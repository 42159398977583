import BEM from 'tao-bem';

class PhotoWithContent extends BEM.Block {
	static get blockName() {
		return 'b-photo-with-content';
	}
}

PhotoWithContent.register();

export default PhotoWithContent;