import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

class AnchorMenu extends BEM.Block {
	static get blockName() {
		return 'b-anchor-menu';
	}

	onInit() {
		const it = this;
		let menuItems = [];

		it.$el.addClass('active');

		$('.menu-anchor').each(function(){
			const $elem = $(this);
			let elemAnchor = $elem.data('anchor');
			let elemId = $elem.attr('id');

			menuItems.push({
				title: elemAnchor,
				id: elemId,
			});

		})

		it.addMenuItemsSlider(menuItems);
		it.addMenuItems(menuItems);

		$( window ).resize(function() {
			let elemsWidth = it.elems('menu-items').$el.outerWidth();
			let containerWidth = it.$el.find('.container').outerWidth();

			if(elemsWidth > containerWidth) {
				it.$el.find(`.${it.block}__menu-more-items`).prepend(it.elems('menu-items-wrapper').$el.children().last());
				elemsWidth = it.elems('menu-items').$el.outerWidth();
			}
		});

		it.elems('menu-item').$el.click(function(){
			let target = $(this).attr('href');	
			console.log($(target).offset());
			
			$('html, body').animate({scrollTop: $(target).offset().top}, 1000);
			return false;
		});
	}

	addMenuItems(menuItems) {
		
		const it = this;
		let menuItemsBlock = it.elems('menu-items').$el
		let elemsWidth = menuItemsBlock.outerWidth();
		menuItemsBlock.removeClass('width');
		let containerWidth = it.$el.find('.container').outerWidth();
		// если true элементы будут помещается в раздел 'еще'
		let toMore = false;

		menuItems.forEach(function(item,index) {
			
			elemsWidth = it.elems('menu-items').$el.outerWidth();
			containerWidth = it.$el.find('.container').outerWidth();

			if(toMore) {
				if(item.title) {
					it.$el.find(`.${it.block}__menu-more-items`).append(`
					<a class='${it.block}__menu-item' href='#${item.id}'>${item.title}</a>
					`);
				}
			} else {
				if(item.title) {
					it.$el.find(`.${it.block}__menu-items-wrapper`).append(`
					<a class='${it.block}__menu-item ${index == 0 && 'active'}' href='#${item.id}'>${item.title}</a>
					`);
					elemsWidth = it.elems('menu-items').$el.outerWidth();
				}
			}

			if(elemsWidth > containerWidth) {
				toMore = true;
				it.$el.find(`.${it.block}__menu-more`).removeClass('hidden');
				it.$el.find(`.${it.block}__menu-more-items`).append(it.elems('menu-items-wrapper').$el.children().last());
				elemsWidth = it.elems('menu-items').$el.outerWidth();
			}


		});
		// Элементы в пункте 'Еще'
		let menuMoreItems = it.$el.find(`.${it.block}__menu-more-items`).children();
		// Скрывает пункт 'Еще'
		if(menuMoreItems.length == 0) {
			it.elems('menu-more').$el.addClass('hide');
		} else {
			it.elems('menu-more').$el.removeClass('hide');
			it.elems('menu-items').$el.css('width','100%');
		}

		menuItemsBlock.addClass('width');
	}

	addMenuItemsSlider(menuItems) {
		const it = this;
		menuItems.forEach(function(item,index) {
			if(item.title) {
				it.$el.find(`.swiper-wrapper`).append(`
				<a class='${it.block}__menu-item  swiper-slide' href='#${item.id}'>${item.title}</a>
				`);
			}
		});
		let initialSlider = 0;

		if(it.elem('menu-items-mobile').$el.find(`.${it.block}__menu-item.active`).index() > 0) {
			initialSlider = it.elem('menu-items-mobile').$el.find(`.${it.block}__menu-item.active`).index();
		}

		new Swiper(it.elem('slider').el, {
			speed: 6000,
			spaceBetween: 10,
			slidesPerView: 'auto',
			initialSlide: initialSlider
		});
	}
}

AnchorMenu.register();

export default AnchorMenu;