import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

class SliderBottomImg extends BEM.Block {
	static get blockName() {
		return 'b-slider-bottom-img';
	}

	onInit() {
		const it = this;

		const swiper = new Swiper(it.elem('slider').el, {
			speed: 600,
			spaceBetween: 20,

			navigation: {
				nextEl: `.${it.block}__arrow-wrapper--next`,
				prevEl: `.${it.block}__arrow-wrapper--prev`,
				disabledClass: `${it.block}__arrow-wrapper--disabled`,
			},

			  pagination: {
				el: `.${it.block}__slider-bullets`,
				type: 'bullets',
				bulletClass: `${it.block}__slider-bullet`,
				bulletElement: 'span',
				bulletActiveClass: `${it.block}__slider-bullet--active`,
				clickable: true
			},
		});
	}
}

SliderBottomImg.register();

export default SliderBottomImg;