import BEM from 'tao-bem';

class CabinetMainPage extends BEM.Block {
  static get blockName() {
    return 'b-cabinet-main-page';
  }

  onInit() { }
}

CabinetMainPage.register();

export default CabinetMainPage;
