import BEM from 'tao-bem';

class ContactsPage extends BEM.Block {
	static get blockName() {
		return 'b-contacts-page';
	}
}

ContactsPage.register();

export default ContactsPage;