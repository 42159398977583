import BEM from 'tao-bem';

class SolutionsDetailPage extends BEM.Block {
	static get blockName() {
		return 'b-solutions-detail-page';
	}

	onInit() {
		const it = this;

		//Переносим хлебные крошки во вложенный блок.
		//Если разместить его там изначально, то вёрстка крашится, почему?
		//Только господь знает(скорее всего из-за формирования хлебных через отложеные функции)
		const $breadcrumbs = it.$el.find(`.${it.block}__breadcrumb`);
		$breadcrumbs.removeClass(`${it.block}__breadcrumb`);
		$breadcrumbs.appendTo(it.$el.find('.b-solutions-banner__breadcrumbs-wrapper'));


		$(function() {
			it.$el.find('.b-request-link__link').click(function(e){
				e.preventDefault();
				$('.b-layout').addClass('modal-open');
				$('.b-layout').addClass('header-hide');
				it.$el.find('.b-solutions-form__wrapper').addClass('active');
			})
		});

	}
}

SolutionsDetailPage.register();

export default SolutionsDetailPage;