import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import {
	Fancybox
} from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox.css';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'component/perfect-scrollbar/style.scss';


class ProductsDetailPage extends BEM.Block {
	static get blockName() {
		return 'b-products-detail-page';
	}

	onInit() {
		const it = this;

		window.addEventListener('popstate', function (e) {
			if (e.state !== null) {
				location.reload();
			}
		});

		Fancybox.bind("[data-fancybox]", {
			// Your options go here
		});

		// Открытие формы заявка на продукт
		it.$el.find('.b-products-logo-with-btns__btn--application').click(function (e) {
			e.preventDefault();
			$('.b-layout').addClass('modal-open');
			$('.b-layout').addClass('header-hide');
			it.$el.find('.b-product-application-form__wrapper').addClass('active');
		})

		// Открытие формы заявка на сертификат
		it.$el.find(`.b-file-item__certificate-btn-form`).click(function (e) {
			e.preventDefault();
			$('.b-layout').addClass('modal-open');
			$('.b-layout').addClass('header-hide');
			it.$el.find('.b-certificate-request-form__title-body').text($(this).data('title'));
			it.$el.find('.b-certificate-request-form__hidden-title-input').attr('value', $(this).data('title'));

			it.$el.find('.b-certificate-request-form__wrapper').addClass('active');
		})

		it.$el.find(`.b-file-item__modal-certificate-btn-form`).click(function (e) {
			e.preventDefault();
			$('.b-layout').addClass('modal-open');
			$('.b-layout').addClass('header-hide');

			it.$el.find(`.b-file-item__modal-wrapper`).removeClass('active');

			it.$el.find('.b-certificate-request-form__title-body').text($(this).data('title'));
			it.$el.find('.b-certificate-request-form__hidden-title-input').attr('value', $(this).data('title'));
			it.$el.find('.b-certificate-request-form__wrapper').addClass('active');
		})

		it.elems("table-item-name").$el.click(function (e) {
			e.preventDefault();

			const downloadFormId = $(this).data('download_form_id');
			console.log(downloadFormId);
			if (!downloadFormId)
				return;

			const $downloadForm = $('#download-form-wrapper-' + downloadFormId);
			console.log($downloadForm);
			if (!$downloadForm.length)
				return;

			$('.b-layout').addClass('modal-open');
			$('.b-layout').addClass('header-hide');

			$('.download-form-wrapper').removeClass('active');
			$downloadForm.find('.download-form-title span').text($(this).find('a:first').text());
			$downloadForm.find('.hidden-product').val($(this).data('id'));
			$downloadForm.find('.success-message').removeClass('active');
			$downloadForm.find('.form-content').removeClass('hide');
			$downloadForm.addClass('active');
                        
                        const itemId = $(this).data('id');
                        const eulaHtml = $('#eula-' + itemId).html();
			const $formEulaHtml = $downloadForm.find('.eula-html');
			$formEulaHtml.html(eulaHtml);
			$downloadForm.find('.eula-checkbox').prop('checked', 'true');
			$downloadForm.find('.eula-container')[eulaHtml.length ? 'removeClass' : 'addClass']('hide');

			$downloadForm.find('.success-message').removeClass('active');
			$downloadForm.find('.form-content').removeClass('hide');
			$downloadForm.addClass('active');

			let eula_scroll = new PerfectScrollbar($downloadForm.find('.eula-html-wrapper-scroll')[0], {
				maxScrollbarLength: 100,
				suppressScrollX: true,
				wheelPropagation: false
			});
		});


		it.elems('tab-content').$el.click(function () {
			it.elems('tab-content').$el.removeClass('active');
			$(this).addClass('active');
			let sectionName = $(this).data('section-name');
			it.elems('content').$el.removeClass('active');

			it.$el.find(`.${it.block}__content[data-section-name="${sectionName}"]`).addClass('active');

			if (sectionName == 'modifications') {
				// Добавление класса, если в ячейке таблицы есть картинка

				$('td').each(function () {
					if ($(this).children('.image').length > 0) {
						$(this).addClass('image-wrapper');
						$(this).attr('data-fancybox', true);
						$(this).attr('data-src', $(this).find('img').attr('src'));
					}
				})
			}
		})

		if ($(window).width() <= 1040) {
			new Swiper(it.elem('sections-slider').el, {
				speed: 600,
				slidesPerView: 'auto',
			});
		}

		it.elems('items-btn-more').$el.click(function () {
			$(this).parent(`.${it.block}__items`).find(`.b-file-item`).removeClass('hide');
			$(this).addClass('hide');
		});

		it.addTableScrollIcon();
	}

	closeModal() {
		const it = this;
		it.elems('item-modal-wrapper').$el.removeClass('active');
		$('.b-layout').removeClass('modal-open');
		$('.b-layout').removeClass('header-hide');
	}

	addTableScrollIcon() {
		const it = this;
		it.$el.find('.table').each(function () {
			$(this).scroll(function () {
				console.log('test');
				$(this).find('.table-hand-scroll').css('left', 20 + $(this).scrollLeft() + 'px');
				$(this).find('.table-hand-scroll').addClass('hide');
			});
		})

		it.$el.find(`.${it.block}__content--modifications`).find('table').each(function () {
			$(this).find('thead').find('tr').first().find('th').first().append('<span class="table-hand-scroll"></span>')
		});

		it.$el.find(`.${it.block}__content--opportunity`).find('table').each(function () {
			$(this).find('thead').find('tr').first().find('th').first().append('<span class="table-hand-scroll"></span>')
		});

		it.$el.find(`.${it.block}__content--materials`).find('table').each(function () {
			$(this).find('thead').find('tr').first().find('th').first().append('<span class="table-hand-scroll"></span>')
		});
	}
}

ProductsDetailPage.register();

export default ProductsDetailPage;