import BEM from 'tao-bem';

class QuoteBlock extends BEM.Block {
	static get blockName() {
		return 'b-quote-block';
	}
}

QuoteBlock.register();

export default QuoteBlock;