import BEM from 'tao-bem';

class SpeakersBlock extends BEM.Block {
	static get blockName() {
		return 'b-speakers-block';
	}

	onInit() {
		const it = this;

		let infoMaxHeight = 0;

		it.elems('speaker-info').$el.each(function(){
			if(infoMaxHeight < $(this).height()){
				infoMaxHeight = $(this).height();
			}
		});

		if($(window).width() > 767) {
			it.elems('speaker-info').$el.height(infoMaxHeight);
		}
	}
}

SpeakersBlock.register();

export default SpeakersBlock;