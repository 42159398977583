import BEM from 'tao-bem';

class ActivityDetailPage extends BEM.Block {
	static get blockName() {
		return 'b-activity-detail-page';
	}

	onInit() {
		const it = this;

		//Переносим хлебные крошки во вложенный блок.
		//Если разместить его там изначально, то вёрстка крашится, почему?
		//Только господь знает(скорее всего из-за формирования хлебных через отложеные функции)
		const $breadcrumbs = it.$el.find(`.${it.block}__breadcrumb`);
		$breadcrumbs.removeClass(`${it.block}__breadcrumb`);
		$breadcrumbs.appendTo(it.$el.find('.b-activity-banner__breadcrumbs-wrapper'));

		it.elems('show-all-program').$el.click(function(e){
			e.preventDefault();
			it.elems('mobile-table').$el.find('tr.hidden').removeClass('hidden');
			$(this).addClass('hidden');
		});
	}
}

ActivityDetailPage.register();

export default ActivityDetailPage;