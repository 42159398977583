import BEM from 'tao-bem';

class Steps extends BEM.Block {
	static get blockName() {
		return 'b-steps';
	}
}

Steps.register();

export default Steps;