import BEM from 'tao-bem';

class PublicationItem extends BEM.Block {
	static get blockName() {
		return 'b-publication-item';
	}

	onInit() {
		const it = this;

		if($(window).width() > 768) {
			if(it.$el.data('big-image')) {
				it.elems('image').$el.css('background-image',`url('${it.$el.data('big-image')}')`);
			}
		}
	}
}

PublicationItem.register();

export default PublicationItem;