import BEM from 'tao-bem';
import 'jquery.maskedinput/src/jquery.maskedinput.js';

class CallbackForm extends BEM.Block {
	static get blockName() {
		return 'b-callback-form';
	}
	onInit() {
		const it = this;
		let $phone = it.elems('fields').$el.find('input[placeholder="Телефон"]');

		it.elems('close-btn').$el.click(function(){
			$('.b-callback-form').removeClass('b-callback-form--active')
		})

		it.elems('politic-checkbox').$el.change( function(){
			if($(this).is(':checked')) {
				it.elems('btn').$el.attr('disabled', false);
			} else {
				it.elems('btn').$el.attr('disabled', true);
			}
		});

		$(`.${it.block}--success`).delay(2500).fadeOut(300);

		$phone.mask("8(999) 999-9999");
	}
}

CallbackForm.register();

export default CallbackForm;