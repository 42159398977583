import BEM from 'tao-bem';

class Field extends BEM.Block {
  static get blockName() {
		return 'b-field';
	}
}

Field.register();

export default Field;
