import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

class SolutionsTask extends BEM.Block {
	static get blockName() {
		return 'b-solutions-task';
	}

	onInit() {
		const it = this;

		const swiper = new Swiper(it.elem('slider').el, {
			speed: 600,
			spaceBetween: 0,
			slidesPerView: 1,

			breakpoints: {
				// when window width is >= 540px

				500: {
					slidesPerView: 2,
				},

				1240: {
					slidesPerView: 3,
				}
			},

			navigation: {
				nextEl: `.${it.block}__arrow-wrapper--next`,
				prevEl: `.${it.block}__arrow-wrapper--prev`,
				disabledClass: `${it.block}__arrow-wrapper--disabled`,
			},

			  pagination: {
				el: `.${it.block}__slider-bullets`,
				type: 'bullets',
				bulletClass: `${it.block}__slider-bullet`,
				bulletElement: 'span',
				bulletActiveClass: `${it.block}__slider-bullet--active`,
				clickable: true
			},
		});
	}
}

SolutionsTask.register();

export default SolutionsTask;
