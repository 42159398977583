import BEM from 'tao-bem';

class SolutionsInformation extends BEM.Block {
	static get blockName() {
		return 'b-solutions-information';
	}

	onInit() {
		const it = this;

		it.elems('item').$el.click(function(e){
			if(e.target.tagName.toLowerCase() === "a") {
				return
			};
			const elem = $(this);

			if(elem.hasClass('active')) {
				it.elems('item').$el.removeClass('active');
			}else {
				it.elems('item').$el.removeClass('active');
				elem.addClass('active');
			}
		})
	}
}

SolutionsInformation.register();

export default SolutionsInformation;