import BEM from 'tao-bem';

class InformationWithContact extends BEM.Block {
	static get blockName() {
		return 'b-information-with-contact';
	}
}

InformationWithContact.register();

export default InformationWithContact;