import BEM from 'tao-bem';

class SitesPage extends BEM.Block {
	static get blockName() {
		return 'b-sites-page';
	}

	onInit() {
		const it = this;

		it.elems('item').$el.click(function(e){
			if($(this).attr('href') == "") {
				e.preventDefault();
			}
		});
	}
}

SitesPage.register();

export default SitesPage;