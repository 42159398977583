import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import "@fancyapps/ui/dist/fancybox.css";
import 'component/fancybox/style.scss'

class PhotoGallerySlider extends BEM.Block {
	static get blockName() {
		return 'b-photo-gallery-slider';
	}

	onInit() {
		const it = this;

		const swiper = new Swiper(it.elem('slider').el, {
			speed: 600,
			spaceBetween: 20,
			slidesPerView: 1,

			breakpoints: {
				// when window width is >= 540px

				540: {
					slidesPerView: 2,
				},

				1040: {
					slidesPerView: 3,
				},

				1240: {
					slidesPerView: 3,
				}
			},

			navigation: {
				nextEl: `.${it.block}__arrow-wrapper--next`,
				prevEl: `.${it.block}__arrow-wrapper--prev`,
				disabledClass: `${it.block}__arrow-wrapper--disabled`,
			},

			  pagination: {
				el: `.${it.block}__slider-bullets`,
				type: 'bullets',
				bulletClass: `${it.block}__slider-bullet`,
				bulletElement: 'span',
				bulletActiveClass: `${it.block}__slider-bullet--active`,
				clickable: true
			},
		});

		Fancybox.bind("[data-fancybox]", {});
	}
}

PhotoGallerySlider.register();

export default PhotoGallerySlider;