import BEM from 'tao-bem';
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox.css';

const buildURLQuery = (src, obj) => {
  const url = new URL(src);
  const params = new URLSearchParams(url.search);

  let rez = new URLSearchParams();

  for (const [key, value] of [...params, ...Object.entries(obj)]) {
    // Youtube
    if (key === "t") {
      rez.set("start", parseInt(value));
    } else {
      rez.set(key, value);
    }
  }

  // Convert to 'foo=1&bar=2&baz=3'
  rez = rez.toString();

  // Vimeo
  // https://vimeo.zendesk.com/hc/en-us/articles/360000121668-Starting-playback-at-a-specific-timecode
  let matches = src.match(/#t=((.*)?\d+s)/);

  if (matches) {
    rez += `#t=${matches[1]}`;
  }

  return rez;
};

const defaults = {
  // General options for any video content (Youtube, Vimeo, HTML5 video)
  video: {
    autoplay: true,
    ratio: 16 / 9,
  },
  // Youtube embed parameters
  youtube: {
    autohide: 1,
    fs: 1,
    rel: 0,
    hd: 1,
    wmode: "transparent",
    enablejsapi: 1,
    html5: 1,
  },
  // Vimeo embed parameters
  vimeo: {
    hd: 1,
    show_title: 1,
    show_byline: 1,
    show_portrait: 0,
    fullscreen: 1,
  },
  // HTML5 video parameters
  html5video: {
    tpl: `<video class="fancybox__html5video" playsinline controls controlsList="nodownload" poster="{{poster}}">
  <source src="{{src}}" type="{{format}}" />Sorry, your browser doesn't support embedded videos.</video>`,
    format: "",
  },
};

// ��������� ���������� ��������� ����� � rutube.ru
class CitrusHtml extends Fancybox.Plugins['Html'] {
	processType(slide) {
		// Add support for `new Fancybox({items : [{html : 'smth'}]});`
		if (slide.html) {
			slide.src = slide.html;
			slide.type = "html";

			delete slide.html;

			return;
		}

		const src = slide.src || "";

		let type = slide.type || this.fancybox.options.type,
			rez = null;

		if (src && typeof src !== "string") {
			return;
		}

		if (
			(rez = src.match(
			/(?:youtube\.com|youtu\.be|youtube\-nocookie\.com)\/(?:watch\?(?:.*&)?v=|v\/|u\/|embed\/?)?(videoseries\?list=(?:.*)|[\w-]{11}|\?listType=(?:.*)&list=(?:.*))(?:.*)/i
			))
		) {
			const params = buildURLQuery(src, this.fancybox.option("Html.youtube"));
			const videoId = encodeURIComponent(rez[1]);

			slide.videoId = videoId;
			slide.src = `https://www.youtube-nocookie.com/embed/${videoId}?${params}`;
			slide.thumb = slide.thumb || `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg`;
			slide.vendor = "youtube";

			type = "video";
		} else if ((rez = src.match(/^.+vimeo.com\/(?:\/)?([\d]+)(.*)?/))) {
			const params = buildURLQuery(src, this.fancybox.option("Html.vimeo"));
			const videoId = encodeURIComponent(rez[1]);

			slide.videoId = videoId;
			slide.src = `https://player.vimeo.com/video/${videoId}?${params}`;
			slide.vendor = "vimeo";

			type = "video";
		} else if (
			rez = src.match(
			/(?:rutube\.ru)\/video\/(\w+)\//i
			)
		) {
			const videoId = encodeURIComponent(rez[1]);

			slide.videoId = videoId;
			slide.src = `https://rutube.ru/play/embed/${videoId}?autoplay=1`;
			slide.thumb = slide.thumb || `https://rutube.ru/api/video/${videoId}/thumbnail/?redirect=1`;
			slide.vendor = "rutube";

			type = "video";
		} else if (
			(rez = src.match(
				/(?:maps\.)?google\.([a-z]{2,3}(?:\.[a-z]{2})?)\/(?:(?:(?:maps\/(?:place\/(?:.*)\/)?\@(.*),(\d+.?\d+?)z))|(?:\?ll=))(.*)?/i
			))
		) {
			slide.src = `//maps.google.${rez[1]}/?ll=${(rez[2]
				? rez[2] + "&z=" + Math.floor(rez[3]) + (rez[4] ? rez[4].replace(/^\//, "&") : "")
				: rez[4] + ""
			).replace(/\?/, "&")}&output=${rez[4] && rez[4].indexOf("layer=c") > 0 ? "svembed" : "embed"}`;

			type = "map";
		} else if ((rez = src.match(/(?:maps\.)?google\.([a-z]{2,3}(?:\.[a-z]{2})?)\/(?:maps\/search\/)(.*)/i))) {
			slide.src = `//maps.google.${rez[1]}/maps?q=${rez[2].replace("query=", "q=").replace("api=1", "")}&output=embed`;

			type = "map";
		}

		// Guess content type
		if (!type) {
			if (src.charAt(0) === "#") {
				type = "inline";
			} else if ((rez = src.match(/\.(mp4|mov|ogv|webm)((\?|#).*)?$/i))) {
				type = "html5video";

				slide.format = slide.format || "video/" + (rez[1] === "ogv" ? "ogg" : rez[1]);
			} else if (src.match(/(^data:image\/[a-z0-9+\/=]*,)|(\.(jp(e|g|eg)|gif|png|bmp|webp|svg|ico)((\?|#).*)?$)/i)) {
				type = "image";
			} else if (src.match(/\.(pdf)((\?|#).*)?$/i)) {
				type = "pdf";
			}
		}

		slide.type = type || this.fancybox.option("defaultType", "image");

		if (type === "html5video" || type === "video") {
			/* citrus */
			//slide.video = extend({}, this.fancybox.option("Html.video"), slide.video);
			slide.video = Object.assign({}, this.fancybox.option("Html.video"), slide.video);

			if (slide._width && slide._height) {
				slide.ratio = parseFloat(slide._width) / parseFloat(slide._height);
			} else {
				slide.ratio = slide.ratio || slide.video.ratio || defaults.video.ratio;
			}
		}
	}

	loadIframeContent(slide) {
		const fancybox = this.fancybox;
		const $iframe = document.createElement("iframe");

		$iframe.className = "fancybox__iframe";

		$iframe.setAttribute("id", `fancybox__iframe_${fancybox.id}_${slide.index}`);

		/* citrus */
		if (slide.vendor == "rutube") {
			$iframe.setAttribute("allow", "autoplay");
			$iframe.setAttribute("allowFullScreen", "true");
		}
		else
			$iframe.setAttribute("allow", "autoplay; fullscreen");
		$iframe.setAttribute("scrolling", "auto");

		slide.$iframe = $iframe;

		if (slide.type !== "iframe" || slide.preload === false) {
			$iframe.setAttribute("src", slide.src);

			this.fancybox.setContent(slide, $iframe);

			this.resizeIframe(slide);

			return;
		}

		fancybox.showLoading(slide);

		const $content = document.createElement("div");
		$content.style.visibility = "hidden";

		this.fancybox.setContent(slide, $content);

		$content.appendChild($iframe);

		$iframe.onerror = () => {
			fancybox.setError(slide, "{{IFRAME_ERROR}}");
		};

		$iframe.onload = () => {
			fancybox.hideLoading(slide);

			let isFirstLoad = false;

			if (!$iframe.isReady) {
				$iframe.isReady = true;
				isFirstLoad = true;
			}

			if (!$iframe.src.length) {
				return;
			}

			$iframe.parentNode.style.visibility = "";

			this.resizeIframe(slide);

			if (isFirstLoad) {
				fancybox.revealContent(slide);
			}
		};

		$iframe.setAttribute("src", slide.src);
	}

	playVideo(slide) {
		if (slide.type === "html5video" && slide.video.autoplay) {
			try {
				const $video = slide.$el.querySelector("video");
	    
				if ($video) {
					const promise = $video.play();
	    
					if (promise !== undefined) {
						promise
							.then(() => {
								// Autoplay started
							})
							.catch((error) => {
								// Autoplay was prevented.
								$video.muted = true;
								$video.play();
							});
					}
				}
			} catch (err) {}
		}

		if (slide.type !== "video" || !(slide.$iframe && slide.$iframe.contentWindow)) {
			return;
		}

		// This function will be repeatedly called to check
		// if video iframe has been loaded to send message to start the video
		const poller = () => {
			if (slide.state === "done" && slide.$iframe && slide.$iframe.contentWindow) {
				let command;

				if (slide.$iframe.isReady) {
					/* citrus */
					//if (slide.video && slide.video.autoplay) {
					if (slide.video && (slide.video.autoplay || slide.vendor === "rutube")) {
						if (slide.vendor == "youtube") {
							command = {
								event: "command",
								func: "playVideo",
							};
						} else if (slide.vendor == "rutube") {
							command = {
								type: "player:play",
								data: {},
							};
						} else {
							command = {
								method: "play",
								value: "true",
							};
						}
					}

					if (command) {
						slide.$iframe.contentWindow.postMessage(JSON.stringify(command), "*");
					}

					/* citrus */
					if (slide.vendor == "rutube") {
						setTimeout(() => {slide.$iframe.contentWindow.postMessage(JSON.stringify({type: "player:unMute"}), "*")}, 100);
						setTimeout(() => {slide.$iframe.contentWindow.postMessage(JSON.stringify({type: "player:play", data: {}}), "*")}, 200);
					}

					return;
				}

				if (slide.vendor === "youtube") {
					command = {
						event: "listening",
						id: slide.$iframe.getAttribute("id"),
					};

					slide.$iframe.contentWindow.postMessage(JSON.stringify(command), "*");
				}
				else if (slide.vendor === "rutube") {
					command = {
						type: "player:unMute",
					};

					slide.$iframe.contentWindow.postMessage(JSON.stringify(command), "*");
				}
			}

			slide.poller = setTimeout(poller, 250);
		};

		poller();
	}

	onUnselectSlide(fancybox, carousel, slide) {
		if (slide.type === "html5video") {
			try {
				slide.$el.querySelector("video").pause();
			} catch (error) {}

			return;
		}

		let command = false;

		if (slide.vendor == "vimeo") {
			command = {
				method: "pause",
				value: "true",
			};
		} else if (slide.vendor === "youtube") {
			command = {
				event: "command",
				func: "pauseVideo",
			};
		} else if (slide.vendor === "rutube") {
			command = {
				type: "player:pause",
				data: {},
			};
		}

		if (command && slide.$iframe && slide.$iframe.contentWindow) {
			slide.$iframe.contentWindow.postMessage(JSON.stringify(command), "*");
		}

		clearTimeout(slide.poller);
	}

	onMessage(e) {
		try {
			let data = JSON.parse(e.data);

			if (e.origin === "https://player.vimeo.com") {
				if (data.event === "ready") {
					for (let $iframe of document.getElementsByClassName("fancybox__iframe")) {
						if ($iframe.contentWindow === e.source) {
							$iframe.isReady = 1;
						}
					}
				}
			} else if (e.origin === "https://rutube.ru") {
				if (data.type === "player:ready") {
					for (let $iframe of document.getElementsByClassName("fancybox__iframe")) {
						if ($iframe.contentWindow === e.source) {
							$iframe.isReady = 1;
						}
					}
				}
			} else if (e.origin === "https://www.youtube-nocookie.com") {
				if (data.event === "onReady") {
					document.getElementById(data.id).isReady = 1;
				}
			}
		} catch (ex) {}
	}
}

class VideosPage extends BEM.Block {
	static get blockName() {
		return 'b-videos-page';
	}

	onInit() {
		const it = this;

		//const rt = new Rutube();

		Fancybox.Plugins['Html'] = CitrusHtml;

		Fancybox.bind("[data-fancybox]", {
			// Your options go here
		});

		it.elems('tab').$el.click(function(){
			let $elem = $(this);

			it.elems('tab').$el.removeClass('active');
			$elem.addClass('active');

			if($elem.hasClass('tab-one')) {
				it.elems('items').$el.removeClass('tab-two');
				it.elems('items').$el.addClass('tab-one');
			}

			if($elem.hasClass('tab-two')) {
				it.elems('items').$el.removeClass('tab-one');
				it.elems('items').$el.addClass('tab-two');
			}
		});

		it.elems('item').$el.click(function(e) {
			if($(this).parent().hasClass('tab-two') && !$(e.target).hasClass(`${it.block}__item-btn`)) {
				e.preventDefault();
			}
		})
	}
}

VideosPage.register();

export default VideosPage;