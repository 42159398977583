import BEM from 'tao-bem';

class AuthChangePassword extends BEM.Block {
	static get blockName() {
		return 'b-auth-change-password';
	}

	onInit() {
		const it = this;
	}
}

AuthChangePassword.register();

export default AuthChangePassword;