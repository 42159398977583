import BEM from 'tao-bem';

class MaterialsPage extends BEM.Block {
	static get blockName() {
		return 'b-materials-page';
	}
}

MaterialsPage.register();

export default MaterialsPage;