import BEM from 'tao-bem';

class Button extends BEM.Block {
  static get blockName() {
		return 'b-button';
	}
}

Button.register();

export default Button;
