import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import anime from "animejs/lib/anime.es.js";

class BannerWithSlider extends BEM.Block {
	static get blockName() {
		return 'b-banner-with-slider';
	}

	onInit() {
		const it = this;

		it.animateSlider()

		const swiper = new Swiper(it.elem('slider').el, {
			speed: 600,
			spaceBetween: 100,
			autoplay: {
				delay: 4000,
			},

			pagination: {
				el: `.${it.block}__dots`,
				clickable: true,
				renderBullet: function (index, className) {
					return `<span class="${className} ${it.block}__dot">0${index+1}</span>`;
				},
				bulletActiveClass: `${it.block}__dot--active`,
			},
			on: {
				slideChange: function() {
					it.elems('tab').$el.removeClass(`${it.block}__tab--active`);
					it.elems('tabs').$el.find(`[id='${this.activeIndex}']`).addClass(`${it.block}__tab--active`);
					it.animateSlider()
				}
			}
		});

		it.elems('tab').$el.click(function() {
			let $elem = $(this);
			let tabId = $elem.attr('id');
			it.elems('tab').$el.removeClass(`${it.block}__tab--active`);
			it.elems('tabs').$el.find(`[id='${tabId}']`).addClass(`${it.block}__tab--active`);
			swiper.slideTo(tabId);
		})

	}

	animateSlider() {
		const it = this;

		anime({
			targets: `.${it.block}__tab--active .${it.block}__tab-line`,
			width: [0, '100%'],
			opacity:[0.5, 1],
			easing: "linear",
			delay: 500,
			duration: 3000
		});

		anime({
			targets: `.${it.block}__slide-img`,
			translateY: ["25px", 0],
			opacity: [0, 1],
			easing: "linear",
			delay: 500
		});

		anime({
			targets: `.${it.block}__slide-title`,
			translateY: ["25px", 0],
			opacity: [0, 1],
			easing: "linear",
			delay: 600
		});
		anime({
			targets: `.${it.block}__slide-subtitle`,
			opacity: [0, 1],
			easing: "linear",
			delay: 700
		});
		anime({
			targets: `.${it.block}__slide-link--anime`,
			translateY: ["-25px", 0],
			opacity: [0, 1],
			easing: "linear",
			delay: 500
		});
	}
}

BannerWithSlider.register();

export default BannerWithSlider;