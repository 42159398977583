import BEM from 'tao-bem';

class Filter extends BEM.Block {
	static get blockName() {
		return 'b-filter';
	}

	onInit() {
		const it = this;
		
		it.$el.find(`.${it.block}__group-head`).click(function() {
			const $elem = $(this);

			$elem.closest(`.${it.block}__group `).toggleClass('active');
		});
	}
}

Filter.register();

export default Filter;