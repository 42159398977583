import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

class ParticipateInSolutions extends BEM.Block {
	static get blockName() {
		return 'b-participate-in-solutions';
	}

	onInit() {
		const it = this;

		if($(window).width() <= 450) {
			new Swiper(it.elem('items-slider').el, {
				speed: 600,
				slidePerView : 'auto',
				pagination: {
					el: `.${it.block}__slider-bullets`,
					type: 'bullets',
					bulletClass: `${it.block}__slider-bullet`,
					bulletElement: 'span',
					bulletActiveClass: `${it.block}__slider-bullet--active`,
					clickable: true
				},
			});
		}
	}
}

ParticipateInSolutions.register();

export default ParticipateInSolutions;