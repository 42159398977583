import BEM from 'tao-bem';

class SuccessStoryAnnouncementCard extends BEM.Block {
	static get blockName() {
		return 'b-success-story-announcement-card';
	}
}

SuccessStoryAnnouncementCard.register();

export default SuccessStoryAnnouncementCard;