import BEM from 'tao-bem';

class PressCenterPage extends BEM.Block {
	static get blockName() {
		return 'b-press-center-page';
	}
}

PressCenterPage.register();

export default PressCenterPage;