import BEM from "tao-bem";

class PublicationsPage extends BEM.Block {
	static get blockName() {
		return "b-publications-page";
	}

	onInit() {
		const it = this;
		let initialFilter = it.$el.data('filter');
		it.$filter = it.$el.find(".b-publications-filter");

		Object.keys(initialFilter['sections']).forEach((key)=>{
			if(initialFilter['sections'][key]['active']) {
				it.renderTag("Раздел: " + initialFilter['sections'][key]['name'],"section-id-" + initialFilter['sections'][key]['id'])
			}
		});

		Object.keys(initialFilter['tags']).forEach((key)=>{
			if(initialFilter['tags'][key]['active']) {
				it.renderTag(initialFilter['tags'][key]['name'],"tag-id-" +initialFilter['tags'][key]['id'])
			}
		});

		// Открытие фильтра
		it.elems("filter-btn").$el.click(function(e) {
			$(".b-layout").addClass("modal-open");
			it.$filter.find(".b-publications-filter__wrapper").addClass("active");
			it.$filter.appendTo($('body'));
		});

		it.$filter.find('.b-publications-filter__filter-item').click(function() {
			if($(this).hasClass('active')) {
				$(this).removeClass('active');
			}else {
				$(this).addClass('active');
			}
		});

		it.$filter.find('.b-publications-filter__btn--filter').click(function() {
			window.location.href = it.collectUrl(it.collectFilterData());
		});
	}

	collectUrl(filter) {
		let newUrl = "/press-center/publications/?";

		if (filter["section_id"].length != 0) {
			filter["section_id"].forEach(id => {
				newUrl += `filter[section_id][]=${id}&`;
			});
		}

		if (filter["p-tags"].length != 0) {
			filter["p-tags"].forEach(id => {
				newUrl += `filter[p-tags][]=${id}&`;
			});
		}
		if (filter["active_from"]) {
			newUrl += `filter[active_from]=${filter["active_from"]}&`;
		}
		if (filter["active_to"]) {
			newUrl += `filter[active_to]=${filter["active_to"]}&`;
		}

		return encodeURI(newUrl);
	}

	collectFilterData() {
		const it = this;

		let filter = {
			"p-tags": [],
			"section_id": [],
			"active_from": false,
			'active_to': false
		};

		if(it.$filter.find(".b-publications-filter__block-field--from").val()) {
			let from = it.$filter.find(".b-publications-filter__block-field--from").val().split('.');
			filter['active_from'] = +new Date(from[1],from[0] - 1 , 1) / 1000;
		}
		if(it.$filter.find(".b-publications-filter__block-field--to").val()) {
			let to = it.$filter.find(".b-publications-filter__block-field--to").val().split('.');
			filter['active_to'] = +new Date(to[1],to[0] - 1, new Date(to[1],to[0], 0).toLocaleString('RU', {day: 'numeric'})) / 1000;
		}

		it.$filter
			.find(".b-publications-filter__filter-item")
			.each(function() {
				if ($(this).hasClass("active")) {
					if ($(this).data("tag-id")) {
						filter["p-tags"].push($(this).data("tag-id"));
					}
					if ($(this).data("section-id")) {
						filter["section_id"].push($(this).data("section-id"));
					}
				}
			});

		return filter;
	}

	renderTag(title, id) {
		const it = this;
		const tagsContainer = it.elems("tags").$el;
		let $tag = $(`
		<div class="${it.block}__tag" id='${id}'>
			${title}
			<span class="${it.block}__tag-close">
			</span>
		</div>
		`);

		tagsContainer.append($tag);

		$tag.find(`.${it.block}__tag-close`).click(function() {
			const $elem = $(this);

			let id = $elem.parent().attr("id");

			$elem
				.parent(`.${it.block}__tag`)
				.remove();

			it.$el.find(`.b-publications-filter__filter-item#${id}`).removeClass('active');

			window.location.href = it.collectUrl(it.collectFilterData());
		});
	}
}

PublicationsPage.register();

export default PublicationsPage;
