import BEM from 'tao-bem';

class DownloadsListItems extends BEM.Block {
	static get blockName() {
		return 'b-downloads-list-items';
	}
}

DownloadsListItems.register();

export default DownloadsListItems;