import BEM from 'tao-bem';

class ServicesPage extends BEM.Block {
	static get blockName() {
		return 'b-services-page';
	}
}

ServicesPage.register();

export default ServicesPage;