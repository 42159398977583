import BEM from 'tao-bem';

class FormularRequestBlock extends BEM.Block {
  static get blockName() {
    return 'b-formular-request-block';
  }
  onInit() {
    const it = this;

    it.elems('formular-btn').$el.click(function(e){
      e.preventDefault();
      $('.b-layout').addClass('modal-open');
      $('.b-layout').addClass('header-hide');
      it.$el.find('.b-formular-request-form__wrapper').addClass('active');
    })
  }
}

FormularRequestBlock.register();

export default FormularRequestBlock;