import BEM from 'tao-bem';

class Content extends BEM.Block {
	static get blockName() {
		return 'b-content';
	}
}

Content.register();

export default Content;