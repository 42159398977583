import BEM from 'tao-bem';
import 'jquery.maskedinput/src/jquery.maskedinput.js';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min.js';
import 'component/select2/style.scss';

class BecomeTechnologyPartnerForm extends BEM.Block {
	static get blockName() {
		return 'b-become-technology-partner-form';
	}

	onInit() {
		const it = this;
		const $form = it.$el.find('form');
		const link = '/local/templates/site/components/bitrix/form.result.new/become-technology-partner-form/ajax.php';
		const $phone = it.$el.find(`.${it.block}__field--phone`);
		const $inn = it.$el.find(`.${it.block}__field--inn`);
		const $kpp = it.$el.find(`.${it.block}__field--kpp`);

                registerValidator(it, $phone, 'phone');
                registerValidator(it, $inn, 'inn');
                registerValidator(it, $kpp, 'kpp');
                registerRequiredFieldsValidation(it);
		
		const $select_partner = it.$el.find(`.${it.block}__field--select-partner`);

		$select_partner.select2(
			{
				placeholder: 'Выберите из списка',
				theme: 'white-checkbox',
				closeOnSelect: false,
				selectionCssClass: `.${it.block}__block-select-selection`,
				dropdownCssClass: `.${it.block}__block-select`,
				language: {
					noResults: function() {
						return "Ничего не найдено";
					},
				},
				escapeMarkup: function (markup) {
					return markup;
				}

			}
		);

		$select_partner.on('select2:select',function() {
			let data = $select_partner.find(':selected');
			if(data.length != 0) {
				$select_partner.next().find('.select2-search__field').attr('placeholder', data[data.length - 1].label);
				$select_partner.next().find('.select2-search__field').addClass('black');
			} else {
				$select_partner.next().find('.select2-search__field').attr('placeholder', 'Выберите из списка');
				$select_partner.next().find('.select2-search__field').removeClass('black');
			}
		});
		$select_partner.on('select2:unselect',function() {
			let data = $select_partner.find(':selected');
			if(data.length != 0) {
				$select_partner.next().find('.select2-search__field').attr('placeholder', data[data.length - 1].label);
				$select_partner.next().find('.select2-search__field').addClass('black');
			} else {
				$select_partner.next().find('.select2-search__field').attr('placeholder', 'Выберите из списка');
				$select_partner.next().find('.select2-search__field').removeClass('black');
			}
		});

		let select_partner_scroll = false;

		$select_partner.on('select2:open',function() {
			if(!select_partner_scroll) {
				select_partner_scroll = new PerfectScrollbar($('.select2-results__options')[0],{
					maxScrollbarLength: 100,
					suppressScrollX: true
				});
			}else {
				select_partner_scroll.update()
			}
		});

		const $select_directions = it.$el.find(`.${it.block}__field--select-directions`);

		$select_directions.select2(
			{
				placeholder: 'Выберите из списка',
				theme: 'white-checkbox',
				closeOnSelect: false,
				selectionCssClass: `.${it.block}__block-select-selection`,
				dropdownCssClass: `.${it.block}__block-select`,
				language: {
					noResults: function() {
						return "Ничего не найдено";
					},
				},
				escapeMarkup: function (markup) {
					return markup;
				}

			}
		);

		$select_directions.on('select2:select',function() {
			let data = $select_directions.find(':selected');
			if(data.length != 0) {
				$select_directions.next().find('.select2-search__field').attr('placeholder', data[data.length - 1].label);
				$select_directions.next().find('.select2-search__field').addClass('black');
			} else {
				$select_directions.next().find('.select2-search__field').attr('placeholder', 'Выберите из списка');
				$select_directions.next().find('.select2-search__field').removeClass('black');
			}
		});
		$select_directions.on('select2:unselect',function() {
			let data = $select_directions.find(':selected');
			if(data.length != 0) {
				$select_directions.next().find('.select2-search__field').attr('placeholder', data[data.length - 1].label);
				$select_directions.next().find('.select2-search__field').addClass('black');
			} else {
				$select_directions.next().find('.select2-search__field').attr('placeholder', 'Выберите из списка');
				$select_directions.next().find('.select2-search__field').removeClass('black');
			}
		});

		let select_directions_scroll = false;

		$select_directions.on('select2:open',function() {
			if(!select_directions_scroll) {
				select_directions_scroll = new PerfectScrollbar($('.select2-results__options')[0],{
					maxScrollbarLength: 100,
					suppressScrollX: true
				});
			}else {
				select_directions_scroll.update()
			}
		});

		it.$el.find(`.${it.block}__politic-checkbox`).change( function(){
			if($(this).is(':checked')) {
				it.$el.find(`.${it.block}__btn`).attr('disabled', false);
			} else {
				it.$el.find(`.${it.block}__btn`).attr('disabled', true);
			}
		});

		it.elems('success-message-link').$el.click(function() {
			location.reload();
		});

		BX.bind($form[0], 'submit', BX.proxy(function(e) {

			BX.PreventDefault(e);

			// валидация полей с маской ввода
			var arMaskValidateErrors = validateInputFields(it);
			if (arMaskValidateErrors.length) {
				it.$el.find(`.${it.block}__errors`).html(arMaskValidateErrors.join('<br>'));
				it.$el.find(`.${it.block}__errors`).addClass('active');
				return false;
			}
			
			let xhr = new XMLHttpRequest();
			xhr.open('POST', link);
			xhr.onload = function() {
				if (xhr.status != 200) {
					alert(`Ошибка ${xhr.status}: ${xhr.statusText}`);
				} else {
					var json = JSON.parse(xhr.responseText)

					if (!json.success) {
						let errorStr = '';
						let requiredError = 'Не заполнены следующие обязательные поля: ';
						let hasRequiresError = false;

						for (let fieldKey in json.errors) {
							if(json.errors[fieldKey].includes('Не заполнены следующие обязательные поля:')) {
								requiredError+= json.errors[fieldKey].split('Не заполнены следующие обязательные поля:')[1] + ', ';
								hasRequiresError = true;
							}else {
								errorStr += json.errors[fieldKey] + '<br>';
							}
						}

						if(hasRequiresError) {
							errorStr += requiredError.substr(0, requiredError.length - 2) + '<br>';
						}
						it.$el.find(`.${it.block}__errors`).addClass('active');
						it.$el.find(`.${it.block}__errors`).html(errorStr);
					} else {
						//Всё отправленно, можешь делать что хочешь
						$form.find(`.${it.block}__errors`).html('');
						$form.find(`input`).val('');
						$form.find(`textarea`).val('');
						it.$el.find('.select2-search__field').attr('placeholder', 'Выберите из списка')
						it.elems('wrapper').$el.addClass('hide');
						it.elems('success-message').$el.addClass('active');
					}
				}
			};

			xhr.onerror = function() {
				console.log('Запрос не удался')
			};

			// Передаем все данные из формы
			xhr.send(new FormData($form[0]));
		}, $form[0], link));
	}
}

BecomeTechnologyPartnerForm.register();

export default BecomeTechnologyPartnerForm;