import BEM from 'tao-bem';

class CompanyInfo extends BEM.Block {
  static get blockName() {
		return 'b-company-info';
	}

	onInit() { }
}

CompanyInfo .register();

export default CompanyInfo ;
