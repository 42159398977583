import BEM from 'tao-bem';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'component/perfect-scrollbar/style.scss';

class FreeDetailPage extends BEM.Block {
	static get blockName() {
		return 'b-free-detail-page';
	}

	onInit() {
		const it = this;

		it.elems('tab').$el.click(function(e){
			const $elem = $(this);
			if($(e.target).closest(`.${it.block}__tab-content`).length > 0) {
				return;
			}

			if($elem.hasClass('active')) {
				$elem.removeClass('active');
				$elem.find(`.${it.block}__tab-content`).slideUp(200);
			}else {
				$elem.addClass('active');
				$elem.find(`.${it.block}__tab-content`).slideDown(200);
			}
		})

		it.addTableScrollIcon();

		it.elems('table-item-name').$el.click(function(e){
			e.preventDefault();

			const downloadFormId = $(this).data('download_form_id');
			if (!downloadFormId)
				return;

			const $downloadForm = $('#download-form-wrapper-' + downloadFormId);
			if (!$downloadForm.length)
				return;

			$('.b-layout').addClass('modal-open');
			$('.b-layout').addClass('header-hide');

			const itemId = $(this).data('id');

			$('.download-form-wrapper').removeClass('active');
			$downloadForm.find('.download-form-title span').text($(this).find('a:first').text());
			$downloadForm.find('.hidden-product').val(itemId);
			$downloadForm.find('form input[data-val]').each(function() {
				$(this).val($(this).data('val'));
			});

			let hiddenSizeValue = '';
			const pool_number_10 = parseInt($(this).data('pool_number_10'), 10);
			const email_id_10 = $(this).data('email_id_10');
			if (!pool_number_10 || pool_number_10 <= 0 || email_id_10.length == 0)
				hiddenSizeValue = $downloadForm.find('.hidden-size').data('value');
			$downloadForm.find('.hidden-size').val(hiddenSizeValue);

			const eulaHtml = $('#eula-' + itemId).html();
			const $formEulaHtml = $downloadForm.find('.eula-html');
			$formEulaHtml.html(eulaHtml);
			$downloadForm.find('.eula-checkbox').prop('checked', 'true');
			$downloadForm.find('.eula-container')[eulaHtml.length ? 'removeClass' : 'addClass']('hide');

			$downloadForm.find('.success-message').removeClass('active');
			$downloadForm.find('.form-content').removeClass('hide');
			$downloadForm.addClass('active');

			let eula_scroll = new PerfectScrollbar($downloadForm.find('.eula-html-wrapper-scroll')[0], {
				maxScrollbarLength: 100,
				suppressScrollX: true,
				wheelPropagation: false
			});
		});
  }

	addTableScrollIcon() {
		const it = this;

		it.$el.find(`.${it.block}__table-wrapper`).each(function(){
			$(this).find('table').each(function(){
				$(this).find('thead').find('tr').first().find('th').first().append('<span class="table-hand-scroll"></span>')
			});

			$(this).scroll(function(){
				$(this).find('.table-hand-scroll').css('left',20 + $(this).scrollLeft() + 'px');
				$(this).find('.table-hand-scroll').addClass('hide');
			});
		})
	}
}

FreeDetailPage.register();

export default FreeDetailPage;