import BEM from 'tao-bem';

class SolutionsTabs extends BEM.Block {
	static get blockName() {
		return 'b-solutions-tabs';
	}
	onInit() {
		const it = this;

		it.elems('category').$el.click(function() {

			let $elem = $(this);
			let categoryId = $elem.attr('id');


			it.elems('category').$el.removeClass(`${it.block}__category--active`);
			it.elems('items').$el.removeClass(`${it.block}__items--active`)

			it.elems('categories').$el.find(`[id='${categoryId}']`).addClass(`${it.block}__category--active`);

			it.elems('content-wrapper').$el.find(`[id='${categoryId}']`).addClass(`${it.block}__items--active`);
		});
	}
}

SolutionsTabs.register();

export default SolutionsTabs;