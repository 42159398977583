import BEM from 'tao-bem';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'component/perfect-scrollbar/style.scss';
import 'jquery.cookie';
import "select2/dist/css/select2.min.css";
import "select2/dist/js/select2.full.min.js";
import 'component/select2/style.scss';


class QuestionsDetailPage extends BEM.Block {
	static get blockName() {
		return 'b-questions-detail-page';
	}

	onInit() {
		let likedItems =  $.cookie('questionsLikedItems') ? $.cookie('questionsLikedItems').split(',') : [];
		let dislikedItems = $.cookie('questionsDislikedItems') ? $.cookie('questionsDislikedItems').split(',') : [];

		const it = this;
		const searchInput = it.elems('search-input').$el;
		const sectionId = it.$el.data('section-id');

		function addItemsEvents() {
			it.$el.find(`.${it.block}__form-btn`).click(function(e){
				e.preventDefault();
				$('.b-layout').addClass('modal-open');
				$('.b-layout').addClass('header-hide');
				it.$el.find('.b-ask-question-form__wrapper').addClass('active');
			})

			it.$el.find('.b-request-link__link').click(function(e){
				e.preventDefault();
				$('.b-layout').addClass('modal-open');
				$('.b-layout').addClass('header-hide');
				it.$el.find('.b-ask-question-form__wrapper').addClass('active');
			})


			likedItems.forEach(function(elem){
				it.$el.find(`.${it.block}__item[id=${elem}]`).find(`.${it.block}__item-counter`).addClass('like');
			})

			dislikedItems.forEach(function(elem){
				it.$el.find(`.${it.block}__item[id=${elem}]`).find(`.${it.block}__item-counter`).addClass('dislike');
			})

			it.elems('item').$el.click(function(event){
				let id = $(this).attr('id');

				if($(event.target).is(`.${it.block}__form-btn`)) {
					return;
				}

				// Клик по счётчку 'Да'
				if($(event.target).is(`.${it.block}__item-counter-yes`)) {
					it.UpdateRatingCount(id,1);
					likedItems.push(id);
					$.cookie('questionsLikedItems',likedItems);
					const yesCounter = $(this).find(`.${it.block}__item-counter-yes`);
					yesCounter.text(`Да: ${yesCounter.data('count') + 1}`);
					$(this).find(`.${it.block}__item-counter`).addClass('like');
					return;
				}

				// Клик по счётчку 'Нет'
				if($(event.target).is(`.${it.block}__item-counter-no`)) {
					it.UpdateRatingCount(id,-1);
					dislikedItems.push(id);
					$.cookie('questionsDislikedItems',dislikedItems);
					const yesCounter = $(this).find(`.${it.block}__item-counter-no`);
					yesCounter.text(`Нет: ${yesCounter.data('count') + 1}`);
					$(this).find(`.${it.block}__item-counter`).addClass('dislike');
					return;
				}
				const elem = $(this);

				if(elem.hasClass('active')) {
					it.elems('item').$el.removeClass('active');
				}else {
					it.elems('item').$el.removeClass('active');
					elem.addClass('active');
				}

			})
		}

		addItemsEvents();


		searchInput.select2({
			placeholder: 'Поиск по вопросам и ответам',
			selectionCssClass: 'select-search',
			minimumInputLength: 1,
			language: {
				inputTooShort: function() {
					return 'Введите запрос';
				},
				noResults: function() {
					return 'Ничего не найдено';
				},
				searching: function() {
					return "Идет поиск";
				}
			},
			theme: 'search',
			ajax: {
			  url: '/local/api/questions/ajax.php',
			  dataType: 'json',
			  method: 'get',
			  data: function (params) {
				var query = {
				  search: encodeURIComponent(params.term),
				  section_id: sectionId
				}

				 return query;
			},
			processResults: function (data) {
				let options = [];

				data.items.forEach(element => {
					options.push({
						text: element.title,
						id: element.id
					})
				});

				return {
					results: options
				}
			}
		}});
		let searchInputScroll = false;

		searchInput.on('select2:open',function() {
			if(!searchInputScroll) {
				searchInputScroll = new PerfectScrollbar($('.select2-results__options')[0],{
					maxScrollbarLength: 100,
					suppressScrollX: true
				});
			}else {
				searchInputScroll.update();
			}

			});

		  searchInput.on('select2:select',function() {
			const elem = $(this);
			let data = elem.find(':selected');
			elem.empty();

			$.ajax({
				url: '/local/api/questions/ajax.php',
				dataType: 'json',
				method: 'get',

				data: {
					search:encodeURIComponent(data[data.length - 1].label),
					section_id: sectionId
				},
				success: function(data) {
					it.viewItems(data.items);
					addItemsEvents();
					it.elems('pagination').$el.addClass('hide');
				}
			})
			setTimeout(() => {
				$('textarea.select2-search__field').attr('placeholder', data[data.length - 1].innerText);
			}, 50);

		});
	}

	UpdateRatingCount(id, value) {
		$.ajax({
			url: '/local/api/questions/ajax.php',
			method: 'post',
			dataType: 'json',
			data: {
				id,
				value
			}
		});
	}

	viewItems(items) {
		const it = this;
		let itemsContainer = it.elems('items').$el;
		itemsContainer.html('');

		items.forEach((el)=>{
			itemsContainer.append($(`<div class="${it.block}__item" id="${el.id}">
			<div class="${it.block}__item-title-wrapper">
				<div class="${it.block}__item-title">
				${el.title ?? ''}
				</div>

				<div class="${it.block}__item-plus">

				</div>
			</div>

			<div class="${it.block}__item-content">
				<p class="c-content">
					${el.content ?? ''}
				</p>

				<p class="${it.block}__item-counter">
					Был ли ответ полезен?<br>
					<span class="${it.block}__item-counter-yes" data-count='${el.yes ?? '0'}'> Да: ${el.yes ?? '0'}</span>
					<span class="${it.block}__item-counter-divider">/</span>
					<span  class="${it.block}__item-counter-no" data-count='${el.yes ?? '0'}'> Нет: ${el.no ?? '0'}</span>
				</p>

				<a class="${it.block}__item-btn ${it.block}__form-btn c-green-btn" href="#">
					Задать вопрос
				</a>
			</div>
		</div>`));
		});
	}
}

QuestionsDetailPage.register();

export default QuestionsDetailPage;