import BEM from 'tao-bem';

class RefundPr extends BEM.Block {
    static get blockName() {
        return 'b-refund-pr';
    }

    onInit() {}
}

RefundPr.register();

export default RefundPr;
