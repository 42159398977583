import BEM from 'tao-bem';

class Footer extends BEM.Block {
	static get blockName() {
		return 'b-footer';
	}

	onInit() {
		const it = this;

		it.elems('callback-link').$el.click(()=> {
			$('.b-callback-form--first').addClass('b-callback-form--active');
		});

		it.elems('subscribe-btn').$el.click(function(){
			it.$el.find(`.b-subscribe-footer-form__wrapper`).addClass('active');
			$('.b-layout').addClass('modal-open');
		});

		it.elems('cookie-notice-btn').$el.click(()=> {
			$('.b-footer__cookie-notice').hide();
			BX.setCookie('BITRIX_SM_cookie_notice_shown', 'Y', { path: '/', expires: 86400*3650 });
		});

		it.elems('poll-site-work-notice-close-btn').$el.click(()=> {
			$('.b-footer__poll-site-work-notice').hide();
			BX.setCookie('BITRIX_SM_poll_site_work_notice_shown', 'Y', { path: '/', expires: 86400*3650 });
		});

		it.elems('poll-site-work-notice-btn').$el.click(()=> {
			$('.b-footer__poll-site-work-notice').hide();
			$('.b-poll-site-work-form__wrapper').addClass('active');
			$('.b-layout').addClass('modal-open');
		});

		if (window._pollSiteWorkNoticeTimeout > 0) {
			setTimeout(function() {
				it.elems('poll-site-work-notice').$el.addClass('active');
			}, window._pollSiteWorkNoticeTimeout * 1000);
		}
		else {
			it.elems('poll-site-work-notice').$el.addClass('active');
		}
	}
}

Footer.register();

export default Footer;