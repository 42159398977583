import BEM from 'tao-bem';

class VacanciesDetailPage extends BEM.Block {
	static get blockName() {
		return 'b-vacancies-detail-page';
	}

	onInit() {
		const it = this;

		//Переносим хлебные крошки во вложенный блок.
		//Если разместить его там изначально, то вёрстка крашится, почему?
		//Только господь знает(скорее всего из-за формирования хлебных через отложеные функции)
		const $breadcrumbs = it.$el.find(`.${it.block}__breadcrumb`);
		$breadcrumbs.removeClass(`${it.block}__breadcrumb`);

		it.$el.find('.b-vacancies-banner__content').prepend($breadcrumbs);

		it.elems('question').$el.click(function(e){
			if(e.target.tagName.toLowerCase() === "a") {
				return
			};

			const elem = $(this);

			if(elem.hasClass('active')) {
				it.elems('question').$el.removeClass('active');
			}else {
				it.elems('question').$el.removeClass('active');
				elem.addClass('active');
			}
		})
	}
}

VacanciesDetailPage.register();

export default VacanciesDetailPage;