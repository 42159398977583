import BEM from 'tao-bem';

class DocumentsPage extends BEM.Block {
	static get blockName() {
		return 'b-documents-page';
	}
}

DocumentsPage.register();

export default DocumentsPage;