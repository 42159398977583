import BEM   from 'tao-bem';
// TODO: Необходимо пофиксить пайплайн под работу с субмодулями.
// import { q } from '@lib/dom';
// import { waitConditionAlt } from '@lib/async';
import { namespace } from '@settings';

const isNode = o => {
  return (
    typeof Node === 'object' ? o instanceof Node
      : o && typeof o === 'object' && typeof o.nodeType === 'number' && typeof o.nodeName === 'string'
  );
};
const q = (selector, type = 'one', parent = document) => {
  if (!selector) return null;
  if (!isNode(parent)) return null;
  if (type === 'one') return parent.querySelector(selector);
  else if (type === 'all') return parent.querySelectorAll(selector);
  else if (type === 'id')    return document.getElementById(selector);
  else if (type === 'name')  return document.getElementsByName(selector);
  else if (type === 'tag')   return document.getElementsByTagName(selector);
  else if (type === 'class') return parent.getElementsByClassName(selector);
  return null;
};

const waitConditionAlt = async ({
  onCheck = _ => true,
  onWait = _ => _,
  onFail = _ => _,
  onComplit = _ => _,
  maxCount = 10,
  waitStep = 100
}) => {
  let count = 0;
  let timer = null;
  const process = _ => {
    count++;
    const status = onCheck && onCheck();
    onWait && onWait();
    if(status) {
      clearTimeout(timer);
      return onComplit();
    }
    if(count >= maxCount) {
      clearTimeout(timer);
      return onFail && onFail();
    }
    timer = setTimeout(_ => process({
      onCheck,
      onWait,
      onFail,
      onComplit,
      maxCount,
      waitStep,
    }), waitStep);
  };
  await process({
      onCheck,
      onWait,
      onFail,
      onComplit,
      maxCount,
      waitStep,
  });
};

let ModalManager = null;
const { log: _log = null } = console;
const isDebug = false;
const log = (...args) => isDebug ? _log(...args) : void null;

class AmazingClassName {
  static slcCardLogin  = '.b-request-support-page__button-login';
  static slcCardSignup = '.b-request-support-page__button-signup';
  elRoot      = null;
  elBtnLogin  = null;
  elBtnSignup = null;

  isReady     = false;
  constructor ({ element = null }) {
    if(!element) return null;
    log('🚲 constructor');
    this.elRoot = element;
    this.initElements();
    this.initEvents();
  }

  initElements() {
    log('🚲 initElements');
    this.elBtnLogin  = q(AmazingClassName.slcCardLogin);
    this.elBtnSignup = q(AmazingClassName.slcCardSignup);
    log('🚲 elements: ', this.elBtnLogin, this.elBtnSignup);
  }

  async initEvents() {
    log('🚲 initEvents;');
    const self = this;
    document.addEventListener('modal-manager-ready', ev => this.handlerReady(ev));
    const trigger = _ => {
    log('🚲 trigger');
      self.elBtnLogin && self.elBtnLogin.addEventListener('click', self.openLogin);
      self.elBtnSignup && self.elBtnSignup.addEventListener('click', self.openSignup);
    };

    await this.waitingForReady({ trigger });
  }

  async waitingForReady({ trigger }) {
    log('🚲 waitingForReady');
    return await waitConditionAlt({
      onCheck:   _ => this.isReady,
      onComplit: _ => trigger(),
      maxCount:  5000 / 100,
    })
  }

  handlerReady() {
    log('🚲 handlerReady');
    ModalManager = window[namespace].ModalManager;
    this.isReady = true;
    log('🚲 handlerReady', ModalManager, this.isReady);
  }

  openLogin (ev) {
    ev.preventDefault();
    log('🚲 openLogin');
    ModalManager  && ModalManager.open('ModalLogin');
  }
  openSignup (ev) {
    ev.preventDefault();
    log('🚲 openSignup');
    ModalManager && ModalManager.open('ModalSignUp');
  }

  static run ({ element }) {
    log('🚲 static run');
    new this({element});
  }
}

class RequestSupportPage extends BEM.Block {
  static get blockName() { return 'b-request-support-page'; }
  onInit() {
    const element = this;
    AmazingClassName.run({element});
  }
}

RequestSupportPage.register();

export default RequestSupportPage;
