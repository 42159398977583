import BEM from 'tao-bem';

class FreePage extends BEM.Block {
	static get blockName() {
		return 'b-free-page';
	}
}

FreePage.register();

export default FreePage;