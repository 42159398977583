import BEM from 'tao-bem';
import 'jquery.cookie';

class ProductsListLayout extends BEM.Block {
	static get blockName() {
		return 'b-products-list-layout';
	}

	onInit() {
		const it = this;

		it.ajaxCount = 0;
		const $filter = it.$el.find(`.${it.block}__filter`);
		const $filter_button = it.$el.find(`.${it.block}__filter-mobile`);
		const $filter_back = $filter_button.next().next();

		it.$el.find(`.${it.block}__reset`).click((event) => {
			event.preventDefault();
			it.$el.find(`.${it.block}__reset-mobile`).trigger('click');
		});

		it.CategoriesEvents();
		it.CategoriesUpdate();

		$filter_button.click(function() {
			$('body').addClass('modal-open');
			$filter_back.toggleClass('active');
			$filter.show();
			$.cookie('products_filter', 'true');
		});

		$filter.find('.b-filter__head-mobile-close').click(function() {
			$('body').removeClass('modal-open');
			$filter_back.toggleClass('active');
			$filter.hide();

			//Очищаем куку при закрытии фильтра
			$.removeCookie('products_filter', '');
		});

		//Эвент клика на серую область при активном фильтре
		$filter_back.click(() => {
			$('body').removeClass('modal-open');
			$filter_back.toggleClass('active');
			$filter.hide();

			//Очищаем куку при закрытии фильтра
			$.removeCookie('products_filter', '');
		});

		//Ставим эвент на декстопную версию категорий
		it.$el.find(`.${it.block}__categories.desktop`).find('.b-tags-list__all').click(function() {
			const $elem = $(this);

			if($elem.hasClass('active')) {
				$.cookie('products_categories_desktop', 'true');
			} else {
				$.removeCookie('products_categories_desktop');
			}
		});

		//Ставим эвент на мобильную версию категорий
		it.$el.find(`.${it.block}__categories.mobile`).find('.b-tags-list__all').click(function() {
			const $elem = $(this);

			//Событие самого блока выполняется быстрее чем это, поэтому проверка именно на наличие класса active
			if($elem.hasClass('active')) {
				$.cookie('products_categories_mobile', 'true');
			} else {
				console.log('кука mobile удалена');
				$.removeCookie('products_categories_mobile');
			}
		});

		//Так как ajax компонента битрикса перерисовывает весь свой контент - приходится делать так:
   		BX.addCustomEvent('onAjaxSuccess', function(data, event) {
			it.ajaxCount++;
			
			//Выполняется 2 запроса на этой странице, bitrix.list и smart.filter, сам bitrix не говорит особо что ajax, поэтому используем счётчик
			if(it.ajaxCount <= 2) {
				if($.cookie('products_filter')) {
					$(`.${it.block}__filter-mobile`).next().next().addClass('active');
					$(`.${it.block}__filter`).show();
				}
	
				//В категориях тригирим клик дважды, потому что в этом случае событие выполняется до события клика самого блока
				if($.cookie('products_categories_desktop') && it.ajaxCount == 2) {
					$(`.${it.block}__categories.desktop`).find('.b-tags-list__all').trigger('click');
					$(`.${it.block}__categories.desktop`).find('.b-tags-list__all').trigger('click');
				}

				//С мобильным то же самое
				if($.cookie('products_categories_mobile') && it.ajaxCount == 2) {
					$(`.${it.block}__categories.mobile`).find('.b-tags-list__all').trigger('click');
					$(`.${it.block}__categories.mobile`).find('.b-tags-list__all').trigger('click');
				}
			} else {
				// Уничтожаем объект, так как после перерисовки он не актуален
				//
				// Закомментировано в рамках TASK 1004951.
				// Счётчик иногда срабатывает на /bitrix/tools/public_session.php и страница очищается.
				// Поведения, где было бы необходимо уничтожение объекта, не найдено.
				//
				// it.destroy();
			}
		});
	}

	//Синхронизирует категории с фильтром
	CategoriesUpdate() {
		const it = this;
		const $categories = it.$el.find(`.b-tags-list__item`);

		$categories.each(function() {
			const $elem = $(this);
			const $filter_item = it.$el.find(`input[type="checkbox"][data-type="${$elem.data('type')}"][data-id="${$elem.data('id')}"]`);

			if($filter_item.prop('checked')) {
				$elem.addClass('active');
			}

			if($filter_item.closest('.b-filter__item').hasClass('disabled')) {
				$elem.addClass('disabled');
			}
		});
	}

	//Эвенты для категорий
	CategoriesEvents() {
		const it = this;

		it.$el.find(`.b-tags-list__item`).click(function() {
			const $elem = $(this);
			it.$el.find(`input[type="checkbox"][data-type="${$elem.data('type')}"][data-id="${$elem.data('id')}"]`).trigger('click');
		});
	}
}

ProductsListLayout.register();

export default ProductsListLayout;