import BEM from 'tao-bem';

class ProductsLogoWithBtns extends BEM.Block {
	static get blockName() {
		return 'b-products-logo-with-btns';
	}
}

ProductsLogoWithBtns.register();

export default ProductsLogoWithBtns;