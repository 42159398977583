import BEM from 'tao-bem';

class SolutionsIndustries extends BEM.Block {
	static get blockName() {
		return 'b-solutions-industries';
	}
}

SolutionsIndustries.register();

export default SolutionsIndustries;