import BEM from 'tao-bem';

class AuthConfirm extends BEM.Block {
	static get blockName() {
		return 'b-auth-confirm';
	}

	onInit() {
		const it = this;
	}
}

AuthConfirm.register();

export default AuthConfirm;