import BEM from 'tao-bem';
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox.css';

class PhotosPage extends BEM.Block {
	static get blockName() {
		return 'b-photos-page';
	}

	onInit() {
		const it = this;

		Fancybox.bind("[data-fancybox]", {
			// Your options go here
		});

		it.elems('tab').$el.click(function(){
			let $elem = $(this);

			it.elems('tab').$el.removeClass('active');
			$elem.addClass('active');

			if($elem.hasClass('tab-one')) {
				it.elems('items').$el.removeClass('tab-two');
				it.elems('items').$el.addClass('tab-one');
			}

			if($elem.hasClass('tab-two')) {
				it.elems('items').$el.removeClass('tab-one');
				it.elems('items').$el.addClass('tab-two');
			}
		});

		it.elems('item').$el.click(function(e) {
			if($(this).parent().hasClass('tab-two') && !$(e.target).hasClass(`${it.block}__item-btn`)) {
				e.preventDefault();
			}
		})
	}
}

PhotosPage.register();

export default PhotosPage;