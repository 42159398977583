import BEM from 'tao-bem';

class VacanciesBanner extends BEM.Block {
	static get blockName() {
		return 'b-vacancies-banner';
	}
}

VacanciesBanner.register();

export default VacanciesBanner;