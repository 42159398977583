import BEM from 'tao-bem';

class RefundEvents extends BEM.Block {
    static get blockName() {
        return 'b-refund-events';
    }

    onInit() {}
}

RefundEvents.register();

export default RefundEvents;
