import BEM from 'tao-bem';

class SuccessStoryPage extends BEM.Block {
	static get blockName() {
		return 'b-success-story-page';
	}
}

SuccessStoryPage.register();

export default SuccessStoryPage;