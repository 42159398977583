import BEM from "tao-bem";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import "swiper/css/bundle";

class Header extends BEM.Block {
    static get blockName() {
        return "b-header";
    }

    onInit() {
        const it = this;
        const layout = document.querySelector('.b-layout');

        if (window.pageYOffset > 0) {
            it.$el.addClass(`${it.block}--scroll`);
        }

        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 0) {
                it.$el.addClass(`${it.block}--scroll`);
            } else {
                if (!it
                    .elems("menu-hamburger")
                    .$el.hasClass(`${it.block}__menu-hamburger--active`)
                ) {
                    it.$el.removeClass(`${it.block}--scroll`);
                }
            }
        });

        it.elems("menu-hamburger").$el.click(function() {
            let $elem = $(this);
            $elem.toggleClass(`${it.block}__menu-hamburger--active`);
            const scrollModeSelector = 'b-layout--no-scroll';
            const scrollDisable = layout.classList.contains(scrollModeSelector);

            if (!$elem.hasClass(`${it.block}__menu-hamburger--active`) &&
                !window.pageYOffset > 0
            ) {
                it.$el.removeClass(`${it.block}--scroll`);
                if (scrollDisable) layout.classList.remove(scrollModeSelector);
            } else {
                it.$el.addClass(`${it.block}--scroll`);
                if (!scrollDisable) layout.classList.add(scrollModeSelector);
            }
            it.elems("mobile-menu").$el.toggleClass(
                `${it.block}__mobile-menu--active`
            );
        });

        it.elems("mobile-menu-item").$el.click(function() {
            let $elem = $(this);
            let tabId = $elem.attr("id");
            let link = $elem.data("link");
            let isActive = $elem.hasClass(`${it.block}__mobile-menu-item--active`);
            $elem.toggleClass(`${it.block}__mobile-menu-item--active`);
            $elem
                .parent()
                .find(`[id='${tabId}']`)
                .toggleClass(
                    `${it.block}__mobile-menu-sections-wrapper--active`
                );
            // if (isActive && link) window.location.href = link;
        });


        it.elems("mobile-menu-section").$el.click(function() {
            $(this).toggleClass(`${it.block}__mobile-menu-section--active`);
        });

        it.elems("menu-item").$el.click(function() {
            let $elem = $(this);
            let link = $elem.data("link");
            if (link)
                window.location.href = link;
        });

        it.elems("menu-item").$el.mouseover(function() {
            $(document).mouseup(function(e) {
                // событие клика по веб-документу
                if ($(e.target).closest(`.${it.block}__content-wrapper`).length)
                    return; //при клике на эти блоки не скрывать
                it.elems("menu-content").$el.removeClass(
                    `${it.block}__menu-content--active`
                );
                it.elems("menu-item").$el.removeClass(
                    `${it.block}__menu-item--active`
                );
                e.stopPropagation();
            });

            let $elem = $(this);
            let menuItemId = $elem.attr("id");
            it.$el.addClass(`${it.block}--white`);
            it.elems("menu-item").$el.removeClass(
                `${it.block}__menu-item--active`
            );
            it.elems("menu")
                .$el.find(`[id='${menuItemId}']`)
                .addClass(`${it.block}__menu-item--active`);
            it.elems("menu-content").$el.removeClass(
                `${it.block}__menu-content--active`
            );
            $(`.${it.block}__menu-content[data-id="${menuItemId}"]`).addClass(
                `${it.block}__menu-content--active`
            );
            it.$el.addClass(`${it.block}--shadow`)
        });

        it.elems("content").$el.mouseleave(function() {
            if (!it.$el.hasClass(`${it.block}--scroll`) &&
                !window.pageYOffset > 0 &&
                it.$el.data("is-main") == 1
            ) {
                it.$el.removeClass(`${it.block}--scroll`);
                it.$el.removeClass(`${it.block}--white`);
            } else {
                it.$el.addClass(`${it.block}--scroll`);
            }

            it.$el.removeClass(`${it.block}--shadow`)
            it.elems("menu-content").$el.removeClass(
                `${it.block}__menu-content--active`
            );
            it.elems("menu-item").$el.removeClass(
                `${it.block}__menu-item--active`
            );
        });

        $(document).ready(function() {

			it.disclaimerInit();
            it.elems("menu-section-card-close").$el.click(function() {
                $(this)
                    .parent(`.${it.block}__menu-section-card`)
                    .addClass("hide");
            });

            it.elems("menu-section-item").$el.click(function() {
                let $elem = $(this);
                let tabId = $elem.attr("id");
                let $parent = $elem.closest(`.${it.block}__menu-content`);
                $parent
                    .find(`.${it.block}__menu-section-link`)
                    .removeClass(`${it.block}__menu-section-link--active`);
                $parent
                    .find(`.${it.block}__menu-section-link[data-id='${tabId}']`)
                    .addClass(`${it.block}__menu-section-link--active`);

                $parent
                    .find(`.${it.block}__menu-section-item`)
                    .removeClass(`${it.block}__menu-section-item--active`);
                $parent
                    .find(`.${it.block}__menu-section`)
                    .find(`[id='${tabId}']`)
                    .addClass(`${it.block}__menu-section-item--active`);

                $parent
                    .find(`.${it.block}__menu-section-elems`)
                    .removeClass(`${it.block}__menu-section-elems--active`);

                $parent
                    .find(`.${it.block}__menu-section-right`)
                    .find(`.${it.block}__menu-section-elems[id='${tabId}']`)
                    .addClass(`${it.block}__menu-section-elems--active`);
            });
        });



        // Поиск по сайту

        const searchScroll = new PerfectScrollbar(
            it.$el.find(`.${it.block}__search-items`)[0], {
                suppressScrollX: true
            }
        );

        it.$el
            .find(`.${it.block}__search-items`)
            .on("ps-scroll-down", () => searchScroll.update());

        it.$el.find(`.${it.block}__menu-icon--loupe`).click(function() {
            it.$el.removeClass(`${it.block}--shadow`)
            it.elems("wrapper").$el.addClass("hide");
            it.elems("search").$el.addClass("active");
            it.$el.addClass(`${it.block}--search`);
            it.elems("menu-hamburger").$el.removeClass(
                `${it.block}__menu-hamburger--active`
            );
            it.elems("mobile-menu").$el.removeClass(
                `${it.block}__mobile-menu--active`
            );

            it.$el.find(`.${it.block}__search-field`).focus();
        });

        it.elems("search-close").$el.click(function() {
            it.elems("wrapper").$el.removeClass("hide");
            it.elems("search").$el.removeClass("active");
            it.elems("search-result").$el.removeClass("active");
            it.$el.find(`.${it.block}__search-field`).val("");
            it.$el.removeClass(`${it.block}--search`);
            $(".b-layout").removeClass("modal-open");
        });

        let handlerSearch = false;

        it.$el.find(`.${it.block}__search-field`).on("input", function() {
            if (handlerSearch) {
                clearTimeout(handlerSearch);
            }
            handlerSearch = setTimeout(() => {
                if ($(this).val()) {
                    it.requestSearchItems($(this).val()).then(data => {
                        it.elems("search-result").$el.addClass("active");
                        $(".b-layout").addClass("modal-open");

                        it.renderSearchItems(
                            data.items,
                            $(this).val(),
                            Object.keys(data.items).length
                        );

                        setTimeout(() => {
                            searchScroll.update();
                        }, 50);
                    });
                }
            }, 1000);
        });

        it.$el.find(`.${it.block}__search-field`).keypress(function(e) {
            if (e.which == 13) {
                clearTimeout(handlerSearch);
                if ($(this).val()) {
                    it.requestSearchItems($(this).val()).then(data => {
                        it.elems("search-result").$el.addClass("active");
                        $(".b-layout").addClass("modal-open");

                        it.renderSearchItems(
                            data.items,
                            $(this).val(),
                            Object.keys(data.items).length
                        );
                        setTimeout(() => {
                            searchScroll.update();
                        }, 50);
                    });
                }
                $(this).attr('readonly', 'readonly');
                $(this).attr('disabled', 'true');
                setTimeout(() => {
                    $(this).removeAttr('readonly');
                    $(this).removeAttr('disabled');
                }, 50);
            }
        });
    }
	disclaimerInit(){
		const getShowStatus = key => sessionStorage.getItem(key);
		const setShowStatus = (key, val = false) => sessionStorage.setItem(key, val);
		const show = el => {
			const mod = 'disclaimer_show';
			const hasMod = el.classList.contains(mod);
			if(!hasMod) el.classList.add(mod);
		}
		const sKey = 'disclaimer-InfoTeCS';
		const isShow = getShowStatus(sKey);
		const discl = document.querySelector(".disclaimer");
    if(!discl) return null;
		const btn = discl.querySelector("button");
		const brdc = document.querySelector(".b-layout__header");
		brdc.style.margin = "0";

		if(isShow === null || isShow === 'true') {
			setShowStatus(sKey, true);
			show(discl);
			brdc.style.margin = "46px 0 0 0";
		};

		btn.addEventListener("click", function() {
			discl.style.display = "none";
			setShowStatus(sKey);
			brdc.style.margin = "0";
		});
	}


    async requestSearchItems(search) {
        return $.ajax({
            url: `/local/api/search/ajax/getitems.php`,
            method: "get",
            dataType: "json",
            data: {
                search
            }
        });
    }

    renderSearchItems(items, search, totalCount) {
        const it = this;

        const container = it.elems("search-items").$el;

        container.html("");

        let iterationCount = 0;
        if (items.length == 0) {
            container.append(
                `<div class="${it.block}__search-total-count" style="margin-top: 20px"><p>Ничего не найдено</p></div>`
            );
            return;
        }

        for (const key in items) {
            iterationCount++;

            if (iterationCount > 3) {
                container.append(
                    `<div class="${it.block}__search-total-count"><a href="/search/?search=${search}">Смотреть все результаты (${totalCount})</a></div>`
                );
                break;
            }

            container.append(
                it.viewSearchItem(
                    items[key]["link"],
                    items[key]["category"],
                    items[key]["title"],
                    items[key]["desc"],
                    items[key]["file"]["icon"],
                    items[key]["file"]["link"]
                )
            );
        }
    }

    viewSearchItem(link, category, title, desc, fileImg, fileLink) {
        const it = this;

        return (
            `
			<a class="${it.block}__search-item" ` +
            (link != "" ? `href='${link}'>` : ">") +
            `
				<div class="${it.block}__search-item-content">
					<div class="${it.block}__search-item-category">
						${category}
					</div>
					<div class="${it.block}__search-item-title">
						${title}
					</div>
					<p class="${it.block}__search-item-desc">
						${desc}
					</p>` +
            (fileImg ?
                `<div class="${it.block}__search-item-link">
						<img src="${fileImg}" alt="">
						<object>
						<a target="_blank" href="${fileLink}">Скачать</a>
						</object>

					</div>` :
                fileLink ?
                `<div class="${it.block}__search-item-link"> <object><a target="_blank" href="${fileLink}">Скачать</a></object></div>` :
                "") +
            `</div>` +
            (fileImg ?
                `<img class="${it.block}__search-item-file-img" src="${fileImg}" alt="">` :
                ``) +
            `</a>`
        );
    }
}

Header.register();

export default Header;
