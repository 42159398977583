import BEM from 'tao-bem';

class EventsPage extends BEM.Block {
	static get blockName() {
		return 'b-events-page';
	}

	onInit() {
		const it = this;

		it.$filter = it.$el.find(".b-events-filter");

		const $select_speakers = it.$filter.find(`.b-events-filter__block-select`);
		const arProposeThemeSectionCodes = ['webinars', 'master-klassy'];

		let initialFilter = it.$el.data('filter');
		let needShowProposeThemeButton = false;

		// Отрисовка тегов
		Object.keys(initialFilter['sections']).forEach((key)=>{
			if(initialFilter['sections'][key]['active']) {
				if (BX.util.in_array(initialFilter['sections'][key]['code'], arProposeThemeSectionCodes))
					needShowProposeThemeButton = true;
				it.renderTag("Раздел: " + initialFilter['sections'][key]['name'],"section-id-" + initialFilter['sections'][key]['id'],'section')
			}
		});

		Object.keys(initialFilter['solutions']).forEach((key)=>{
			if(initialFilter['solutions'][key]['active']) {
				it.renderTag("Решение: " + initialFilter['solutions'][key]['name'],"solution-id-" + initialFilter['solutions'][key]['id'],'solution')
			}
		});

		Object.keys(initialFilter['speakers']).forEach((key)=>{
			if(initialFilter['speakers'][key]['active']) {
				it.renderTag("Спикер: " + initialFilter['speakers'][key]['name'],"speakerId-" + initialFilter['speakers'][key]['id'],'speaker')
			}
		});


		// Открытие фильтра
		it.elems('filter-btn').$el.click(function(e){
			$('.b-layout').addClass('modal-open');
			it.$filter.find('.b-events-filter__wrapper').addClass('active');
			setTimeout(() => {
				it.$filter.appendTo($('body'));
			}, 50);

		})

		it.elems('tab').$el.click(function() {
			if($(this).data('type') == 'planned') {
				it.$el.data('active-tab','one');
			}

			if($(this).data('type') == 'archive') {
				it.$el.data('active-tab' , 'two');
			}

			window.location.href = it.collectUrl(it.collectFilterData($select_speakers.val()));
		});

		it.elems('tag-close').$el.click(function(){
			$(this).parent(`.${it.block}__tag`).remove();
		});

		it.$filter.find('.b-events-filter__filter-item').click(function() {
			if($(this).hasClass('active')) {
				$(this).removeClass('active');
			}else {
				$(this).addClass('active');
			}
		});

		it.$filter.find('.b-events-filter__btn--filter').click(function() {
			window.location.href = it.collectUrl(it.collectFilterData($select_speakers.val()));
		});

		if (needShowProposeThemeButton) {
			it.elems('top-propose-theme-container').$el.removeClass('hidden').click(function(e) { it.openModal(e); });
			it.elems('bottom-propose-theme-container').$el.removeClass('hidden').click(function(e) { it.openModal(e); });
		}
	}

	openModal(e) {
		e.preventDefault();
		$('.b-layout').addClass('modal-open');
		$('.b-layout').addClass('header-hide');
		$('.b-propose-theme-form__wrapper').addClass('active');
	}

	collectUrl(filter) {
		const it = this;
		let newUrl = "/press-center/events/?";

		if (filter["section_id"].length != 0) {
			filter["section_id"].forEach(id => {
				newUrl += `filter[section_id][]=${id}&`;
			});
		}

		if (filter["p-solutions"].length != 0) {
			filter["p-solutions"].forEach(id => {
				newUrl += `filter[p-solutions][]=${id}&`;
			});
		}

		if (filter["p-spikers"].length != 0) {
			filter["p-spikers"].forEach(id => {
				newUrl += `filter[p-spikers][]=${id}&`;
			});
		}

		if (filter["active_from"]) {
			newUrl += `filter[active_from]=${filter["active_from"]}&`;
		}

		if (filter["active_to"]) {
			newUrl += `filter[active_to]=${filter["active_to"]}&`;
		}

		newUrl += `active_tab=${it.$el.data('active-tab')}`;

		return encodeURI(newUrl);
	}

	collectFilterData(speakers = false) {
		const it = this;

		let filter = {
			'section_id' : [],
			'p-solutions' : [],
			'p-spikers' : [],
			'active_from' : false,
			'active_to' : false,
		};


		if(it.$filter
			.find(".b-events-filter__block-field--from").val()) {
				let from = it.$filter
				.find(".b-events-filter__block-field--from").val().split('.');
				filter['active_from'] = +new Date(from[1],from[0] - 1 , 1) / 1000;
		}

		if(it.$filter
		.find(".b-events-filter__block-field--to").val()) {
			let to = it.$filter
			.find(".b-events-filter__block-field--to").val().split('.');
			filter['active_to'] = +new Date(to[1],to[0] - 1, new Date(to[1],to[0], 0).toLocaleString('RU', {day: 'numeric'})) / 1000;

		}




		if(speakers) {
			filter['p-spikers'] = speakers;
		}

		it.$filter
			.find(".b-events-filter__filter-item")
			.each(function() {
				if ($(this).hasClass("active")) {
					if ($(this).data("solution-id")) {
						filter["p-solutions"].push($(this).data("solution-id"));
					}
					if ($(this).data("section-id")) {
						filter["section_id"].push($(this).data("section-id"));
					}
				}
			});

		return filter;
	}

	renderTag(title, id, type) {
		const it = this;
		const tagsContainer = it.elems("tags").$el;
		let initialFilter = it.$el.data('filter');
		let speakers = [];

		let $tag = $(`
		<div class="${it.block}__tag" id='${id}' data-type="${type}">
			${title}
			<span class="${it.block}__tag-close">
			</span>
		</div>
		`);

		tagsContainer.append($tag);

		$tag.find(`.${it.block}__tag-close`).click(function() {
			const $elem = $(this);

			let id = $elem.parent().attr("id");

			$elem
				.parent(`.${it.block}__tag`)
				.remove();

			it.$el.find(`.b-events-filter__filter-item#${id}`).removeClass('active');

			if($elem.parent().data('type') == 'speaker') {
				Object.keys(initialFilter['speakers']).forEach((key)=>{
					if(initialFilter['speakers'][key]['active'] && key != id.split('-')[1]) {
						speakers.push(initialFilter['speakers'][key]['id'])
					}
				});
			}

			window.location.href = it.collectUrl(it.collectFilterData(speakers));
		});
	}
}

EventsPage.register();

export default EventsPage;