import BEM from 'tao-bem';

class DescriptionWithLogo extends BEM.Block {
	static get blockName() {
		return 'b-description-with-logo';
	}

	onInit() {
		const it = this;

		// Закрыть карточку при клике на крестик
		it.elems('card-close').$el.click(()=>it.elems('card').$el.addClass('hide'));
	}
}

DescriptionWithLogo.register();

export default DescriptionWithLogo;