import BEM from 'tao-bem';

class BrochuresPage extends BEM.Block {
	static get blockName() {
		return 'b-brochures-page';
	}

	onInit() {
		const it = this;

		if($(window).width() < 768) {
			it.elems('items').$el.removeClass('tab-one');
			it.elems('items').$el.addClass('tab-two');
		}

		it.elems('tab').$el.click(function(){
			let $elem = $(this);

			it.elems('tab').$el.removeClass('active');
			$elem.addClass('active');

			if($elem.hasClass('tab-one')) {
				it.elems('items').$el.removeClass('tab-two');
				it.elems('items').$el.addClass('tab-one');
			}

			if($elem.hasClass('tab-two')) {
				it.elems('items').$el.removeClass('tab-one');
				it.elems('items').$el.addClass('tab-two');
			}
		});
	}
}

BrochuresPage.register();

export default BrochuresPage;