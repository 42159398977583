import 'style/layout/work';
import 'block/common';
import 'block/cabinet';
import 'block/site';

(function () {
	"use strict";
	$(function () {
		//
	})
})();
