import BEM from 'tao-bem';

class PublicationsBlock extends BEM.Block {
	static get blockName() {
		return 'b-publications-block';
	}

	onInit() {
		const it = this;

		setTimeout(() => {
			let maxItemContentHeight = 0;
			it.$el.find(`.${it.block}__items-right`).find(`.b-publication-item__content`).each(function(){
				$(this).css('height','auto');

				if ( $(this).height() > maxItemContentHeight ) {
					maxItemContentHeight = $(this).height();
				}
			});
			it.$el.find('.b-publication-item__content').height(maxItemContentHeight);
		}, 50);

		// Выравнивание картинки по высоте
		if($(window).width() >= 1040) {
			setTimeout(() => {
				let firstItemImage = 26 + it.$el.find(`.${it.block}__items-right`).find(`.b-publication-item`).first().height() + it.$el.find(`.${it.block}__items-right`).find(`.b-publication-item__image`).first().height();
			it.$el.find(`.${it.block}__items-left`).find(`.b-publication-item__image`).css('height',firstItemImage  + 'px');
			}, 50);
		}
	}
}

PublicationsBlock.register();

export default PublicationsBlock;