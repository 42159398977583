import {
  type,
  isArray,
  isString,
  isNumber,
  isObject
} from './type.js';
import math from './math.js';
import { objectToCamelCase, apToObjectValue, apToObjectKeys } from './object.js';

export const co = o => JSON.parse(JSON.stringify(o));
export const p = s => JSON.parse(s);
export const s = o => JSON.stringify(o);

export const len = _ => {
  if (isNumber(_)) return String(_).length;
  if (isString(_)) return _.length;
  if (isArray(_)) return _.length;
  if (!isArray(_) && isObject(_)) return Object.keys(_).length;
  return -1;
};

export const gebi = (id, arr, key = 'id') => {
  /*** DEPRECATED ***/
  /* Get Element By Id */
  if (id === undefined) return false;
  if (arr === undefined) return false;
  if (arr.length === 0) return false;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === id) {
      return {
        index: i,
        element: arr[i]
      };
    }
  }
  return false;
};

export const splice = (q, arr) => {
  const index = arr.findIndex(s => s === q);
  if (arr !== -1) arr.splice(index, 1);
  return arr;
};

export const has = (q, arr) => arr.includes(q);

export const listGuard = (value, array, def = false) => array.includes(value) ? value : !def ? array[0] : def;

export const sortRnd = _array => {
  let array = co(_array);
  let indexes = [];
  let newArray = [];
  for (var i = 0; i < array.length; i++) {
    let newIndex = math.getRndIntLimited(indexes, 0, array.length - 1);
    indexes.push(newIndex);
    newArray.push(array[newIndex]);
  }
  return newArray;
};
export const getItemInObjArrByID = (id, array, key = 'id') => {
  for (var i = 0; i <= array.length; i++) {
    if (array[i] &&
      type(array[i]) === 'object' &&
      array[i].hasOwnProperty(key) &&
      String(array[i][key]) === String(id)
    ) {
      return {
        index: i,
        object: array[i]
      };
    }
  }
  return false;
};

export const getAddedElements = (arrA, arrB) => {
  let notExistInA = [];
  arrB.forEach(itemB => !arrA.includes(itemB) ? notExistInA.push(itemB) : false);
  return notExistInA;
};

export const getRemovedElements = (arrA, arrB) => {
  let deleted = [];
  arrA.forEach(itemA => {
    let index = arrB.indexOf(itemA);
    if (index === -1) deleted.push(itemA);
  });
  return deleted;
};
export const getIntersectedElements = (arrA, arrB) => {
  let intersected = [];
  arrB.forEach(itemB => arrA.includes(itemB) ? intersected.push(itemB) : false);
  return intersected;
};

export const compare = (arrA, arrB) => {
  return {
    added: getAddedElements(arrA, arrB),
    deleted: getRemovedElements(arrA, arrB),
    intersected: getIntersectedElements(arrA, arrB)
  };
};

export const everyLow = (a, e) => {
  for (let i = 0; i < a.length; i++) if (a[i] !== e) return false;
  return true;
};
export const someLow = (a, e) => {
  for (let i = 0; i < a.length; i++) if (a[i] === e) return true;
  return false;
};

export const padEnd = (_arr, symb, length) => {
  let arr = co(_arr);
  for (let i = 0; i < length; i++) arr.push(symb);
  return arr;
};
export const zip = (...arg) => {
  let max = [...arg]
    .reduce(({ length: a }, { length: b }) => a > b ? a : b, 0);
  let arrays = [...arg]
    .map(a => padEnd(a, null, max - a.length));
  let res = [];
  for (let x = 0; x < max; x++) res.push(arrays.map(arr => arr[x]));
};
export const arrayToCamelCase = arr => arr.map(o => objectToCamelCase(o));
export const apToArrayValue = (a, fn) => {
  const process = val => {
    if (isObject(val)) return apToObjectValue(val, fn);
    else if (isArray(val)) return apToArrayValue(val, fn);
    else return fn(val);
  };
  return a
    .map(value => process(value))
};

export const apToArrayKeys = (a, fn) => {
  const process = val => {
    if (isObject(val)) return apToObjectKeys(val, fn);
    else if (isArray(val)) return apToArrayValue(val, fn);
    else return fn(val);
  };
  return a
    .map(value => process(value))
};

export default {
  apToArrayValue,
  apToArrayKeys,
  arrayToCamelCase,
  co,
  compare,
  everyLow,
  gebi,
  getAddedElements,
  getIntersectedElements,
  getItemInObjArrByID,
  getRemovedElements,
  has,
  len,
  listGuard,
  p,
  padEnd,
  s,
  someLow,
  sortRnd,
  splice,
  zip,
};