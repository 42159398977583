import BEM from 'tao-bem';

class SolutionsList extends BEM.Block {
	static get blockName() {
		return 'b-solutions-list';
	}

	onInit() {
		const it = this;

		// Высота заголовка по самому большому
		let maxHeight = it.blocksMaxHeight(`.b-solution-list-item__title`);
		it.$el.find('.b-solution-list-item__title').css('min-height',maxHeight);
	}

	blocksMaxHeight(selector) {
		const it = this;

		return Math.max.apply(Math, it.$el.find(selector).map(function(){
			return $(this).height();
		}).get());
	}
}

SolutionsList.register();

export default SolutionsList;