import BEM from 'tao-bem';

class EcosystemTabs extends BEM.Block {
	static get blockName() {
		return 'b-ecosystem-tabs';
	}

	onInit() {
		const it = this;

		it.elems('item').$el.click(function(e) {
			if($(this).attr('href') == '') {
				e.preventDefault();
			}
		});
	}
}

EcosystemTabs.register();

export default EcosystemTabs;