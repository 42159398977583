import BEM from 'tao-bem';
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox.css';

class SolutionsScenarios extends BEM.Block {
	static get blockName() {
		return 'b-solutions-scenarios';
	}

	onInit() {
		const it = this;

		Fancybox.bind("[data-fancybox]", {
			// Your options go here
		});
	}
}

SolutionsScenarios.register();

export default SolutionsScenarios;
