import BEM from 'tao-bem';

class ProfileInfo extends BEM.Block {
  static get blockName() {
		return 'b-profile-info';
	}

	onInit() { }
}

ProfileInfo .register();

export default ProfileInfo ;
