import BEM from 'tao-bem';

class SuccessStoryDetailPage extends BEM.Block {
	static get blockName() {
		return 'b-success-story-detail-page';
	}

	onInit() {
		const it = this;

		//Переносим хлебные крошки во вложенный блок.
		//Если разместить его там изначально, то вёрстка крашится, почему?
		//Только господь знает(скорее всего из-за формирования хлебных через отложеные функции)
		const $breadcrumbs = it.$el.find(`.${it.block}__breadcrumb`);
		$breadcrumbs.removeClass(`${it.block}__breadcrumb`);
		$breadcrumbs.appendTo(it.$el.find('.b-solutions-banner__breadcrumbs-wrapper'));
	}
}

SuccessStoryDetailPage.register();

export default SuccessStoryDetailPage;