import BEM from 'tao-bem';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';


class VacanciesFilter extends BEM.Block {
	static get blockName() {
		return 'b-vacancies-filter';
	}


	onInit() {
		const it = this;

		it.$el.find(`.${it.block}__group-head`).click(function() {
			const $elem = $(this);

			$elem.closest(`.${it.block}__group `).toggleClass('active');
		});
	}
}

VacanciesFilter.register();

export default VacanciesFilter;