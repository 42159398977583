import BEM from 'tao-bem';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'component/perfect-scrollbar/style.scss';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min.js';
import 'component/select2/style.scss';

class SubscribeForm extends BEM.Block {
	static get blockName() {
		return 'b-subscribe-form';
	}

	onInit() {
		const it = this;
		const $form = it.$el.find('form');
		const link = '/local/templates/site/components/bitrix/form.result.new/subscribe-form/ajax.php';
		const $select = it.$el.find(`.${it.block}__field--select`);

		it.$el.find(`.${it.block}__politic-checkbox`).change( function(){
			if($(this).is(':checked')) {
				it.$el.find(`.${it.block}__btn`).attr('disabled', false);
			} else {
				it.$el.find(`.${it.block}__btn`).attr('disabled', true);
			}
		});

		it.$el.find(`.${it.block}__success-message-link`).click(function(){
			it.$el.find(`.${it.block}__form-content`).removeClass('hide');
			it.$el.find(`.${it.block}__success-message`).removeClass('active');
		});

		// select
		$select.select2(
			{
				theme: 'white-single',
				closeOnSelect: true,
				minimumResultsForSearch: Infinity,
				dropdownCssClass: 'b-subscribe-form__field-wrapper',
				language: {
					noResults: function() {
						return "Ничего не найдено";
					},
				},
				escapeMarkup: function (markup) {
					return markup;
				}
			}
		);

		$select.val();

		setTimeout(()=>{
			it.$el.find('.select2-search__field').addClass('black');
			it.$el.find('.select2-search__field').attr('placeholder', $select.find(':selected')[0].innerText)
		},50)

		$select.on('select2:selecting',function(){
			$select.val([]);
		});

		$select.on('select2:select',function() {
			$(this).off('select2:select');
			$select.val();

			setTimeout(()=>{
				it.$el.find('.select2-search__field').addClass('black');
				it.$el.find('.select2-search__field').attr('placeholder', $select.find(':selected')[0].innerText)
			},50)


			$select.on('select2:select',function() {

				$select.val();

				it.$el.find('.select2-search__field').attr('placeholder', $select.find(':selected')[0].innerText)

			});
		})
		let selectScroll = false;

		$select.on('select2:open',function() {
			if(!selectScroll) {
				selectScroll = new PerfectScrollbar($('.select2-results__options')[0],{
					maxScrollbarLength: 100,
					suppressScrollX: true,
					wheelPropagation: false,
				});
			}else {
				selectScroll.update();
			}
		});

		$select.on('select2:unselecting',function(e) {
			e.preventDefault();
		});
		// select
                
                registerRequiredFieldsValidation(it);

		BX.bind($form[0], 'submit', BX.proxy(function(e) {
			BX.PreventDefault(e);
                        
                        var arMaskValidateErrors = validateInputFields(it);
			if (arMaskValidateErrors.length) {
				it.$el.find(`.${it.block}__errors`).addClass('active').html(arMaskValidateErrors.join('<br>'));
				return false;
			}
                        
			let xhr = new XMLHttpRequest();
			xhr.open('POST', link);

			xhr.onload = function() {
				if (xhr.status != 200) {
					alert(`Ошибка ${xhr.status}: ${xhr.statusText}`);
				} else {
					var json = JSON.parse(xhr.responseText)

					if (!json.success) {
						let errorStr = '';
						let requiredError = 'Не заполнены следующие обязательные поля: ';
						let hasRequiresError = false;

						for (let fieldKey in json.errors) {
							if(json.errors[fieldKey].includes('Не заполнены следующие обязательные поля:')) {
								requiredError+= json.errors[fieldKey].split('Не заполнены следующие обязательные поля:')[1] + ', ';
								hasRequiresError = true;
							}else {
								errorStr += json.errors[fieldKey] + '<br>';
							}
						}

						if(hasRequiresError) {
							errorStr += requiredError.substr(0, requiredError.length - 2) + '<br>';
						}

						it.$el.find(`.${it.block}__errors`).html(errorStr);
					} else {
						//Всё отправленно
						$form.find(`.${it.block}__errors`).html('');
						$form.find(`input:not([type=hidden])`).val('');
						$form.find(`textarea`).val('');
						it.elems('form-content').$el.addClass('hide');
						it.elems('success-message').$el.addClass('active');
						//it.elems('form-wrapper-scroll').$el.scrollTop(0);
						//formScroll.update();
					}
				}
			};

			xhr.onerror = function() {
				console.log('Запрос не удался')
			};

			// Передаем все данные из формы
			xhr.send(new FormData($form[0]));
		}, $form[0], link));
	}
}

SubscribeForm.register();

export default SubscribeForm;