import BEM from 'tao-bem';

class ContactsInfo extends BEM.Block {
	static get blockName() {
		return 'b-contacts-info';
	}
}

ContactsInfo.register();

export default ContactsInfo;
