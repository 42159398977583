import BEM from 'tao-bem';
import 'jquery.maskedinput/src/jquery.maskedinput.js';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'component/perfect-scrollbar/style.scss';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min.js';
import 'component/select2/style.scss';
/*
	global BX
*/

class ProposeThemeForm extends BEM.Block {
	static get blockName() {
		return 'b-propose-theme-form';
	}

	onInit() {
		const it = this;
		const $form = it.$el.find('form');
		const link = '/local/templates/site/components/bitrix/form.result.new/propose-theme-form/ajax.php';
		const $phone = it.$el.find(`.${it.block}__field--phone`);
		const $select_age = it.$el.find(`.select-age`);
		const $select_business_types = it.$el.find(`.select-business-types`);
		const $select_business_types_counter = it.$el.find(`.${it.block}__field-label-count.business-types`);
                
		setTimeout(() => {
			// business-types select
			$select_business_types.select2(
				{
					placeholder: 'Выберите из списка',
					theme: 'white',
					closeOnSelect: false,
					selectionCssClass: 'select-business-types',
					language: {
						noResults: function() {
							return "Ничего не найдено";
						},
					},
					dropdownCssClass: 'select-business-types',
					minimumResultsForSearch: Infinity,
					sorter: data => data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1)
				}
			);

			setTimeout(()=>{
				it.$el.find('.select-business-types + span .select2-search__field').addClass('black').prop('readonly', true);
			},50)

			$select_business_types.on('select2:select',function() {
				$(this).off('select2:select');

				$('.select-business-types').find('.select2-results__option--disabled').remove();

				it.$el.find(".select-business-types").find('.no-choice-option').remove();

				let data = $select_business_types.find(':selected');
				data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1);
				setTimeout(()=>{
					it.$el.find('.select-business-types + span .select2-search__field').addClass('black').prop('readonly', true);
					it.$el.find('.select-business-types + span .select2-search__field').attr('placeholder', data[data.length - 1].label)
					$select_business_types_counter.addClass('active');
					$select_business_types_counter.text(data.length);
				},50)

				$select_business_types.on('select2:select',function() {
					data = $select_business_types.find(':selected')
					data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1);
					it.$el.find('.select-business-types + span .select2-search__field').attr('placeholder', data[data.length - 1].label)
					$select_business_types_counter.addClass('active');
					$select_business_types_counter.text(data.length);
				});
			});

			$select_business_types.on('select2:unselect',function() {
				let data = $select_business_types.find(':selected');
				data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1);

				if(data.length != 0) {
					it.$el.find('.select-business-types + span .select2-search__field').attr('placeholder', data[data.length - 1].label)
					$select_business_types_counter.text(data.length);
				} else {
					$select_business_types_counter.removeClass('active');
				}
			});
			let select_business_types_scroll = false;
			$select_business_types.on("select2:open", function() {
				if(!select_business_types_scroll){
					select_business_types_scroll = new PerfectScrollbar($('.select-business-types .select2-results__options')[0],{
						maxScrollbarLength: 100,
						suppressScrollX: true,
						wheelPropagation: false
					});
				}else {
					select_business_types_scroll.update()
				}
			});
			// business-types select

			// age select
			$select_age.select2(
				{
					theme: 'white-single',
					closeOnSelect: true,
					minimumResultsForSearch: Infinity,
					//dropdownCssClass: `${it.block}__field-wrapper`,
					dropdownCssClass: 'select-age',
					language: {
						noResults: function() {
							return "Ничего не найдено";
						},
					},
					escapeMarkup: function (markup) {
						return markup;
					}
				}
			);
			$select_age.val();
	    
			setTimeout(()=>{
				it.$el.find('.select-age + span .select2-search__field').addClass('black').prop('readonly', true);
				it.$el.find('.select-age + span .select2-search__field').attr('placeholder', $select_age.find(':selected')[0].innerText)
			},50)
	    
			$select_age.on('select2:selecting',function(){
				$select_age.val([]);
			});
	    
			$select_age.on('select2:select',function() {
				$(this).off('select2:select');
				$select_age.val();

				setTimeout(()=>{
					it.$el.find('.select-age + span .select2-search__field').addClass('black');
					it.$el.find('.select-age + span .select2-search__field').attr('placeholder', $select_age.find(':selected')[0].innerText)
				},50)
	    
				$select_age.on('select2:select',function() {
					$select_age.val();
					it.$el.find('.select-age + span .select2-search__field').attr('placeholder', $select_age.find(':selected')[0].innerText)
				});
			})
	    
			/*let selectAgeScroll = false;
			$select_age.on('select2:open',function() {
				if(!selectAgeScroll) {
					//selectAgeScroll = new PerfectScrollbar($('.select-age .select2-results__options')[0],{
					selectAgeScroll = new PerfectScrollbar($('.select2-results__options')[0],{
						maxScrollbarLength: 100,
						suppressScrollX: true,
						wheelPropagation: false,
					});
				}else {
					selectAgeScroll.update();
				}
			});*/
	    
			$select_age.on('select2:unselecting',function(e) {
				e.preventDefault();
			});
			// age select
		}, 50);

		let formScroll = new PerfectScrollbar(it.$el.find(`.${it.block}__form-wrapper-scroll`)[0],{
			maxScrollbarLength: 100,
			suppressScrollX: true,
			wheelPropagation: false
		});

		it.elems('close-btn').$el.click(function(){
			it.closeModal();
		});

		$(`.${it.block}__checkboxes label`).on('click', function(e) {
			let $checkboxes = $(this).closest(`.${it.block}__checkboxes`);
			$checkboxes.find('label').removeClass('active');
			$(this).addClass('active');
			$checkboxes.find('input[type=hidden]').val($(this).data('value'));
		});

		it.elems('wrapper').$el.click(function (e){
			if ($(e.target).closest(`.${it.block}__form-wrapper-scroll`).length) return;   //при клике на эти блоки не скрывать
			if ($(e.target).closest(`.${it.block}__close-btn-wrapper`).length) return;
			it.closeModal();
			e.stopPropagation();
		});
                
                registerValidator(it, $phone, 'phone');
                registerRequiredFieldsValidation(it);

		BX.bind($form[0], 'submit', BX.proxy(function(e) {
			BX.PreventDefault(e);
                        
                        var arMaskValidateErrors = validateInputFields(it);
			if (arMaskValidateErrors.length) {
				it.$el.find(`.${it.block}__errors`).addClass('active').html(arMaskValidateErrors.join('<br>'));
				return false;
			}

			let xhr = new XMLHttpRequest();
			xhr.open('POST', link);

			xhr.onload = function() {
				if (xhr.status != 200) {
					alert(`Ошибка ${xhr.status}: ${xhr.statusText}`);
				} else {
					var json = JSON.parse(xhr.responseText)

					if (!json.success) {
						let errorStr = '';
						let requiredError = 'Не заполнены следующие обязательные поля: ';
						let hasRequiresError = false;

						for (let fieldKey in json.errors) {
							if(json.errors[fieldKey].includes('Не заполнены следующие обязательные поля:')) {
								requiredError+= json.errors[fieldKey].split('Не заполнены следующие обязательные поля:')[1] + ', ';
								hasRequiresError = true;
							}else {
								errorStr += json.errors[fieldKey] + '<br>';
							}
						}

						if(hasRequiresError) {
							errorStr += requiredError.substr(0, requiredError.length - 2) + '<br>';
						}

						it.$el.find(`.${it.block}__errors`).html(errorStr);
					} else {
						//Всё отправленно
						$form.find(`.${it.block}__errors`).html('');
						$form.find(`input:not([type=hidden])`).val('');
						$form.find(`textarea`).val('');
						$form.find(`select`).val('').val([]);
						it.$el.find(".select2-search__field").attr("placeholder", 'Выберите из списка');
						it.$el.find(`.${it.block}__field-label-count`).removeClass('active').text('1');

						it.elems('form-content').$el.addClass('hide');
						it.elems('success-message').$el.addClass('active');
						it.elems('form-wrapper-scroll').$el.scrollTop(0);
						formScroll.update();
					}
				}
			};

			xhr.onerror = function() {
				console.log('Запрос не удался')
			};

			// Передаем все данные из формы
			xhr.send(new FormData($form[0]));
		}, $form[0], link));
	}

	closeModal() {
		const it = this;
		it.elems('wrapper').$el.removeClass('active');
		$('.b-layout').removeClass('modal-open');
		$('.b-layout').removeClass('header-hide');
	}
}

ProposeThemeForm.register();

export default ProposeThemeForm;