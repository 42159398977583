import BEM from 'tao-bem';

class EventsBlock extends BEM.Block {
	static get blockName() {
		return 'b-events-block';
	}
}

EventsBlock.register();

export default EventsBlock;