import BEM from 'tao-bem';

class YoutubeVideo extends BEM.Block {
	static get blockName() {
		return 'b-youtube-video';
	}
}

YoutubeVideo.register();

export default YoutubeVideo;