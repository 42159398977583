import BEM from 'tao-bem';

class DemoPage extends BEM.Block {
	static get blockName() {
		return 'b-demo-page';
	}
}

DemoPage.register();

export default DemoPage;