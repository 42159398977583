import BEM from 'tao-bem';

class MarketingResearchPage extends BEM.Block {
	static get blockName() {
		return 'b-marketing-research-page';
	}
}

MarketingResearchPage.register();

export default MarketingResearchPage;