import BEM from 'tao-bem';

class CategoriesList extends BEM.Block {
	static get blockName() {
		return 'b-categories-list';
	}

	onInit() {
		const it = this;

		it.elems('category').$el.click(function() {

			let $elem = $(this);
			let categoryId = $elem.attr('id');


			it.elems('category').$el.removeClass(`${it.block}__category--active`);
			it.elems('items').$el.removeClass(`${it.block}__items--active`)

			it.elems('categories').$el.find(`[id='${categoryId}']`).addClass(`${it.block}__category--active`);

			it.elems('content-wrapper').$el.find(`[id='${categoryId}']`).addClass(`${it.block}__items--active`);
		});

		it.$el.find('.b-solution-list-item').mouseleave(function(){
			$(this).removeClass(`b-solution-list-item--active`)
		})

		if($(`.b-solution-list-item--active`).length) {
			$('html, body').animate({
				scrollTop: $(`.b-solution-list-item--active`).offset().top - 300  // класс объекта к которому приезжаем
			}, 1000);
		}
		let maxHeight = it.blocksMaxHeight(`.b-solution-list-item__title`);
		it.$el.find('.b-solution-list-item__title').css('min-height',maxHeight);
	}

	blocksMaxHeight(selector) {
		const it = this;

		return Math.max.apply(Math, it.$el.find(selector).map(function(){
			return $(this).height();
		}).get());
	}
}

CategoriesList.register();

export default CategoriesList;