import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

class ShareholdersPage extends BEM.Block {
	static get blockName() {
		return 'b-shareholders-page';
	}

	onInit() {
		const it = this;

		it.elems('tab-content').$el.click(function(){
			it.elems('tab-content').$el.removeClass('active');
			$(this).addClass('active');
			let sectionName = $(this).data('section-name');
			it.elems('content').$el.removeClass('active');
			it.$el.find(`.${it.block}__content[data-section-name="${sectionName}"]`).addClass('active');
		})

		it.addTableScrollIcon();

		if($(window).width() <= 1040) {
			new Swiper(it.elem('sections-slider').el, {
				speed: 600,
				slidesPerView: 'auto',
			});
		}
	}

	addTableScrollIcon() {
		const it = this;

		it.$el.find(`.${it.block}__table-wrapper`).each(function(){
			$(this).scroll(function(){
				$(this).find('.table-hand-scroll').css('left',20 + $(this).scrollLeft() + 'px');
				$(this).find('.table-hand-scroll').addClass('hide');
			});
		})

		it.$el.find('table').each(function(){
			$(this).find('thead').find('tr').first().find('th').first().append('<span class="table-hand-scroll"></span>')
		});
	}
}

ShareholdersPage.register();

export default ShareholdersPage;