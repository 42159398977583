import BEM from 'tao-bem';

class AcademyPage extends BEM.Block {
	static get blockName() {
		return 'b-academy-page';
	}
}

AcademyPage.register();

export default AcademyPage;