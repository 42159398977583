import BEM from 'tao-bem';

class WarningBlock extends BEM.Block {
	static get blockName() {
		return 'b-warning-block';
	}
}

WarningBlock.register();

export default WarningBlock;