import BEM from 'tao-bem';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

class FileItem extends BEM.Block {
	static get blockName() {
		return 'b-file-item';
	}

	onInit() {
		const it = this;
		let slider = it.initSlider();

		it.$el.find(`.${it.block}__content`).click(function(e){
			if ($(e.target).closest(`.${it.block}__btn-wrapper`).length) return;   //при клике на эти блоки не скрывать
			e.preventDefault();
			$('.b-layout').addClass('modal-open');
			$('.b-layout').addClass('header-hide');
			$(this).closest(`.${it.block}`).find(`.${it.block}__modal-wrapper`).addClass('active');

			if(slider) {
				slider.update();
			}
		});

		it.$el.find(`.${it.block}__modal-wrapper`).click(function(e){
			if ($(e.target).closest(`.${it.block}__modal`).length) return;   //при клике на эти блоки не скрывать
			it.closeModal();
			it.$el.find(`.${it.block}__modal-wrapper`).removeClass('active')
			e.stopPropagation();
		});

		it.$el.find(`.${it.block}__modal-close`).click(function(){
			$(this).closest(`.${it.block}__modal-wrapper`).removeClass('active');
			it.closeModal();
		});



		it.$el.find(`.${it.block}__modal`).each(function() {
			new PerfectScrollbar($(this)[0],{
				maxScrollbarLength: 100,
				suppressScrollX: true
			});
		})

	}

	initSlider() {
		const it = this;
		if(it.elem('modal-image-slider')) {
			return new Swiper(it.elem('modal-image-slider').el, {
				speed: 600,
				spaceBetween: 20,
				slidesPerView: 1,
				effect: 'fade',
				fadeEffect: {
					crossFade: true
				  },

				  pagination: {
					el: `.${it.block}__slider-bullets`,
					type: 'bullets',
					bulletClass: `${it.block}__slider-bullet`,
					bulletElement: 'span',
					bulletActiveClass: `${it.block}__slider-bullet--active`,
					clickable: true
				},
			});
		}
	}

	closeModal() {
		const it = this;
		it.$el.find(`.${it.block}__modal-wrapper`).removeClass('active');
		$('.b-layout').removeClass('modal-open');
		$('.b-layout').removeClass('header-hide');
	}
}

FileItem.register();

export default FileItem;