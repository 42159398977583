import BEM from 'tao-bem';
import 'jquery.maskedinput/src/jquery.maskedinput.js';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

class PublicationsFilter extends BEM.Block {
	static get blockName() {
		return 'b-publications-filter';
	}

	onInit() {
		const it = this;

		it.elems('close-btn').$el.click(function(){
			it.closeModal();
		});

		it.elems('block-field').$el.each(function(){
			$(this).mask("99.9999",{placeholder:"мм.гггг",autoclear: false,reverse: true});

		})

		it.elems('wrapper').$el.click(function (e){
			if ($(e.target).closest(`.${it.block}__wrapper-scroll`).length) return;   //при клике на эти блоки не скрывать
			if ($(e.target).closest(`.${it.block}__close-btn-wrapper`).length) return;
			it.closeModal();
			e.stopPropagation();
		});


		it.$el.find(`.${it.block}__btn--reset`).click(function() {
			it.$el.find(`.${it.block}__filter-item`).removeClass('active');
			it.$el.find(`.${it.block}__block-field`).val('');
		});

		new PerfectScrollbar(it.$el.find(`.${it.block}__wrapper-scroll`)[0],{
			maxScrollbarLength: 100,
			suppressScrollX: true
		});
	}

	closeModal() {
		const it = this;
		it.$el.find(`.${it.block}__wrapper`).removeClass('active');
		$('.b-layout').removeClass('modal-open');
	}
}

PublicationsFilter.register();

export default PublicationsFilter;