import BEM from 'tao-bem';

class BetaPage extends BEM.Block {
	static get blockName() {
		return 'b-beta-page';
	}
}

BetaPage.register();

export default BetaPage;