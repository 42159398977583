import BEM from 'tao-bem';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'component/perfect-scrollbar/style.scss';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min.js';
import 'component/select2/style.scss';

class SubscribeFormOld extends BEM.Block {
	static get blockName() {
		return 'b-subscribe-form-old';
	}

	onInit() {
		const it = this;
		const $select = it.elems('form-select').$el;

		it.elems('form-submit').$el.click(function(e){
			e.preventDefault();
			it.elems('success-message').$el.delay(250).fadeIn(300);
			it.elems('success-message').$el.delay(2500).fadeOut(300);
		});

		// select
		$select.select2(
			{
				placeholder: 'Выберите из списка',
				theme: 'white-single',
				closeOnSelect: true,
				minimumResultsForSearch: Infinity,
				dropdownCssClass: 'b-subscribe-form__field-wrapper',
				language: {
					noResults: function() {
						return "Ничего не найдено";
					},
				},
				escapeMarkup: function (markup) {
					return markup;
				}
			}
		);

		$select.val();

		setTimeout(()=>{
			it.$el.find('.select2-search__field').addClass('black');
			it.$el.find('.select2-search__field').attr('placeholder', $select.val())
		},50)

		$select.on('select2:selecting',function(){
			$select.val([]);
		});

		$select.on('select2:select',function() {
			$(this).off('select2:select');
			$select.val();

			setTimeout(()=>{
				it.$el.find('.select2-search__field').addClass('black');
				it.$el.find('.select2-search__field').attr('placeholder', $select.val())
			},50)


			$select.on('select2:select',function() {

				$select.val();

				it.$el.find('.select2-search__field').attr('placeholder', $select.val())

			});
		})
		let selectScroll = false;

		$select.on('select2:open',function() {
			if(!selectScroll) {
				selectScroll = new PerfectScrollbar($('.select2-results__options')[0],{
					maxScrollbarLength: 100,
					suppressScrollX: true
				});
			}else {
				selectScroll.update()
			}
		});

		$select.on('select2:unselecting',function(e) {
			e.preventDefault();
		});
		// select
	}
}

SubscribeFormOld.register();

export default SubscribeFormOld;