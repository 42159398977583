import BEM from 'tao-bem';

class TagsList extends BEM.Block {
	static get blockName() {
		return 'b-tags-list';
	}

	onInit() {
		const it = this;
		const $all = it.$el.find(`.${it.block}__all`);
		const $items = it.$el.find(`.${it.block}__item`);
		const items_visible = it.$el.data('items-visible');

		if($all.lenght != 0) {
			$all.click(() => {
				if(!$all.hasClass('active')) {
					$items.removeClass('hidden');
					$all.addClass('active');
					$all.html('Скрыть');
				} else {
					$items.slice(items_visible).addClass('hidden');
					$all.removeClass('active');
					$all.html('Показать все категории');
				}
			});
		}

		$items.click(function() {
			$(this).toggleClass('active');
		})
	}
}

TagsList.register();

export default TagsList;