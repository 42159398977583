import BEM from "tao-bem";

class VacanciesPage extends BEM.Block {
	static get blockName() {
		return "b-vacancies-page";
	}

	onInit() {
		const it = this;

		const $filter = it.elems("filter").$el;
		const $filter_modal_bg = it.elems("filter-modal-bg").$el;
		const $filter_btn = it.$el.find(`.${it.block}__filter-btn`);


		it.$el.find(".b-vacancies-filter__item-label").on("change", function() {
			let id = $(this)
					.find("input")
					.attr("id");

				let elemText = $(this)
					.find(".b-vacancies-filter__item-text")
					.text();

				if (
					$(this)
						.find("input")
						.prop("checked")
				) {
					it.renderTag(elemText, id);
				} else {
					it.$el.find(`.${it.block}__content-tag#${id}`).remove();
					if(it.elems('content-tags').$el.find(`.${it.block}__content-tag`).length < 1) {
						it.elems('content-tags').$el.addClass('hide');
					}
				}


			it.requestItems(it.collectFilterData()).then(data => {
				it.renderItems(data['items'],data['pager'],data['items_count']);
			});
		});

		it.$el.find(`.b-vacancies-filter__item-label`).each(function() {
			let elemText = $(this)
				.find(".b-vacancies-filter__item-text")
				.text();

			if (
				$(this)
					.find("input")
					.is(":checked")
			) {
				if ($(this).data("p-direction")) {
					it.renderTag(
						elemText,
						"direction_" + $(this).data("p-direction")
					);
				}
				if ($(this).data("section-id")) {
					it.renderTag(
						elemText,
						"city_" + $(this).data("section-id")
					);
				}
			}
		});


		let handlerSearch = false;

		it.$el
			.find(`.${it.block}__content-search-field`)
			.on("input", function() {
				if (handlerSearch) {
					clearTimeout(handlerSearch);
				}

				handlerSearch = setTimeout(() => {
					it.requestItems(it.collectFilterData()).then(data => {
						it.renderItems(data['items'],data['pager'],data['items_count']);
					});
				}, 1000);
			});

		it.$el.find(`.${it.block}__content-search-field`).keypress(function(e) {
			if (e.which == 13) {
				e.preventDefault();
				clearTimeout(handlerSearch);
				it.requestItems(it.collectFilterData()).then(data => {
					it.renderItems(data['items'],data['pager'],data['items_count']);
				});

				$(this).attr('readonly', 'readonly');
				$(this).attr('disabled', 'true');
			setTimeout(() => {
				$(this).removeAttr('readonly');
				$(this).removeAttr('disabled');
			}, 50);
			}
		});

		$filter_btn.click(function() {
			$filter_modal_bg.addClass("active");
			$filter.addClass("active");
		});

		$filter_modal_bg.click(function(e) {
			if ($(e.target).closest(`.${it.block}__filter`).length) return; //при клике на эти блоки не скрывать
			it.closeModal();
			$filter_modal_bg.removeClass("active");
			$filter.removeClass("active");
			e.stopPropagation();
		});

		it.$el.find(`.b-vacancies-filter__mobile-head-close`).click(function() {
			it.closeModal();
			$filter_modal_bg.removeClass("active");
			$filter.removeClass("active");
		});
	}

	renderTag(title, id) {
		const it = this;
		const tagsContainer = it.elems("content-tags").$el;
		let $tag = $(`
		<div class="${it.block}__content-tag" id='${id}'>
			${title}
			<span class="${it.block}__content-tag-close">
			</span>
		</div>
		`);

		tagsContainer.append($tag);
		tagsContainer.removeClass('hide');

		$tag.find(`.${it.block}__content-tag-close`).click(function() {
			const $elem = $(this);
			let id = $elem.parent().attr("id");
			$elem.parent().remove();
			it.$el
				.find(".b-vacancies-filter__item-label")
				.find(`input#${id}`)
				.trigger("click");

			it.requestItems(it.collectFilterData()).then(data => {
				it.renderItems(data['items'],data['pager'],data['items_count']);
			});
		});
	}

	changeUrl(filter) {
		const it = this;

		let newUrl = "/about/vacancies/?";
		let search = "";

		if (filter["p-direction"].length != 0) {
			filter["p-direction"].forEach(id => {
				newUrl += `filter[p-direction][]=${id}&`;
			});
		}

		if (filter["section_id"].length != 0) {
			filter["section_id"].forEach(id => {
				newUrl += `filter[section_id][]=${id}&`;
			});
		}

		if (it.$el.find(`.${it.block}__content-search-field`).val()) {
			search = it.$el.find(`.${it.block}__content-search-field`).val();
			newUrl += `filter[search]=${search}&`;
		}

		newUrl += `PAGEN_1=1`;

		window.history.replaceState(null, null, encodeURI(newUrl));
		return;
	}

	collectFilterData() {
		const it = this;
		let filter = {
			section_id: [],
			"p-direction": [],
			search: ""
		};

		filter['search'] = it.elems('content-search-field').$el.val();

		it.$el.find(`.b-vacancies-filter__item-label`).each(function() {
			if (
				$(this)
					.find("input")
					.is(":checked")
			) {
				if ($(this).data("section-id")) {
					filter["section_id"].push($(this).data("section-id"));
				}
				if ($(this).data("p-direction")) {
					filter["p-direction"].push($(this).data("p-direction"));
				}
			}
		});
		it.changeUrl(filter);
		return filter;
	}

	async requestItems(filter) {
		const it = this;
		return $.ajax({
			url: `/local/api/vacancies/getitems.php`,
			method: "get",
			dataType: "json",
			data: {
				filter,
				page_count: it.$el.data('max-items-count')
			}
		});
	}

	renderItems(items, pager,items_count) {
		const it = this;
		const initialFilter = it.$el.data('initial-filter');

		const itemsContainer = it.elems("items").$el;
		const paginationContainer = it.elems("pagination").$el;

		it.elems('title-count').$el.text(items_count);

		itemsContainer.html("");
		paginationContainer.html("");

		if(items_count == 0) {
			itemsContainer.append(`<div class="${it.block}__not-found">Ничего не найдено</div>`);
			return;
		}

		for (const key in items) {
			itemsContainer.append(
				it.viewItem(
					items[key]['link'],
					initialFilter['p-direction'][items[key]['direction_id']]['name'],
					items[key]['name'],
					items[key]['cities'],
				)
			);
		}

		if (pager) {
			paginationContainer.append(pager);
		}
	}

	viewItem(
		link = "",
		direction = "",
		title = "",
		city = "",
	) {
		const it = this;
		return (
		`
			<a class="${it.block}__item" href="${link}">
				<div class="${it.block}__item-direction">
					${direction}
				</div>
				<div class="${it.block}__item-title">
					${title}
				</div>
				<div class="${it.block}__item-city">
					${city}
				</div>
			</a>
		`
		);
	}

	openModal() {
		$("body").addClass("header-hide");
		$("body").addClass("modal-open");
	}

	closeModal() {
		$("body").removeClass("header-hide");
		$("body").removeClass("modal-open");
	}
}

VacanciesPage.register();

export default VacanciesPage;
