import BEM from 'tao-bem';

class ContactsMap extends BEM.Block {
	static get blockName() {
		return 'b-contacts-map';
	}

	setContactInfoBlockClass() {
		this.contactInfoBlockClass = 'b-contacts-info';
	}
	onInit() {
		const it = this;

		it.setContactInfoBlockClass();

		it.elems('item').$el.click(function(){
			if(!$(this).hasClass('active')) {
				let itemData = $(this).data('item');
				it.elems('item').$el.removeClass('active');
				$(this).addClass('active');
				it.filterMap($(this).data('filter-id'))
				let {name,address,multichannel_phones,support_phones,faxes,mails} = itemData;
				it.viewInfo(name,address,multichannel_phones,support_phones,faxes,mails);
			}
		})

		it.initMap();

		it.$el.find(`.${it.block}__item.active`).each(function(){
			it.filterMap($(this).data('filter-id'))
		});
	}

	viewInfo(city,address,multichannel_phones,support_phones,faxes,mails) {
		const it = this;
		it.$el.find(`.${it.block}__title--city`).text(city);
		it.$el.find(`.${it.contactInfoBlockClass}__data-item--address`).html('');
		it.$el.find(`.${it.contactInfoBlockClass}__data-item--multichannel_phones`).html('');
		it.$el.find(`.${it.contactInfoBlockClass}__data-item--support_phones`).html('');
		it.$el.find(`.${it.contactInfoBlockClass}__data-item--faxes`).html('');
		it.$el.find(`.${it.contactInfoBlockClass}__data-item--mails`).html('');

		if(address) {
			it.$el.find(`.${it.contactInfoBlockClass}__data-item--address`).html(`
			<div class="${it.contactInfoBlockClass}__data-item-title">
				Адрес
			</div>
			<div class="${it.contactInfoBlockClass}__data-item-content">
				${address}
			</div>
			`);
		}

		if(multichannel_phones) {
			it.$el.find(`.${it.contactInfoBlockClass}__data-item--multichannel_phones`).html(`
			<div class="${it.contactInfoBlockClass}__data-item-title">
				Единый многоканальный телефон
			</div>
			<div class="${it.contactInfoBlockClass}__data-item-content">
				${multichannel_phones}
			</div>
			`);
		}

		if(support_phones) {
			it.$el.find(`.${it.contactInfoBlockClass}__data-item--support_phones`).html(`
			<div class="${it.contactInfoBlockClass}__data-item-title">
				Техническая поддержка
			</div>
			<div class="${it.contactInfoBlockClass}__data-item-content">
				${support_phones}
			</div>
			`);
		}

		if(faxes) {
			it.$el.find(`.${it.contactInfoBlockClass}__data-item--faxes`).html(`
			<div class="${it.contactInfoBlockClass}__data-item-title">
				Факс
			</div>
			<div class="${it.contactInfoBlockClass}__data-item-content">
				${faxes}
			</div>
			`);
		}

		if(mails) {
			it.$el.find(`.${it.contactInfoBlockClass}__data-item--mails`).html(`
			<div class="${it.contactInfoBlockClass}__data-item-title">
				Почта
			</div>
			<div class="${it.contactInfoBlockClass}__data-item-content">
				${mails}
			</div>
			`);
		}
	}

	filterMap(id='all') {
		const it = this;

		ymaps.ready(function() {
			if(id) {
				it.contactsMap.geoObjects.removeAll();
				let result = it.mapItems;
				result
					.search(`properties.filter_id == ${id}`)
					.addToMap(it.contactsMap);
			}
			if(id == 'all') {
				it.mapItems.addToMap(it.contactsMap);
			}

			it.contactsMap
			.setBounds(it.contactsMap.geoObjects.getBounds(), {
				checkZoomRange: true
			})
			.then(function() {
				it.contactsMap.setZoom(15);
			});
		});
	}

	initMap() {
		const it = this;
		let mapItems = it.$el.data("items-map");
		let activeItem = mapItems.find( el => el.active);
		ymaps.ready(function() {
			it.contactsMap = new ymaps.Map("contacts-map", {
				center: [activeItem.lat, activeItem.lon],
				behaviors: ["default"],
				zoom: 15
			});
			let ballonMaxWidth = $(window).width() > 768 ? 368 : 300;

				let MyBalloonLayout = ymaps.templateLayoutFactory.createClass(
					'<div class="popover top">' +
						'<div class="arrow"></div>' +
						'<div class="popover-inner">' +
						`$[[options.contentLayout observeSize minWidth=235 maxWidth=${ballonMaxWidth}]]` +
						"</div>" +
						"</div>",
					{
						build: function() {
							this.constructor.superclass.build.call(this);

							this._$element = $(".popover", this.getParentElement());

							this.applyElementOffset();

							this._$element
								.find(".close")
								.on("click", $.proxy(this.onCloseClick, this));
						},
						clear: function() {
							this._$element.find(".close").off("click");

							this.constructor.superclass.clear.call(this);
						},
						onSublayoutSizeChange: function() {
							MyBalloonLayout.superclass.onSublayoutSizeChange.apply(
								this,
								arguments
							);

							if (!this._isElement(this._$element)) {
								return;
							}

							this.applyElementOffset();

							this.events.fire("shapechange");
						},
						applyElementOffset: function() {

							this._$element.css({
								left: -(this._$element[0].offsetWidth / 2),
								top: -(
									this._$element[0].offsetHeight +
									this._$element.find(".arrow")[0].offsetHeight
								)
							});
						},
						onCloseClick: function(e) {
							e.preventDefault();

							this.events.fire("userclose");
						},
						getShape: function() {
							if (!this._isElement(this._$element)) {
								return MyBalloonLayout.superclass.getShape.call(
									this
								);
							}

							var position = this._$element.position();

							return new ymaps.shape.Rectangle(
								new ymaps.geometry.pixel.Rectangle([
									[position.left, position.top],
									[
										position.left +
											this._$element[0].offsetWidth,
										position.top +
											this._$element[0].offsetHeight +
											this._$element.find(".arrow")[0]
												.offsetHeight
									]
								])
							);
						},
						_isElement: function(element) {
							return (
								element && element[0] && element.find(".arrow")[0]
							);
						}
					}
				);
				// Создание вложенного макета содержимого балуна.
				let MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
					'<div class="ballon-content"><a class="close" href="#"><span></span></a>$[properties.balloonContent]</div>'
				);

				let markersCollection = new ymaps.GeoObjectCollection();

				for (const key in mapItems) {
					if (mapItems[key]["lat"] && mapItems[key]["lon"]) {
						let placemark = new ymaps.Placemark(
							[mapItems[key]["lat"], mapItems[key]["lon"]],
							{
								balloonContent:
									`<div class="ballon-inner">
										<div class="ballon-address">
											${mapItems[key]['address']}
										</div>
										<div class="ballon-city">
											Россия, ${mapItems[key]['name']}
										</div>
									</div>`,
								filter_id: mapItems[key]["id"],
							},

							{
								balloonShadow: false,
								balloonLayout: MyBalloonLayout,
								balloonContentLayout: MyBalloonContentLayout,
								hideIconOnBalloonOpen: false,
								iconLayout: "default#imageWithContent",
								iconImageHref: "/images/site/map-marker.svg",
								iconImageSize: [39, 50],
								iconImageOffset: [-20, -50],
								mapAutoPan: true,
								balloonOffset: $(window).width() > 768 ? [0,0] : [-130, 0],
							}
						);

						placemark.events
							.add("balloonopen", function(e) {
								// Ссылку на объект, вызвавший событие,
								// можно получить из поля 'target'.
								e.get("target").options.set(
									"iconImageHref",
									"/images/site/map-marker2.svg",
								);
								it.contactsMap.setCenter(placemark.geometry.getCoordinates(), it.contactsMap.getZoom());


							})
							.add("balloonclose", function(e) {
								e.get("target").options.set(
									"iconImageHref",
									"/images/site/map-marker.svg"
								);
							});

						markersCollection.add(placemark);
					}
				}

				it.mapItems = ymaps
				.geoQuery(markersCollection)
				.addToMap(it.contactsMap);

				it.contactsMap
				.setBounds(it.contactsMap.geoObjects.getBounds(), {
					checkZoomRange: true
				})
				.then(function() {
					it.contactsMap.setZoom(15);
				});
		});
	}
}

ContactsMap.register();

export default ContactsMap;
