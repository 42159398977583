import BEM from 'tao-bem';

class FilesPage extends BEM.Block {
	static get blockName() {
		return 'b-files-page';
	}

	onInit() {
		const it = this;

		it.elems('show-all').$el.click(function(){
			$(this).addClass('hide');
			it.$el.find('.b-file-item').removeClass('hide');
		});

		it.elems('category').$el.click(function() {
			if($(this).hasClass(`all`)) {
				if($(this).hasClass('active')){
					it.elems('category').$el.removeClass('active');
				}else {
					it.elems('category').$el.addClass('active');
				}
			}else {
				it.$el.find(`.${it.block}__category.all`).removeClass('active');
				$(this).toggleClass('active');
			}

			it.requestHtmlItems(it.collectFilterData()).then(html => {
				it.renderItems(html);
			});

		});
	}

	collectFilterData() {
		const it = this;

		let filter = {
			section_id: [],
		};

		it.$el.find(`.${it.block}__category`).each(function() {

			if(!$(this).hasClass('all')) {
				if($(this).hasClass('active')) {
					filter["section_id"].push($(this).data("section-id"))
				}
			}
		});

		it.changeUrl(filter);
		return filter;
	}

	renderItems(html) {
		const it = this;
		const itemsContainer = it.elems("items").$el;
		const titleCount = it.elems('title-count').$el;
		itemsContainer.html(html);

		let itemsCount = it.$el.find('.b-file-item').length;

		if(itemsCount > 10) {
			it.elems('show-all').$el.removeClass('hide');
		} else {
			it.elems('show-all').$el.addClass('hide');
		}

		titleCount.text(itemsCount);
	}

	changeUrl(filter) {
		const it = this;

		let pageName = it.$el.data('page-name');

		let newUrl = `/about/${pageName}/?`;

		if (filter["section_id"].length != 0) {
			filter["section_id"].forEach(id => {
				newUrl += `filter[section_id][]=${id}&`;
			});
		}

		window.history.replaceState(null, null, encodeURI(newUrl.substr(0, newUrl.length - 1)));
		return;
	}

	async requestHtmlItems(filter) {
		const it = this;

		let pageName = it.$el.data('page-name');

		return $.ajax({
			url: `/local/api/${pageName}/gethtmlitems.php`,
			method: "get",
			dataType: "html",
			data: {
				filter
			}
		});
	}
}

FilesPage.register();

export default FilesPage;