import BEM from 'tao-bem';
import 'jquery.maskedinput/src/jquery.maskedinput.js';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min.js';
import 'component/select2/style.scss';

class EventsFilter extends BEM.Block {
	static get blockName() {
		return 'b-events-filter';
	}

	onInit() {
		const it = this;
		const $select_speakers = it.$el.find(`.${it.block}__block-select`);

		//select
		$select_speakers.select2({
			placeholder: 'Выберите из списка',
			theme: 'white-checkbox',
			closeOnSelect: false,
			selectionCssClass: `${it.block}__block-select-selection`,
			dropdownCssClass: `${it.block}__block-select`,
			language: {
				noResults: function () {
					return "Ничего не найдено";
				},
			},
			escapeMarkup: function (markup) {
				return markup;
			}

		});

		it.$el.find(`.${it.block}__block-select-wrapper`).find('.select2-search__field').attr('placeholder', 'Выберите из списка');

		$select_speakers.on('select2:select', function () {
			it.$el.find(`.${it.block}__block-select-wrapper`).find('.select2-search__field').attr('placeholder', 'Выберите из списка');
		});
		$select_speakers.on('select2:unselect', function () {
			it.$el.find(`.${it.block}__block-select-wrapper`).find('.select2-search__field').attr('placeholder', 'Выберите из списка');
		});

		let select_speakers_scroll = null;

		$select_speakers.on('select2:open', function () {
			if (!select_speakers_scroll) {
				const $scrollElem = $('.select2-results');
				$scrollElem.css({
					position: 'relative'
				});
				// select_speakers_scroll = new PerfectScrollbar($('.select2-dropdown')[0], {
				select_speakers_scroll = new PerfectScrollbar($scrollElem[0], {

					maxScrollbarLength: 100,
					scrollYMarginOffset: -30,
					wheelPropagation: true,
					wheelSpeed: 2,
					suppressScrollX: true,
				});
			} else {
				select_speakers_scroll.update()
			}
		});
		// select
		it.elems('close-btn').$el.click(function () {
			it.closeModal();
		});

		it.elems('block-field').$el.each(function () {
			$(this).mask("99.9999", {
				placeholder: "мм.гггг",
				autoclear: false,
				reverse: true
			});

		})

		it.elems('wrapper').$el.click(function (e) {
			if ($(e.target).closest(`.${it.block}__wrapper-scroll`).length) return; //при клике на эти блоки не скрывать
			if ($(e.target).closest(`.${it.block}__close-btn-wrapper`).length) return;
			it.closeModal();
			e.stopPropagation();
		});

		it.$el.find(`.${it.block}__btn--reset`).click(function () {
			it.$el.find(`.${it.block}__filter-item`).removeClass('active');
			it.$el.find(`.${it.block}__block-field`).val('');
			$select_speakers.val([]);
		});

		new PerfectScrollbar(it.$el.find(`.${it.block}__wrapper-scroll`)[0], {
			maxScrollbarLength: 100,
			suppressScrollX: true
		});
	}

	closeModal() {
		const it = this;
		it.elems('wrapper').$el.removeClass('active');
		$('.b-layout').removeClass('modal-open');
	}
}

EventsFilter.register();

export default EventsFilter;