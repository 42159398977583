import BEM from 'tao-bem';

class WorkingConditionsBlock extends BEM.Block {
	static get blockName() {
		return 'b-working-conditions-block';
	}

	onInit() {
		const it = this;

		setTimeout(() => {
			let maxImageHeight = 0;

			it.elems('item-icon').$el.each(function(){
				if($(this).height() > maxImageHeight) {
					maxImageHeight =$(this).height();
				}
			});

			if(maxImageHeight > 0) {
				it.elems('item-icon-wrapper').$el.height(maxImageHeight);
			}
		}, 50);
	}
}

WorkingConditionsBlock.register();

export default WorkingConditionsBlock;