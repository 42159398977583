import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

class EventSlider extends BEM.Block {
	static get blockName() {
		return 'b-event-slider';
	}
	onInit() {
		const it = this;
		new Swiper(`.${it.block}__slider`, {
			speed: 800,
			slidesPerView: 'auto',
			spaceBetween: 26,
			breakpoints: {
				// when window width is >= 540px
				1200: {
					slidesPerGroup: 2,
				},
				1600: {
					slidesPerGroup: 3,
				}
			},
			navigation: {
				nextEl: `.${it.block}__arrow-next`,
				prevEl: `.${it.block}__arrow-prev`,
				disabledClass: `${it.block}__arrow--disabled`,
			  }
		});
	}
}

EventSlider.register();

export default EventSlider;