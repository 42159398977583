import BEM from 'tao-bem';
// import 'jquery.maskedinput/src/jquery.maskedinput.js';
import IMask from 'imask';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min.js';
import 'component/select2/style.scss';
/*
	global BX
*/

class IndividualOfferForm extends BEM.Block {
	static get blockName() {
		return 'b-individual-offer-form';
	}

	onInit() {
		const it = this;
		const $form = it.$el.find('form');
		const link = '/local/templates/site/components/bitrix/form.result.new/individual-offer/ajax.php';
		const $phone = it.$el.find(`.${it.block}__field--phone`);


		const $select = it.$el.find(`.${it.block}__field--select`);
		const $select_organization_industries_counter = it.$el.find(`.${it.block}__field-label-count.industries`);

		// select
		$select.select2(
			{
				placeholder: 'Выберите из списка',
				theme: 'white',
				closeOnSelect: false,
				language: {
					noResults: function() {
						return "Ничего не найдено";
					},
				},
				dropdownCssClass: 'b-individual-offer-form__field-wrapper'
			}
		);

		/*$(window).scroll(function () {
			$select.select2("close");
		});*/


		$select.on('select2:select',function() {
			$(this).off('select2:select');
			$('.select2-results__option--disabled').remove();
			it.$el.find('.no-choice-option').remove();

			let data = $select.find(':selected');
			data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1);
			setTimeout(()=>{
				it.$el.find('.select2-search__field').addClass('black');
				it.$el.find('.select2-search__field').attr('placeholder', data[data.length - 1].label)
				$select_organization_industries_counter.addClass('active');
				$select_organization_industries_counter.text(data.length);
			},50)

			$select.on('select2:select',function() {
				data = $select.find(':selected')

				data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1);
				it.$el.find('.select2-search__field').attr('placeholder', data[data.length - 1].label)
				$select_organization_industries_counter.addClass('active');
				$select_organization_industries_counter.text(data.length);
			});
		});

		$select.on('select2:unselect',function() {
			let data = $select.find(':selected');
			data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1);

			if(data.length != 0) {
				it.$el.find('.select2-search__field').attr('placeholder', data[data.length - 1].label);

				$select_organization_industries_counter.text(data.length);
			} else {
				$select_organization_industries_counter.removeClass('active');
			}
		});
		let selectScroll = false;

		$select.on('select2:open',function() {
			if(!selectScroll) {
				selectScroll = new PerfectScrollbar($('.select2-results__options')[0],{
					maxScrollbarLength: 100,
					suppressScrollX: true
				});
			}else {
				selectScroll.update()
			}
		});

		// select

		registerValidator(it, $phone, 'phone');
                registerRequiredFieldsValidation(it);

		it.$el.find(`.${it.block}__politic-checkbox`).change( function(){
			if($(this).is(':checked')) {
				it.$el.find(`.${it.block}__btn`).attr('disabled', false);
			} else {
				it.$el.find(`.${it.block}__btn`).attr('disabled', true);
			}
		});

		it.elems('success-message-link').$el.click(function() {
			location.reload();
		});

		BX.bind($form[0], 'submit', BX.proxy(function(e) {
			BX.PreventDefault(e);
                        
                        var arMaskValidateErrors = validateInputFields(it);
			if (arMaskValidateErrors.length) {
				it.$el.find(`.${it.block}__errors`).addClass('active').html(arMaskValidateErrors.join('<br>'));
				return false;
			}
                        
			let xhr = new XMLHttpRequest();
			xhr.open('POST', link);

			xhr.onload = function() {
				if (xhr.status != 200) {
					alert(`Ошибка ${xhr.status}: ${xhr.statusText}`);
				} else {
					var json = JSON.parse(xhr.responseText)

					if (!json.success) {
						let errorStr = '';
						let requiredError = 'Не заполнены следующие обязательные поля: ';
						let hasRequiresError = false;

						for (let fieldKey in json.errors) {
							if(json.errors[fieldKey].includes('Не заполнены следующие обязательные поля:')) {
								requiredError+= json.errors[fieldKey].split('Не заполнены следующие обязательные поля:')[1] + ', ';
								hasRequiresError = true;
							}else {
								errorStr += json.errors[fieldKey] + '<br>';
							}
						}

						if(hasRequiresError) {
							errorStr += requiredError.substr(0, requiredError.length - 2) + '<br>';
						}

						it.$el.find(`.${it.block}__errors`).html(errorStr);
					} else {
						//Всё отправленно, можешь делать что хочешь
						$form.find(`.${it.block}__errors`).html('');
						$form.find(`input`).val('');
						$form.find(`textarea`).val('');
						$select.val(null);
						it.$el.find('.select2-search__field').attr('placeholder', 'Выберите из списка')
						it.elems('wrapper').$el.addClass('hide');
						it.elems('success-message').$el.addClass('active');
					}
				}
			};

			xhr.onerror = function() {
				console.log('Запрос не удался')
			};

			// Передаем все данные из формы
			xhr.send(new FormData($form[0]));
		}, $form[0], link));
	}
}

IndividualOfferForm.register();

export default IndividualOfferForm;