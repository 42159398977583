import BEM from 'tao-bem';

class SolutionsBanner extends BEM.Block {
	static get blockName() {
		return 'b-solutions-banner';
	}

	onInit() {
		let it = this;
		let image_block = it.el.querySelector(`.${it.block}__banner-wrapper`);
		let media_querty = window.matchMedia('(min-width: 768px)');

		image_block.style.backgroundImage = "url("+image_block.getAttribute(media_querty.matches?"data-image-desktop":"data-image-mobile")+")";
	}
}

SolutionsBanner.register();

export default SolutionsBanner;