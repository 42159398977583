import BEM from 'tao-bem';

class EventsDetailPage extends BEM.Block {
	static get blockName() {
		return 'b-events-detail-page';
	}

	onInit() {
		const it = this;

		it.$el.find('.b-speakers-block__btn').click(function() {
			$('html, body').animate({
				scrollTop: it.elems('form-registation').$el.offset().top - 200  // класс объекта к которому приезжаем
			}, 1000);
		});
	}
}

EventsDetailPage.register();

export default EventsDetailPage;