import BEM from 'tao-bem';

class DownloadsPage extends BEM.Block {
	static get blockName() {
		return 'b-downloads-page';
	}

	onInit() {
		const it = this;

		// Открытие формы заявка на продукт
		it.$el.find('.b-request-link__link').click(function(e){
			e.preventDefault();
			$('.b-layout').addClass('modal-open');
			$('.b-layout').addClass('header-hide');
			it.$el.find('.b-product-feedback-form__wrapper').addClass('active');
		})
	}
}

DownloadsPage.register();

export default DownloadsPage;