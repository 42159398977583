import BEM from 'tao-bem';

class AboutSections extends BEM.Block {
	static get blockName() {
		return 'b-about-sections';
	}
}

AboutSections.register();

export default AboutSections;