import BEM from 'tao-bem';

class AlphabetList extends BEM.Block {
	static get blockName() {
		return 'b-alphabet-list';
	}
}

AlphabetList.register();

export default AlphabetList;