import BEM from 'tao-bem';

class CertifiedPage extends BEM.Block {
	static get blockName() {
		return 'b-certified-page';
	}
}

CertifiedPage.register();

export default CertifiedPage;