import BEM from 'tao-bem';

class CertificateItems extends BEM.Block {
	static get blockName() {
		return 'b-certificate-items';
	}
}

CertificateItems.register();

export default CertificateItems;