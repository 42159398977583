import BEM from 'tao-bem';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'component/perfect-scrollbar/style.scss';

class StatusesBlock extends BEM.Block {
	static get blockName() {
		return 'b-statuses-block';
	}

	onInit() {
		const it = this;



		it.$el.find(`.${it.block}__modal-scroll`).each(function() {
			new PerfectScrollbar($(this)[0],{
				maxScrollbarLength: 100,
				suppressScrollX: true
			})
		});

		it.elems('item').$el.click(function(){
			it.closeModal();
			it.openModal();
			$(this).addClass('active');
		});

		it.elems('item-modal').$el.click(function (e){
			if ($(e.target).closest(`.${it.block}__modal-scroll`).length) return;   //при клике на эти блоки не скрывать

			if ($(e.target).closest(`.${it.block}__close-btn-wrapper`).length){
				if(!$(e.target).closest(`.${it.block}__close-btn`).length) return;
			}
			it.closeModal();
			e.stopPropagation();
		});
	}



	closeModal() {
		const it = this;
		it.elems('item').$el.removeClass('active');
		$('.b-layout').removeClass('modal-open');
		$('.b-layout').removeClass('header-hide');
	}

	openModal() {
		$('.b-layout').addClass('modal-open');
		$('.b-layout').addClass('header-hide');
	}
}

StatusesBlock.register();

export default StatusesBlock;