import BEM from "tao-bem";
/* global ymaps */
class SearchPartnersPage extends BEM.Block {
	static get blockName() {
		return "b-search-partners-page";
	}

	onInit() {
		const it = this;
		const $filter = it.elems("filter").$el;
		const $filter_modal_bg = it.elems("filter-modal-bg").$el;
		const $filter_btn = it.$el.find(`.${it.block}__filter-btn--filter`);
		const $resetBtn = it.$el.find(
			`.b-search-partners-filter__filter-btn-reset`
		);
		const $mobileResetBtn = it.$el.find(`.${it.block}__filter-btn--reset`);

		// true, если какой-то фильтр выбран
		it.filterActive = false;

                it.initMap();

		$resetBtn.click(function() {
			it.resetFilter();
		});

		$mobileResetBtn.click(function() {
			it.resetFilter();
		});

		const $selectFederal = it.$el.find(
			`.b-search-partners-filter__group-select--federal`
		);
		const $selectCity = it.$el.find(
			`.b-search-partners-filter__group-select--city`
		);

		$filter_btn.click(function() {
			it.openModal();
			$filter_modal_bg.addClass("active");
			$filter.addClass("active");
		});

		$filter_modal_bg.click(function(e) {
			if ($(e.target).closest(`.${it.block}__filter`).length) return; //при клике на эти блоки не скрывать
			it.closeModal();
			$filter_modal_bg.removeClass("active");
			$filter.removeClass("active");
			e.stopPropagation();
		});

		it.$el
			.find(`.b-search-partners-filter__mobile-head-close`)
			.click(function() {
				it.closeModal();
				$filter_modal_bg.removeClass("active");
				$filter.removeClass("active");
			});

		it.collectFilterData($selectFederal.val()[0], $selectCity.val()[0]);

		let handlerSearch = false;

		it.$el
			.find(`.${it.block}__content-search-field`)
			.on("input", function() {
				if (handlerSearch) {
					clearTimeout(handlerSearch);
				}

				handlerSearch = setTimeout(() => {
					let filter = it.collectFilterData(
						$selectFederal.val()[0],
						$selectCity.val()[0]
					);
					it.requestItems(filter).then(data => {
						it.renderItems(data.items, data.pager);
					});
				}, 1000);
			});

		it.elems('content-search').$el.submit(function(e){
			e.preventDefault()
			clearTimeout(handlerSearch);
			let $input = it.$el
			.find(`.${it.block}__content-search-field`);

			$input.attr('readonly', 'readonly');
			$input.attr('disabled', 'true');


			setTimeout(() => {
				$input.removeAttr('readonly');
				$input.removeAttr('disabled');
			}, 50);


			let filter = it.collectFilterData(
				$selectFederal.val()[0],
				$selectCity.val()[0]
			);
			it.requestItems(filter).then(data => {
				it.renderItems(data.items, data.pager);
			});
		});

		it.$el.find(`.b-search-partners-filter__item-label`).each(function() {
			let elemText = $(this)
				.find(".b-search-partners-filter__item-text")
				.text();

			if (
				$(this)
					.find("input")
					.is(":checked")
			) {
				if ($(this).data("type-id")) {
					it.renderTag(
						elemText,
						"partner_type_" + $(this).data("type-id")
					);
				}
				if ($(this).data("status-id")) {
					it.renderTag(
						elemText,
						"partner_status_" + $(this).data("status-id")
					);
				}
			}
		});

		if ($selectFederal.val()[0]) {
			it.renderTag(
				$selectFederal.find(":selected")[0].innerText,
				"federal"
			);
		}

		if ($selectCity.val()[0]) {
			it.renderTag($selectCity.find(":selected")[0].innerText, "city");
			let selectedCity = $selectCity.val()[0];

			it.requestCities($selectFederal.val()[0]).then(data => {

				$selectCity.empty();
				for (let key in data) {
					if(data[key]["id"] == selectedCity) {

						$selectCity.append(
							new Option(
								data[key]["name"],
								data[key]["id"],
								true,
								true
							)
						);
					setTimeout(() => {
						it.$el
					.find(".city-selection")
					.find(".select2-search__field")
					.attr("placeholder",data[key]["name"]
					);
					}, 10);

					} else {
						$selectCity.append(
							new Option(
								data[key]["name"],
								data[key]["id"],
								false,
								false
							)
						);
					}
				}
			});
		}

		it.$el
			.find(".b-search-partners-filter__item-label")
			.on("change", function() {
				let filter = it.collectFilterData(
					$selectFederal.val()[0],
					$selectCity.val()[0]
				);

				let id = $(this)
					.find("input")
					.attr("id");

				let elemText = $(this)
					.find(".b-search-partners-filter__item-text")
					.text();

				if (
					$(this)
						.find("input")
						.prop("checked")
				) {
					it.renderTag(elemText, id);
				} else {
					it.$el.find(`.${it.block}__content-tag#${id}`).remove();
				}

				it.changeUrl(filter);
				it.requestItems(filter).then(data => {
					it.renderItems(data.items, data.pager);
				});
			});

		$selectFederal.on("select2:select", function() {
			$(this).off("select2:select");

			setTimeout(() => {
				let filter = it.collectFilterData($selectFederal.val()[0]);
				it.$el
					.find(".b-search-partners-filter__group--city")
					.removeClass("hide");
				it.$el.find(`.${it.block}__content-tag#city`).remove();
				it.$el.find(`.${it.block}__content-tag#federal`).remove();
				it.renderTag(
					$selectFederal.find(":selected")[0].innerText,
					"federal"
				);
				it.changeUrl(filter);
				it.requestItems(filter).then(data => {
					it.renderItems(data.items, data.pager);
				});
				it.$el
					.find(".federal-selection")
					.find(".select2-search__field")
					.addClass("black");
				it.$el
					.find(".federal-selection")
					.find(".select2-search__field")
					.attr(
						"placeholder",
						$selectFederal.find(":selected")[0].innerText
					);
			}, 50);

			$selectFederal.on("select2:select", function() {
				it.$el
					.find(".b-search-partners-filter__group--city")
					.removeClass("hide");
				it.$el
					.find(".federal-selection")
					.find(".select2-search__field")
					.addClass("black");
				it.$el
					.find(".federal-selection")
					.find(".select2-search__field")
					.attr(
						"placeholder",
						$selectFederal.find(":selected")[0].innerText
					);
				it.$el.find(`.${it.block}__content-tag#city`).remove();
				it.$el.find(`.${it.block}__content-tag#federal`).remove();
				it.renderTag(
					$selectFederal.find(":selected")[0].innerText,
					"federal"
				);

				let filter = it.collectFilterData($selectFederal.val()[0]);
				it.changeUrl(filter);
				it.requestItems(filter).then(data => {
					it.renderItems(data.items, data.pager);
				});
			});
		});

		$selectFederal.on("select2:unselect", function() {
			let filter = it.collectFilterData([], []);
			it.$el
				.find(".b-search-partners-filter__group--city")
				.addClass("hide");

			it.$el.find(`.${it.block}__content-tag#federal`).remove();
			it.$el.find(`.${it.block}__content-tag#city`).remove();
			it.changeUrl(filter);
			it.requestItems(filter).then(data => {
				it.renderItems(data.items, data.pager);
			});
			it.$el
				.find(".federal-selection")
				.find(".select2-search__field")
				.removeClass("black");
		});

		$selectCity.on("select2:select", function() {
			$(this).off("select2:select");
			it.$el.find(`.${it.block}__content-tag#city`).remove();
			$selectCity.val();

			setTimeout(() => {
				it.$el
					.find(".city-selection")
					.find(".select2-search__field")
					.addClass("black");
				it.$el
					.find(".city-selection")
					.find(".select2-search__field")
					.attr(
						"placeholder",
						$selectCity.find(":selected")[0].innerText
					);
				let filter = it.collectFilterData(
					$selectFederal.val()[0],
					$selectCity.val()[0]
				);
				it.renderTag(
					$selectCity.find(":selected")[0].innerText,
					"city"
				);
				it.changeUrl(filter);
				it.requestItems(filter).then(data => {
					it.renderItems(data.items, data.pager);
				});
			}, 50);

			$selectCity.on("select2:select", function() {
				$selectCity.val();
				it.$el.find(`.${it.block}__content-tag#city`).remove();
				it.$el
					.find(".city-selection")
					.find(".select2-search__field")
					.addClass("black");
				it.$el
					.find(".city-selection")
					.find(".select2-search__field")
					.attr(
						"placeholder",
						$selectCity.find(":selected")[0].innerText
					);
				let filter = it.collectFilterData(
					$selectFederal.val()[0],
					$selectCity.val()[0]
				);
				it.renderTag(
					$selectCity.find(":selected")[0].innerText,
					"city"
				);
				it.changeUrl(filter);
				it.requestItems(filter).then(data => {
					it.renderItems(data.items, data.pager);
				});
			});
		});

		$selectCity.on("select2:unselect", function() {
			it.$el
				.find(".city-selection")
				.find(".select2-search__field")
				.removeClass("black");
			it.$el.find(`.${it.block}__content-tag#city`).remove();
			let filter = it.collectFilterData(
				$selectFederal.val()[0],
				$selectCity.val()[0]
			);
			it.changeUrl(filter);
			it.requestItems(filter).then(data => {
				it.renderItems(data.items, data.pager);
			});
		});

		it.elems("content-tab").$el.click(function() {
			const $elem = $(this);
			it.elems("content-tab").$el.removeClass("active");
			$elem.addClass("active");
			if ($elem.data("content") === "items") {
				it.elems("content-map").$el.removeClass("active");
				it.elems("content-items").$el.addClass("active");
			}

			if ($elem.data("content") === "map") {
				it.elems("content-items").$el.removeClass("active");
				it.elems("content-map").$el.addClass("active");
				// Если выбран какой-то фильтр то центрировать по меткам иначе по геолокации
				ymaps.ready(function() {
					if(!it.filterActive) {
						setTimeout(() => {
							it.partnersMap.container.fitToViewport();
                                                        if(typeof(it.myBounds) !== 'undefined') {
                                                            it.partnersMap.setBounds(it.myBounds).then(function() {
                                                                    if (it.partnersMap.getZoom() > 15)
                                                                            it.partnersMap.setZoom(12);
                                                            });
                                                        }
						}, 50);
					}else {
						it.partnersMap.container.fitToViewport();
						it.collectFilterData(
							$selectFederal.val()[0],
							$selectCity.val()[0]
						)
					}
				});

			}
		});

		it.elems("content-tag-close").$el.click(function() {
			const $elem = $(this);
			let id = $elem.parent().attr("id");
			$elem.parent().remove();

			if (id == "federal") {
				$selectFederal.val([]);
				it.$el
					.find(".federal-selection")
					.find(".select2-search__field")
					.removeClass("black");
				$selectFederal.trigger("change");
			}

			if (id == "city") {
				$selectCity.val([]);
				it.$el
					.find(".city-selection")
					.find(".select2-search__field")
					.removeClass("black");
				$selectCity.trigger("change");
			}

			let filter = it.collectFilterData(
				$selectFederal.val()[0],
				$selectCity.val()[0]
			);
			it.changeUrl(filter);
		});
	}

	collectFilterData(federal, city) {
		const it = this;
		it.filterActive = false;

		const $selectCity = it.$el.find(
			`.b-search-partners-filter__group-select--city`
		);

		let filter = {
			"p-type": [],
			"p-status": [],
			section_id: false
		};
		let activeStatus = false;
		let location = false;

		it.$el.find(`.b-search-partners-filter__item-label`).each(function() {
			if (
				$(this)
					.find("input")
					.is(":checked")
			) {
				it.filterActive = true;

				if ($(this).data("type-id") == 4) {
					activeStatus = true;
				}
				if ($(this).data("type-id")) {
					filter["p-type"].push($(this).data("type-id"));
				}
				if ($(this).data("status-id")) {
					filter["p-status"].push($(this).data("status-id"));
				}
			}
		});

		if (activeStatus) {
			it.$el
				.find(".b-search-partners-filter__group--status")
				.removeClass("hide");
		} else {
			it.$el
				.find(".b-search-partners-filter__group--status")
				.addClass("hide");
			it.$el
				.find(".b-search-partners-filter__group--status")
				.find(`.b-search-partners-filter__item-label`)
				.find("input")
				.prop("checked", false);

			filter["p-status"] = [];
			it.$el
				.find(`.${it.block}__content-tag[id*="partner_status_"]`)
				.remove();
		}

		if (city) {
			it.filterActive = true;
			filter["section_id"] = city;
			it.$el
				.find(".b-search-partners-filter__group--city")
				.removeClass("hide");
			location = "city";

		} else {
			if (federal) {
				it.filterActive = true;
				location = "federal";
				filter["section_id"] = federal;
				$selectCity.empty();

				it.$el
					.find(".b-search-partners-filter__group--city")
					.removeClass("hide");
				it.requestCities(federal).then(data => {
					for (let key in data) {
						$selectCity.append(
							new Option(
								data[key]["name"],
								data[key]["id"],
								false,
								false
							)
						);
					}
				});
			}
		}

		it.mapFilter(filter, location);

		return filter;
	}

	async requestItems(filter) {
		const it = this;
		let search = "";
		const page_count = it.$el.data("page-count");

		if (it.$el.find(`.${it.block}__content-search-field`).val()) {
			search = it.$el.find(`.${it.block}__content-search-field`).val();

			it.filterActive = true;
		}

		return $.ajax({
			url: `/local/api/partners/ajax/items.php`,
			method: "get",
			dataType: "json",
			data: {
				filter,
				search,
				page_count
			}
		});
	}

	async requestCities(district_id) {
		return $.ajax({
			url: `/local/api/partners/ajax/cities.php`,
			method: "get",
			dataType: "json",
			data: {
				district_id
			}
		});
	}

	resetFilter() {
		const it = this;

		let filter = {
			"p-type": [],
			"p-status": [],
			section_id: false
		};
		it.$el.find(`.${it.block}__content-search-field`).val("");

		it.changeUrl(filter);
		location.reload();
	}

	renderItems(items, pager) {
		const it = this;
		const itemsContainer = it.elems("items").$el;
		const paginationContainer = it.elems("pagination").$el;
		itemsContainer.html("");
		paginationContainer.html("");

		if(items.length == 0) {
			itemsContainer.append(`<div class="${it.block}__not-found">Ничего не найдено</div>`);
			return;
		}

		for (const key in items) {
			if(!items[key]["status"]) {
				items[key]["status"] = []
			}

			itemsContainer.append(
				it.viewItem(
					items[key]["link"] || '',
					items[key]["status"]["color"] || '',
					items[key]["status"]["name"] || '',
					items[key]["icon"] || '',
					items[key]["name"] || '',
					items[key]["type"] || '',
					items[key]["sites"] || ''
				)
			);
		}

		if (pager) {
			paginationContainer.append(pager);
		}
	}

	viewItem(
		link = "",
		statusColor = "",
		statusName = "",
		icon = "",
		name = "",
		type = "",
		sites = ""
	) {
		const it = this;
		let siteLinks = '';

		if(sites != '') {
			sites.forEach( (link) => {
				if(link != '') {
					siteLinks += `<object><a href="${link}" target="_blank">${link.split("//").slice(-1)[0].split('/')[0]}</a></object>`;
				}
			});
		}

		return (
			`
		<a class="${it.block}__item" href="${link}">
			<div class="${it.block}__item-status">
				<span class="${it.block}__item-status-text">
					<div class="${it.block}__item-status-color" style="background-color: ${statusColor}">
					</div>
					${statusName}
				</span>
			</div>
			<div class="${it.block}__item-logo">
				<img src="${icon}" alt="">
			</div>
			<div class="${it.block}__item-name">
				${name}
			</div>
			<div class="${it.block}__item-properties">
				<div class="${it.block}__item-type">
					${type}
				</div>
				${siteLinks}
			</div>
		</a>
		`
		);
	}

	renderTag(title, id) {
		const it = this;
		const tagsContainer = it.elems("content-tags").$el;
		let $tag = $(`
		<div class="${it.block}__content-tag" id='${id}'>
			${title}
			<span class="${it.block}__content-tag-close">
			</span>
		</div>
		`);

		tagsContainer.append($tag);

		$tag.find(`.${it.block}__content-tag-close`).click(function() {
			const $elem = $(this);
			const $selectFederal = it.$el.find(
				`.b-search-partners-filter__group-select--federal`
			);
			const $selectCity = it.$el.find(
				`.b-search-partners-filter__group-select--city`
			);

			let id = $elem.parent().attr("id");
			it.$el
				.find(".b-search-partners-filter__item-label")
				.find(`input#${id}`)
				.trigger("click");
			$elem.parent().remove();

			if (id == "federal") {
				$selectFederal.val([]);
				it.$el
					.find(".federal-selection")
					.find(".select2-search__field")
					.removeClass("black");

				$selectFederal.trigger("change");

				$selectCity.val([]);

				it.$el
					.find(".city-selection")
					.find(".select2-search__field")
					.removeClass("black");

				$selectCity.trigger("change");

				it.$el
					.find(".b-search-partners-filter__group--city")
					.addClass("hide");

				it.$el.find(`.${it.block}__content-tag#city`).remove();
			}

			if (id == "city") {
				$selectCity.val([]);
				it.$el
					.find(".city-selection")
					.find(".select2-search__field")
					.removeClass("black");
				$selectCity.trigger("change");
			}

			let filter = it.collectFilterData(
				$selectFederal.val()[0],
				$selectCity.val()[0]
			);
			it.requestItems(filter).then(data => {
				it.renderItems(data.items, data.pager);
			});
			it.changeUrl(filter);
		});
	}

	closeModal() {
		$("body").removeClass("header-hide");
		$("body").removeClass("modal-open");
		document.body.style.height = 'auto';
	}

	openModal() {
		$("body").addClass("header-hide");
		$("body").addClass("modal-open");
		document.body.style.height = '100vh';
	}

	changeUrl(filter) {
		const it = this;

		let newUrl = "/partners/?";
		let search = "";

		if (filter["p-type"].length != 0) {
			filter["p-type"].forEach(id => {
				newUrl += `filter[p-type][]=${id}&`;
			});
		}

		if (filter["p-status"].length != 0) {
			filter["p-status"].forEach(id => {
				newUrl += `filter[p-status][]=${id}&`;
			});
		}

		if (filter["section_id"]) {
			newUrl += `filter[section_id]=${filter["section_id"]}&`;
		}

		if (it.$el.find(`.${it.block}__content-search-field`).val()) {
			search = it.$el.find(`.${it.block}__content-search-field`).val();
			newUrl += `search=${search}&`;
		}

		newUrl += `PAGEN_1=1`;

		window.history.replaceState(null, null, encodeURI(newUrl));
		return;
	}

	mapFilter(filter, location = false) {
		const it = this;
		let search = false;

		if (it.$el.find(`.${it.block}__content-search-field`).val()) {
			search = it.$el.find(`.${it.block}__content-search-field`).val().toLowerCase();
		}

		ymaps.ready(function() {
			it.partnersMap.geoObjects.removeAll();

			let locationUser = ymaps.geolocation;
			locationUser
				.get({
					mapStateAutoApply: false
				})
				.then(
					function(loca) {
						it.partnersMap.geoObjects.add(loca.geoObjects);
					},
					function(err) {
						console.log("Ошибка: " + err);
					}
				);

			let result = it.mapItems;

			if(result) {
				if(search) {
					result = result.search(`properties.city_name regExp "${search}"`).add(result.search(`properties.name regExp "${search}"`));
				}

				if (filter["p-type"].length > 0) {
					filter["p-type"].forEach(e => {
						if (e != 4) {
							if (location == "city") {
								result
									.search(`properties.filter_type_id == ${e}`)
									.search(
										`properties.filter_section_id == ${filter["section_id"]}`
									)
									.addToMap(it.partnersMap);
							} else {
								if (location == "federal") {
									result
										.search(`properties.filter_type_id == ${e}`)
										.search(
											`properties.filter_federal_id == ${filter["section_id"]}`
										)
										.addToMap(it.partnersMap);
								} else {
									result
										.search(`properties.filter_type_id == ${e}`)
										.addToMap(it.partnersMap);
								}
							}
						} else {
							if (!filter["p-status"].length > 0) {
								if (location == "city") {
									result
										.search(`properties.filter_type_id == ${e}`)
										.search(
											`properties.filter_section_id == ${filter["section_id"]}`
										)
										.addToMap(it.partnersMap);
								} else {
									if (location == "federal") {
										result
											.search(
												`properties.filter_type_id == ${e}`
											)
											.search(
												`properties.filter_federal_id == ${filter["section_id"]}`
											)
											.addToMap(it.partnersMap);
									} else {
										result
											.search(
												`properties.filter_type_id == ${e}`
											)
											.addToMap(it.partnersMap);
									}
								}
							}
						}
					});
				}

				if (filter["p-status"].length > 0) {
					filter["p-status"].forEach(e => {
						if (location == "city") {
							result
								.search(`properties.filter_status_id == ${e}`)
								.search(
									`properties.filter_section_id == ${filter["section_id"]}`
								)
								.addToMap(it.partnersMap);
						} else {
							if (location == "federal") {
								result
									.search(`properties.filter_status_id == ${e}`)
									.search(
										`properties.filter_federal_id == ${filter["section_id"]}`
									)
									.addToMap(it.partnersMap);
							} else {
								result
									.search(`properties.filter_status_id == ${e}`)
									.addToMap(it.partnersMap);
							}
						}
					});
				}

				if (
					location == "city" &&
					filter["p-status"].length == 0 &&
					filter["p-type"].length == 0
				) {
					result
						.search(
							`properties.filter_section_id == ${filter["section_id"]}`
						)
						.addToMap(it.partnersMap);
				}

				if (
					location == "federal" &&
					filter["p-status"].length == 0 &&
					filter["p-type"].length == 0
				) {
					result
						.search(
							`properties.filter_federal_id == ${filter["section_id"]}`
						)
						.addToMap(it.partnersMap);
				}

				if (
					filter["section_id"] == false &&
					filter["p-status"].length == 0 &&
					filter["p-type"].length == 0
				) {
					if(search) {
						result.addToMap(it.partnersMap);
					}else {
						it.mapItems.addToMap(it.partnersMap);
					}
				}

                                var boundsRaw = it.partnersMap.geoObjects.getBounds();
                                if (!boundsRaw || !boundsRaw[0] || !boundsRaw[1] || isNaN(boundsRaw[0][0]) || isNaN(boundsRaw[0][1]) || isNaN(boundsRaw[1][0]) || isNaN(boundsRaw[1][1])) {
                                    console.log('Предупреждение: Неполные координаты.');
                                } else {
                                    it.partnersMap
					.setBounds(boundsRaw, {
						checkZoomRange: true
					})
					.then(function() {
						if (it.partnersMap.getZoom() > 15)
							it.partnersMap.setZoom(15);
					});
                                }
			}

		});
	}

	initMap() {
		const it = this;
		let mapItems = it.$el.data("items-map");
		it.partnersMap;

		ymaps.ready(function() {
			it.partnersMap = new ymaps.Map("partners-map", {
				center: [57.72690515226745, 51.571114913373194],
				behaviors: ["default"],
				zoom: 3
			});

			// Определение местоположения
			let location = ymaps.geolocation;
			location
				.get({
					mapStateAutoApply: false
				})
				.then(
					function(result) {
						it.partnersMap.geoObjects.add(result.geoObjects);
						it.myBounds = result.geoObjects.getBounds();
						it.partnersMap.setBounds(it.myBounds).then(function() {
							if (it.partnersMap.getZoom() > 15)
								it.partnersMap.setZoom(12);
						});
					},
					function(err) {
						console.log("Ошибка: " + err);
					}
				);

			let MyBalloonLayout = ymaps.templateLayoutFactory.createClass(
				'<div class="popover top">' +
					'<div class="arrow"></div>' +
					'<div class="popover-inner">' +
					"$[[options.contentLayout observeSize minWidth=235 maxWidth=368]]" +
					"</div>" +
					"</div>",
				{
					build: function() {
						this.constructor.superclass.build.call(this);

						this._$element = $(".popover", this.getParentElement());

						this.applyElementOffset();

						this._$element
							.find(".close")
							.on("click", $.proxy(this.onCloseClick, this));
					},
					clear: function() {
						this._$element.find(".close").off("click");

						this.constructor.superclass.clear.call(this);
					},
					onSublayoutSizeChange: function() {
						MyBalloonLayout.superclass.onSublayoutSizeChange.apply(
							this,
							arguments
						);

						if (!this._isElement(this._$element)) {
							return;
						}

						this.applyElementOffset();

						this.events.fire("shapechange");
					},
					applyElementOffset: function() {
						this._$element.css({
							left: -(this._$element[0].offsetWidth / 2),
							top: -(
								this._$element[0].offsetHeight +
								this._$element.find(".arrow")[0].offsetHeight
							)
						});
					},
					onCloseClick: function(e) {
						e.preventDefault();

						this.events.fire("userclose");
					},
					getShape: function() {
						if (!this._isElement(this._$element)) {
							return MyBalloonLayout.superclass.getShape.call(
								this
							);
						}

						var position = this._$element.position();

						return new ymaps.shape.Rectangle(
							new ymaps.geometry.pixel.Rectangle([
								[position.left, position.top],
								[
									position.left +
										this._$element[0].offsetWidth,
									position.top +
										this._$element[0].offsetHeight +
										this._$element.find(".arrow")[0]
											.offsetHeight
								]
							])
						);
					},
					_isElement: function(element) {
						return (
							element && element[0] && element.find(".arrow")[0]
						);
					}
				}
			);
			// Создание вложенного макета содержимого балуна.
			let MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
				'<div class="ballon-content"><a class="close" href="#"><span></span></a>$[properties.balloonContent]</div>'
			);

			let markersCollection = new ymaps.GeoObjectCollection();
			for (const key in mapItems) {
				if(!mapItems[key]["status"]) {
					mapItems[key]["status"] = [];
				}

				let status = mapItems[key]["status"]["name"] ? `<div class="status">${mapItems[key]["status"]["name"]} партнер</div>` : '';
				let site = '';
				let linkParser = document.createElement('a');
				mapItems[key]["sites"].forEach(s => {
					if(s != '') {
						linkParser.href = s;
						site += `<div><a target="_blank" href="${s}" target="_blank">${linkParser.hostname}</a></div>`;
					}
				});
				if (mapItems[key]["lat"] && mapItems[key]["lon"]) {
					let placemark = new ymaps.Placemark(
						[mapItems[key]["lat"], mapItems[key]["lon"]],
						{
							balloonContent:
								`<div class="ballon">` + status +
								`
								<div class="name">${mapItems[key]["name"]}</div>
								<div class="properties">
								<div class="property-type">${mapItems[key]["type"]}</div>
									` +
								site +
								`
								</div>
								<a href="${mapItems[key]["link"]}">Подробнее</a>
							</div>`,
							filter_section_id: mapItems[key]["section_id"],
							filter_type_id: mapItems[key]["type_id"],
							filter_status_id: mapItems[key]["status"]["id"],
							filter_federal_id: mapItems[key]["federal_id"],
							name: mapItems[key]["name"].toLowerCase(),
							city_name: mapItems[key]["city_name"].toLowerCase(),
						},

						{
							balloonShadow: false,
							balloonLayout: MyBalloonLayout,
							balloonContentLayout: MyBalloonContentLayout,
							hideIconOnBalloonOpen: false,
							iconLayout: "default#imageWithContent",
							iconImageHref: "/images/site/map-marker.svg",
							iconImageSize: [39, 50],
							iconImageOffset: [-20, -50],
						}
					);

					placemark.events
						.add("balloonopen", function(e) {
							// Ссылку на объект, вызвавший событие,
							// можно получить из поля 'target'.
							e.get("target").options.set(
								"iconImageHref",
								"/images/site/map-marker2.svg",
							);
						})
						.add("balloonclose", function(e) {
							e.get("target").options.set(
								"iconImageHref",
								"/images/site/map-marker.svg"
							);
						});

					markersCollection.add(placemark);
				}
			}

			it.mapItems = ymaps
				.geoQuery(markersCollection)
				.addToMap(it.partnersMap);
		});
	}
}

SearchPartnersPage.register();

export default SearchPartnersPage;
