import BEM from 'tao-bem';
import 'jquery.maskedinput/src/jquery.maskedinput.js';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min.js';
import 'component/select2/style.scss';

class BecomeCommercialPartnerForm extends BEM.Block {
	static get blockName() {
		return 'b-become-commercial-partner-form';
	}

	onInit() {
		const it = this;
		const $form = it.$el.find('form');
		const link = '/local/templates/site/components/bitrix/form.result.new/become-commercial-partner-form/ajax.php';
		const $phone = it.$el.find(`.${it.block}__field--phone`);

		it.$el.find(`.${it.block}__field--number`).on('input', function(e){
			this.value = this.value.replace(/[^0-9\.]/g, '');
		  });

		it.$el.find(`.products-checked`).click(function(){
			if($(this).hasClass('active')) {
				$(this).removeClass('active');
				it.$el.find(`.${it.block}__textarea-products`).attr("disabled", true);
				it.$el.find(`.${it.block}__textarea-products`).addClass('disabled');
				return;
			}
			it.$el.find(`.${it.block}__textarea-products`).attr("disabled", false);
			it.$el.find(`.${it.block}__textarea-products`).removeClass('disabled');
			it.$el.find(`.${it.block}__checkbox.products-unchecked`).removeClass('active');
			it.$el.find(`.${it.block}__checkbox.products-checked`).addClass('active');
		});

		it.$el.find(`.products-unchecked`).click(function(){
			if($(this).hasClass('active')) {
				$(this).removeClass('active');
				it.$el.find(`.${it.block}__textarea-products`).attr("disabled", true);
				it.$el.find(`.${it.block}__textarea-products`).addClass('disabled');
				return;
			}
			it.$el.find(`.${it.block}__textarea-products`).attr("disabled", true);
			it.$el.find(`.${it.block}__textarea-products`).addClass('disabled');
			it.$el.find(`.${it.block}__checkbox.products-checked`).removeClass('active');
			it.$el.find(`.${it.block}__checkbox.products-unchecked`).addClass('active');
		});

		it.$el.find('.support-checked').click(function(){
			if($(this).hasClass('active')) {
				$(this).removeClass('active');
				it.$el.find(`.${it.block}__support-field`).val('');
				return;
			}

			it.$el.find(`.${it.block}__checkbox.support-unchecked`).removeClass('active');
			it.$el.find(`.${it.block}__checkbox.support-checked`).addClass('active');
			it.$el.find(`.${it.block}__support-field`).val('Да');
		});

		it.$el.find('.support-unchecked').click(function(){
			if($(this).hasClass('active')) {
				$(this).removeClass('active');
				it.$el.find(`.${it.block}__support-field`).val('');
				return;
			}

			it.$el.find(`.${it.block}__checkbox.support-unchecked`).addClass('active');
			it.$el.find(`.${it.block}__checkbox.support-checked`).removeClass('active');
			it.$el.find(`.${it.block}__support-field`).val('Нет');
		});

		registerValidator(it, $phone, 'phone');
                registerRequiredFieldsValidation(it);

		const $select_works = it.$el.find(`.${it.block}__field--select`);

		//select
		$select_works.select2(
			{
				placeholder: 'Выберите из списка',
				theme: 'white-checkbox',
				closeOnSelect: false,
				selectionCssClass: `.${it.block}__block-select-selection`,
				dropdownCssClass: `.${it.block}__block-select`,
				language: {
					noResults: function() {
						return "Ничего не найдено";
					},
				},
				escapeMarkup: function (markup) {
					return markup;
				}

			}
		);

		it.$el.find(`.${it.block}__block-select-wrapper`).find('.select2-search__field').attr('placeholder', 'Выберите из списка');

		$select_works.on('select2:select',function() {
			let data = $select_works.find(':selected');
			if(data.length != 0) {
				$select_works.next().find('.select2-search__field').attr('placeholder', data[data.length - 1].label);
				$select_works.next().find('.select2-search__field').addClass('black');
			} else {
				$select_works.next().find('.select2-search__field').attr('placeholder', 'Выберите из списка');
				$select_works.next().find('.select2-search__field').removeClass('black');
			}
		});

		$select_works.on('select2:unselect',function() {
			let data = $select_works.find(':selected');
			if(data.length != 0) {
				$select_works.next().find('.select2-search__field').attr('placeholder', data[data.length - 1].label);
				$select_works.next().find('.select2-search__field').addClass('black');
			} else {
				$select_works.next().find('.select2-search__field').attr('placeholder', 'Выберите из списка');
				$select_works.next().find('.select2-search__field').removeClass('black');
			}
		});

		let select_works_scroll = false;

		$select_works.on('select2:open',function() {
			if(!select_works_scroll) {
				select_works_scroll = new PerfectScrollbar($('.select2-results__options')[0],{
					maxScrollbarLength: 100,
					suppressScrollX: true
				});
			}else {
				select_works_scroll.update()
			}
		});
		// select

		it.$el.find(`.${it.block}__politic-checkbox`).change( function(){
			if($(this).is(':checked')) {
				it.$el.find(`.${it.block}__btn`).attr('disabled', false);
			} else {
				it.$el.find(`.${it.block}__btn`).attr('disabled', true);
			}
		});

		it.elems('success-message-link').$el.click(function() {
			location.reload();
		});

		BX.bind($form[0], 'submit', BX.proxy(function(e) {
                        BX.PreventDefault(e);
                    
                        var arMaskValidateErrors = validateInputFields(it);
			if (arMaskValidateErrors.length) {
				it.$el.find(`.${it.block}__errors`).html(arMaskValidateErrors.join('<br>'));
				it.$el.find(`.${it.block}__errors`).addClass('active');
				return false;
			}
                        
			if(it.$el.find(`.products-checked`).hasClass('active')) {
				it.$el.find(`.${it.block}__textarea-products`).attr('disabled', false);
			}

			if(it.$el.find(`.products-unchecked`).hasClass('active')) {
				it.$el.find(`.${it.block}__textarea-products`).val('Нет');
				it.$el.find(`.${it.block}__textarea-products`).attr('disabled', false);
			}

			let xhr = new XMLHttpRequest();
			xhr.open('POST', link);
			xhr.onload = function() {
				if(!it.$el.find(`.products-checked`).hasClass('active')) {
					it.$el.find(`.${it.block}__textarea-products`).attr('disabled', true);
					it.$el.find(`.${it.block}__textarea-products`).val('');
				}
				if (xhr.status != 200) {
					alert(`Ошибка ${xhr.status}: ${xhr.statusText}`);
				} else {
					var json = JSON.parse(xhr.responseText)

					if (!json.success) {
						let errorStr = '';
						let requiredError = 'Не заполнены следующие обязательные поля: ';
						let hasRequiresError = false;

						for (let fieldKey in json.errors) {
							if(json.errors[fieldKey].includes('Не заполнены следующие обязательные поля:')) {
								requiredError+= json.errors[fieldKey].split('Не заполнены следующие обязательные поля:')[1] + ', ';
								hasRequiresError = true;
							}else {
								errorStr += json.errors[fieldKey] + '<br>';
							}
						}

						if(hasRequiresError) {
							errorStr += requiredError.substr(0, requiredError.length - 2) + '<br>';
						}
						it.$el.find(`.${it.block}__errors`).addClass('active');
						it.$el.find(`.${it.block}__errors`).html(errorStr);
					} else {
						//Всё отправленно, можешь делать что хочешь
						$form.find(`.${it.block}__errors`).html('');
						$form.find(`input`).val('');
						$form.find(`textarea`).val('');
						it.$el.find('.select2-search__field').attr('placeholder', 'Выберите из списка')
						it.elems('wrapper').$el.addClass('hide');
						it.elems('success-message').$el.addClass('active');
					}
				}
			};

			xhr.onerror = function() {
				console.log('Запрос не удался')
			};

			// Передаем все данные из формы
			xhr.send(new FormData($form[0]));
		}, $form[0], link));
	}
}

BecomeCommercialPartnerForm.register();

export default BecomeCommercialPartnerForm;