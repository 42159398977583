import BEM from 'tao-bem';

class IndividualOfferPage extends BEM.Block {
	static get blockName() {
		return 'b-individual-offer-page';
	}

	onInit() {
		const it = this;

		it.elems('card-close').$el.click(function() {
			it.elems('card').$el.addClass('hide');
		});
	}
}

IndividualOfferPage.register();

export default IndividualOfferPage;