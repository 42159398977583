import BEM from 'tao-bem';

class DocumentsDetailPage extends BEM.Block {
	static get blockName() {
		return 'b-documents-detail-page';
	}
}

DocumentsDetailPage.register();

export default DocumentsDetailPage;