import BEM from 'tao-bem';

class SolutionsOpportunities extends BEM.Block {
	static get blockName() {
		return 'b-solutions-opportunities';
	}
}

SolutionsOpportunities.register();

export default SolutionsOpportunities;