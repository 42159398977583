import BEM from 'tao-bem';

class RequisitesPage extends BEM.Block {
	static get blockName() {
		return 'b-requisites-page';
	}

	onInit() {
		const it = this;

		this.addTableScrollIcon();
	}

	addTableScrollIcon() {
		const it = this;

		it.$el.find(`.${it.block}__table-wrapper`).each(function(){
			$(this).scrollLeft(530);

			setTimeout(() => {
				$(this).scroll(function(){
					$(this).find('.table-hand-scroll').css('left',20 + $(this).scrollLeft() + 'px');
					$(this).find('.table-hand-scroll').addClass('hide');
				});
			}, 50);
		})

		it.$el.find('table').each(function(){
			$(this).find('tbody').find('tr').first().find('td').first().append('<span class="table-hand-scroll"></span>')
		});
	}
}

RequisitesPage.register();

export default RequisitesPage;