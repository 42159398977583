import BEM from 'tao-bem';

class MarketingResearchDetailPage extends BEM.Block {
	static get blockName() {
		return 'b-marketing-research-detail-page';
	}
}

MarketingResearchDetailPage.register();

export default MarketingResearchDetailPage;