import BEM from 'tao-bem';

class BtnTop extends BEM.Block {
	static get blockName() {
		return 'b-btn-top';
	}

	onInit() {
		const it = this;

		it.$el.click(()=>{
			$('body,html').animate({scrollTop: 0}, 1000);
		})

		$(document).scroll(function () {
			if( $(this).scrollTop() > 1000) {
				it.$el.addClass('active');
			} else {
				it.$el.removeClass('active');
			}
		});
	}
}

BtnTop.register();

export default BtnTop;