import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

class SolutionsProducts extends BEM.Block {
	static get blockName() {
		return 'b-solutions-products';
	}

	onInit() {
		const it = this;

		const swiper = new Swiper(it.elem('slider').el, {
			speed: 600,
			spaceBetween: 0,
			slidesPerView: 1,

			breakpoints: {
				// when window width is >= 540px

				540: {
					slidesPerView: 2,
				},

				1040: {
					slidesPerView: 3,
				},

				1240: {
					slidesPerView: 4,
				}
			},

			navigation: {
				nextEl: `.${it.block}__arrow-wrapper--next`,
				prevEl: `.${it.block}__arrow-wrapper--prev`,
				disabledClass: `${it.block}__arrow-wrapper--disabled`,
			},

			  pagination: {
				el: `.${it.block}__slider-bullets`,
				type: 'bullets',
				bulletClass: `${it.block}__slider-bullet`,
				bulletElement: 'span',
				bulletActiveClass: `${it.block}__slider-bullet--active`,
				clickable: true
			},
		});

		// Высота заголовка по самому большому
		let maxHeight = it.blocksMaxHeight(`.${it.block}__slide-name`);
		it.elems('slide-name').$el.css('min-height',maxHeight);
	}

	blocksMaxHeight(selector) {
		const it = this;

		return Math.max.apply(Math, it.$el.find(selector).map(function(){
			return $(this).height();
		}).get());
	}
}

SolutionsProducts.register();

export default SolutionsProducts;
