import BEM from 'tao-bem';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min.js';
import 'component/select2/style.scss';

class SearchPartnersFilter extends BEM.Block {
	static get blockName() {
		return 'b-search-partners-filter';
	}

	onInit() {
		const it = this;

		it.$el.find(`.${it.block}__group-head`).click(function() {
			const $elem = $(this);

			$elem.closest(`.${it.block}__group `).toggleClass('active');
		});

		// select federal
		const $selectFederal = it.$el.find(`.${it.block}__group-select--federal`);

		$selectFederal.select2(
			{
				placeholder: 'Выберите из списка',
				theme: 'white-single',
				closeOnSelect: true,
				minimumResultsForSearch: Infinity,
				dropdownCssClass: 'federal-dropdown',
				selectionCssClass: 'federal-selection',
				language: {
					noResults: function() {
						return "Ничего не найдено";
					},
				},
			}
		);
		if($selectFederal.find(':selected')[0]) {
			it.$el.find('.federal-selection').find('.select2-search__field').addClass('black');
			it.$el.find('.federal-selection').find('.select2-search__field').attr('placeholder', $selectFederal.find(':selected')[0].innerText);
		}

		$selectFederal.on('select2:selecting',function(){
			$selectFederal.val([]);
		});
		let federalScroll = false;
		$selectFederal.on('select2:open',function() {
			if(!federalScroll) {
				federalScroll = new PerfectScrollbar($('.select2-results__options')[0],{
					maxScrollbarLength: 100,
					suppressScrollX: true,
					wheelPropagation: false,
				});
			}else {
				federalScroll.update();
			}
		});
		// select federal
		// select city
		const $selectCity = it.$el.find(`.${it.block}__group-select--city`);

		$selectCity.select2(
			{
				placeholder: 'Выберите из списка',
				theme: 'white-single',
				closeOnSelect: true,
				minimumResultsForSearch: Infinity,
				dropdownCssClass: 'city-dropdown',
				selectionCssClass: 'city-selection',
				language: {
					noResults: function() {
						return "Ничего не найдено";
					},
				},
			}
		);
		if($selectCity.find(':selected')[0]) {
			it.$el.find('.city-selection').find('.select2-search__field').addClass('black');
			it.$el.find('.city-selection').find('.select2-search__field').attr('placeholder', $selectCity.find(':selected')[0].innerText);
		}
		$selectCity.on('select2:selecting',function(){
			$selectCity.val([]);
		});
		let cityScroll = false;
		$selectCity.on('select2:open',function() {
			if(!cityScroll) {
				cityScroll =new PerfectScrollbar($('.select2-results__options')[0],{
					maxScrollbarLength: 100,
					suppressScrollX: true,
					wheelPropagation: false,
				});
			}else {
				cityScroll.update();
			}
		});
		// select city

		$(window).scroll(function () {
			$selectCity.select2("close");
			$selectFederal.select2("close");
		});
	}
}

SearchPartnersFilter.register();

export default SearchPartnersFilter;
