import BEM from 'tao-bem';

class SocialChare extends BEM.Block {
	static get blockName() {
		return 'b-social-chare';
	}
}

SocialChare.register();

export default SocialChare;