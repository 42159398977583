import BEM from 'tao-bem';

class NotFoundPage extends BEM.Block {
	static get blockName() {
		return 'b-not-found-page';
	}
}

NotFoundPage.register();

export default NotFoundPage;