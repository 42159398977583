import BEM from "tao-bem";
// import "jquery.maskedinput/src/jquery.maskedinput.js";
import IMask from 'imask';
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "select2/dist/css/select2.min.css";
import "select2/dist/js/select2.full.min.js";
import "component/select2/style.scss";
/*
	global BX
*/

class RequestSupportForm extends BEM.Block {
	static get blockName() {
		return "b-request-support-form";
	}

	onInit() {
		const it = this;
		const $form = it.$el.find("form");
		const link = "/local/templates/site/components/bitrix/form.result.new/request-support/ajax.php";
		const $phone = it.$el.find(`.${it.block}__field--phone`);

		// Если true убираю логику отправки формы
		let storybook = it.$el.data("storybook");

		const $select = it.$el.find(`.${it.block}__field--select`);
		// select
		$select.select2({
			placeholder: "Выберите из списка",
			theme: "white-single",
			closeOnSelect: true,
			minimumResultsForSearch: Infinity,
			language: {
				noResults: function () {
					return "Ничего не найдено";
				},
			},
			dropdownCssClass: "b-request-support-form__field-wrapper",
		});

		$select.on("select2:selecting", function () {
			$select.val([]);
		});

		$select.on("select2:close", function () {
			const el = it.$el.find(".select2-search__field");
			el[0].blur();
		});

		$select.on("select2:select", function () {
			$(this).off("select2:select");
			$(".select2-results__option--disabled").remove();
			it.$el.find(".no-choice-option").remove();

			$select.val();

			setTimeout(() => {
				it.$el.find(".select2-search__field").addClass("black");
				it.$el
					.find(".select2-search__field")
					.attr("placeholder", $select.val());
			}, 50);

			$select.on("select2:select", function () {
				$select.val();

				it.$el
					.find(".select2-search__field")
					.attr("placeholder", $select.val());
			});
		});
		let selectScroll = false;

		$select.on("select2:open", function () {
			if (!selectScroll) {
				selectScroll = new PerfectScrollbar(
					$(".select2-results__options")[0],
					{
						maxScrollbarLength: 100,
						suppressScrollX: true,
					}
				);
			} else {
				selectScroll.update();
			}
		});
		// select

		registerValidator(it, $phone, 'phone');
                registerRequiredFieldsValidation(it);

		it.$el.find(`.${it.block}__politic-checkbox`).change(function () {
			if ($(this).is(":checked")) {
				it.$el.find(`.${it.block}__btn`).attr("disabled", false);
			} else {
				it.$el.find(`.${it.block}__btn`).attr("disabled", true);
			}
		});

		it.elems("success-message-link").$el.click(function () {
			it.elems("wrapper").$el.removeClass("hide");
			it.elems("success-message").$el.removeClass("active");
		});

		if (storybook) {
			$form.submit(function(e){
				e.preventDefault();
				$form.find(`.${it.block}__errors`).html("");
				$form.find(`.${it.block}__errors`).removeClass("active");
				$form.find(`input`).val("");
				$form.find(`textarea`).val("");
				$select.val(null);
				it.$el
					.find(".select2-search__field")
					.attr("placeholder", "Выберите из списка");
				it.elems("wrapper").$el.addClass("hide");
				it.elems("success-message").$el.addClass("active");
				return false;
			})
		} else {
			BX.bind(
				$form[0],
				"submit",
				BX.proxy(
					function (e) {
						BX.PreventDefault(e);
                                                
                                                var arMaskValidateErrors = validateInputFields(it);
                                                if (arMaskValidateErrors.length) {
                                                        it.$el.find(`.${it.block}__errors`).addClass('active').html(arMaskValidateErrors.join('<br>'));
                                                        return false;
                                                }

						let xhr = new XMLHttpRequest();
						xhr.open("POST", link);

						xhr.onload = function () {
							if (xhr.status != 200) {
								alert(
									`Ошибка ${xhr.status}: ${xhr.statusText}`
								);
							} else {
								var json = JSON.parse(xhr.responseText);

								if (!json.success) {
									let errorStr = "";
									let requiredError =
										"Не заполнены следующие обязательные поля: ";
									let hasRequiresError = false;

									for (let fieldKey in json.errors) {
										if (
											json.errors[fieldKey].includes(
												"Не заполнены следующие обязательные поля:"
											)
										) {
											requiredError +=
												json.errors[fieldKey].split(
													"Не заполнены следующие обязательные поля:"
												)[1] + ", ";
											hasRequiresError = true;
										} else {
											errorStr +=
												json.errors[fieldKey] + "<br>";
										}
									}

									if (hasRequiresError) {
										errorStr +=
											requiredError.substr(
												0,
												requiredError.length - 2
											) + "<br>";
									}

									it.$el
										.find(`.${it.block}__errors`)
										.html(errorStr);
								} else {
									//Всё отправленно
									$form.find(`.${it.block}__errors`).html("");
									$form
										.find(`.${it.block}__errors`)
										.removeClass("active");
									$form.find(`input`).val("");
									$form.find(`textarea`).val("");
									$select.val(null);
									it.$el
										.find(".select2-search__field")
										.attr(
											"placeholder",
											"Выберите из списка"
										);
									it.elems("wrapper").$el.addClass("hide");
									it.elems("success-message").$el.addClass(
										"active"
									);
								}
							}
						};

						xhr.onerror = function () {
							console.log("Запрос не удался");
						};

						// Передаем все данные из формы
						xhr.send(new FormData($form[0]));
					},
					$form[0],
					link
				)
			);
		}
	}
}

RequestSupportForm.register();

export default RequestSupportForm;
