import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

class TabsWithSlider extends BEM.Block {
	static get blockName() {
		return 'b-tabs-with-slider';
	}

	onInit() {
		const it = this;

		let swiper1 = it.initSlider({
			elem: it.elems('slider').$el[0],
			arrowPrevSelector: `.${it.block}__arrows--1 .${it.block}__arrow-wrapper--prev`,
			arrowNextSelector: `.${it.block}__arrows--1 .${it.block}__arrow-wrapper--next`,
		});

		let swiper2 = it.initSlider({
			elem: it.elems('slider').$el[1],
			arrowNextSelector: `.${it.block}__arrows--2 .${it.block}__arrow-wrapper--next`,
			arrowPrevSelector: `.${it.block}__arrows--2 .${it.block}__arrow-wrapper--prev`,
		});

		if(swiper1.isLocked) {
			it.$el.find(`.${it.block}__arrows--1`).remove();
		}
		if(swiper2.isLocked) {
			it.$el.find(`.${it.block}__arrows--2`).remove();
		}

		it.elems('category').$el.click(function() {
		let $elem = $(this);
		let categoryId = $elem.attr('id');
		it.elems('category').$el.removeClass(`${it.block}__category--active`);
		it.elems('slider-wrapper').$el.removeClass(`${it.block}__slider-wrapper--active`)
		it.elems('arrows').$el.removeClass(`${it.block}__arrows--active`);
		it.$el.find(`.${it.block}__arrows[id='${categoryId}']`).addClass(`${it.block}__arrows--active`);
		it.elems('categories').$el.find(`.${it.block}__category[id='${categoryId}']`).addClass(`${it.block}__category--active`);
		it.$el.find(`.${it.block}__slider-wrapper[id='${categoryId}']`).addClass(`${it.block}__slider-wrapper--active`);
	});
	}

	initSlider(elemObj) {
		const it = this;
		return new Swiper(elemObj.elem, {
			speed: 600,
			spaceBetween: 20,
			slidesPerView: 'auto',
			initialSlide: 0,
			watchSlidesProgress:true,
			navigation: {
				nextEl: elemObj.arrowNextSelector,
				prevEl: elemObj.arrowPrevSelector,
				disabledClass: `${it.block}__arrow-wrapper--disabled`,
			},
		});
	}
}

TabsWithSlider.register();

export default TabsWithSlider;