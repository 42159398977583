import BEM from 'tao-bem';

class QuestionsPage extends BEM.Block {
	static get blockName() {
		return 'b-questions-page';
	}

	onInit() {
		const it = this;

		it.$el.find(`.b-questions-detail-page__form-btn`).click(function(e){
			e.preventDefault();
			$('.b-layout').addClass('modal-open');
			$('.b-layout').addClass('header-hide');
			it.$el.find('.b-ask-question-form__wrapper').addClass('active');
		})
	}
}

QuestionsPage.register();

export default QuestionsPage;