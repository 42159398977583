import BEM from 'tao-bem';
// import 'jquery.maskedinput/src/jquery.maskedinput.js';
import IMask from 'imask';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'component/perfect-scrollbar/style.scss';
/*
	global BX
*/

class SolutionsForm extends BEM.Block {
	static get blockName() {
		return 'b-solutions-form';
	}

	onInit() {
		const it = this;
		const $phone = it.$el.find(`.${it.block}__field--phone`);
		const $form = it.$el.find('form');
		const link = '/local/templates/site/components/bitrix/form.result.new/solutions-form/ajax.php';

		new PerfectScrollbar(it.$el.find(`.${it.block}__form-wrapper-scroll`)[0],{
			maxScrollbarLength: 100,
			suppressScrollX: true
		});

                registerValidator(it, $phone, 'phone');
                registerRequiredFieldsValidation(it);

		it.elems('close-btn').$el.click(function(){
			it.closeModal();
		});


		it.$el.find(`.${it.block}__politic-checkbox`).change( function(){
			if($(this).is(':checked')) {
				it.$el.find(`.${it.block}__btn`).attr('disabled', false);
			} else {
				it.$el.find(`.${it.block}__btn`).attr('disabled', true);
			}
		});


		it.elems('wrapper').$el.click(function (e){
			if ($(e.target).closest(`.${it.block}__form-wrapper-scroll`).length) return;   //при клике на эти блоки не скрывать
			if ($(e.target).closest(`.${it.block}__close-btn-wrapper`).length) return;
			it.closeModal();
			e.stopPropagation();
		});

		BX.bind($form[0], 'submit', BX.proxy(function(e) {
			BX.PreventDefault(e);

                        var arMaskValidateErrors = validateInputFields(it);
			if (arMaskValidateErrors.length) {
				it.$el.find(`.${it.block}__errors`).addClass('active').html(arMaskValidateErrors.join('<br>'));
				return false;
			}

			let xhr = new XMLHttpRequest();
			xhr.open('POST', link);

			xhr.onload = function() {
				if (xhr.status != 200) {
					alert(`Ошибка ${xhr.status}: ${xhr.statusText}`);
				} else {
					var json = JSON.parse(xhr.responseText)

					if (!json.success) {
						let errorStr = '';
						let requiredError = 'Не заполнены следующие обязательные поля: ';
						let hasRequiresError = false;

						for (let fieldKey in json.errors) {
							if(json.errors[fieldKey].includes('Не заполнены следующие обязательные поля:')) {
								requiredError+= json.errors[fieldKey].split('Не заполнены следующие обязательные поля:')[1] + ', ';
								hasRequiresError = true;
							}else {
								errorStr += json.errors[fieldKey] + '<br>';
							}
						}

						if(hasRequiresError) {
							errorStr += requiredError.substr(0, requiredError.length - 2) + '<br>';
						}

						it.$el.find(`.${it.block}__errors`).html(errorStr);
					} else {
						//Всё отправленно, можешь делать что хочешь
						$form.find(`.${it.block}__errors`).html('');
						$form.find(`input`).val('');
						$form.find(`textarea`).val('');
						it.elems('form-content').$el.addClass('hide');
						it.elems('success-message').$el.addClass('active');
						it.elems('form-wrapper-scroll').$el.scrollTop(0);
					}
				}
			};

			xhr.onerror = function() {
				console.log('Запрос не удался')
			};

			// Передаем все данные из формы
			xhr.send(new FormData($form[0]));
		}, $form[0], link));

	}


	closeModal() {
		const it = this;
		it.elems('wrapper').$el.removeClass('active');
		$('.b-layout').removeClass('modal-open');
		$('.b-layout').removeClass('header-hide');
	}
}

SolutionsForm.register();

export default SolutionsForm;
