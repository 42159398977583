import BEM from 'tao-bem';

class ActivityBanner extends BEM.Block {
	static get blockName() {
		return 'b-activity-banner';
	}

	onInit() {
		let it = this;
		let image_block = it.el.querySelector(`.${it.block}__banner-wrapper`);
		let media_querty = window.matchMedia('(min-width: 768px)');

		image_block.style.backgroundImage = "url(" + image_block.getAttribute(media_querty.matches ? "data-image-desktop" : "data-image-mobile" ) + ")";

		it.elems('banner-btn').$el.click(function(e){
			$([document.documentElement, document.body]).animate({
				scrollTop: $($(this).attr('href')).offset().top
			}, 1000);
		});
	}
}

ActivityBanner.register();

export default ActivityBanner;