import BEM from "tao-bem";

class SearchPage extends BEM.Block {
	static get blockName() {
		return "b-search-page";
	}

	onInit() {
		const it = this;
		const itemsLimit = it.$el.data("items-limit");
		const searchField = it.elems("search-field").$el;
		const clearBtn = it.elems('search-clear').$el;
		let searchHiddenText = it.elems('search-hidden-text').$el;
		let categories = it.$el.data("categories");

		it.elems('show-categories').$el.click(function(){
			it.elems('categories').$el.removeClass('hide');
			$(this).addClass('hide');
		});

		searchHiddenText.text(searchField.val());
		if(searchField.val()) {
			clearBtn.addClass('active');
		}
		if(searchField.width() + 10 > searchHiddenText.width()) {
			clearBtn.css('', searchHiddenText.width() + 26);
		}

		clearBtn.click(function(){
			searchField.val('');
			$(this).removeClass('active');
			categories = [];
			it.elems("search-items").$el.html('');
			it.elems("result-count").$el.find("span").text(0);
			it.elems("search-pagination").$el.html('');
			it.elems("categories").$el.html('');
			it.elems('categories').$el.removeClass('hide');
			it.elems('show-categories').$el.addClass('hide');
			window.history.replaceState(null, null, '/search/');
		});

		it.elems('card-btn').$el.click(()=> {
			$('.b-callback-form--first').addClass('b-callback-form--active');
		});

		let handlerSearch = false;

		it.elems("search-form").$el.on('input',function(e) {
			e.preventDefault();

			searchHiddenText.text(searchField.val());

			if(searchField.width() + 10 > searchHiddenText.width()) {
				clearBtn.css('', searchHiddenText.width() + 26);
			}

			if (searchField.val()) {
				it.elems('search-clear').$el.addClass('active');
			}else {
				it.elems('search-clear').$el.removeClass('active');
			}
			if (handlerSearch) {
				clearTimeout(handlerSearch);
			}
			handlerSearch = setTimeout(() => {
				if (searchField.val()) {
					it.requestCategories(searchField.val(), categories).then(data => {
						it.renderCategories(data);
					});
					it.requestSearchItems(searchField.val(), itemsLimit).then(
						data => {
							if (data.items) {
								it.renderSearchItems(data.items, data.pager);
								it.changeUrl(searchField.val());
							}
						}
					);

					it.requestSearchItems(searchField.val(), 1000).then(data => {
						if (data.items) {
							it.renderResultTotalCount(
								Object.keys(data.items).length
							);
						}
					});
				}
			}, 1000);
		});
		it.elems("search-form").$el.submit(function(e) {
			e.preventDefault();
			if (handlerSearch) {
				clearTimeout(handlerSearch);
			}
			if (searchField.val()) {
				it.requestCategories(searchField.val(), categories).then(data => {
					it.renderCategories(data);
				});
				it.requestSearchItems(searchField.val(), itemsLimit).then(
					data => {
						if (data.items) {
							it.renderSearchItems(data.items, data.pager);
							it.changeUrl(searchField.val());
						}
					}
				);

				it.requestSearchItems(searchField.val(), 1000).then(data => {
					if (data.items) {
						it.renderResultTotalCount(
							Object.keys(data.items).length
						);
					}
				});
			}
			it.elems('search-field').$el.attr('readonly', 'readonly');
			it.elems('search-field').$el.attr('disabled', 'true');
			setTimeout(() => {
				it.elems('search-field').$el.removeAttr('readonly');
				it.elems('search-field').$el.removeAttr('disabled');
			}, 50);
		});
	}
	renderResultTotalCount(resultCount) {
		const it = this;
		const totalCountItems = it.elems("result-count").$el.find("span");

		if (resultCount) {
			totalCountItems.text(resultCount);
		} else {
			totalCountItems.text(0);
		}
	}

	renderSearchItems(items, pager) {
		const it = this;
		const container = it.elems("search-items").$el;
		const pagerContainer = it.elems("search-pagination").$el;
		pagerContainer.html("");
		container.html("");

		if(Object.keys(items).length === 0) {
			it.elems('search-not-found').$el.addClass('active');
		} else {
			it.elems('search-not-found').$el.removeClass('active');
		}
		if (pager) {
			pagerContainer.append(pager);
		}
		for (const key in items) {
			container.append(
				it.viewSearchItem(
					items[key]["link"],
					items[key]["category"],
					items[key]["title"],
					items[key]["desc"],
					items[key]["file"]["icon"],
					items[key]["file"]["link"]
				)
			);
		}
	}

	changeUrl(search) {
		let searchParams = new URLSearchParams(window.location.search);
		searchParams.set('search',search);
		let newParams = searchParams.toString();
		window.history.replaceState(null, null, "/search/?" + newParams);
	}

	viewSearchItem(link, category, title, desc, fileImg, fileLink) {
		const it = this;

		return (
			`
			<a class="${it.block}__search-item" ` +
			(link != "" ? `href='${link}'>` : ">") +
			`
				<div class="${it.block}__search-item-content">
					<div class="${it.block}__search-item-category">
						${category}
					</div>
					<div class="${it.block}__search-item-title">
						${title}
					</div>
					<p class="${it.block}__search-item-desc">
						${desc}
					</p>` +
			(fileImg
				? `<div class="${it.block}__search-item-link">
						<img src="${fileImg}" alt="">
						<object>
						<a target="_blank" href="${fileLink}">Скачать</a>
						</object>

					</div>`
				: fileLink
				? `<div class="${it.block}__search-item-link"> <object><a target="_blank" href="${fileLink}">Скачать</a></object></div>`
				: "") +
			`</div>` +
			(fileImg
				? `<img class="${it.block}__search-item-file-img" src="${fileImg}" alt="">`
				: ``) +
			`</a>`
		);
	}

	async requestSearchItems(search, items_limit = false) {
		return $.ajax({
			url: `/local/api/search/ajax/getitems.php`,
			method: "get",
			dataType: "json",
			data: {
				search,
				items_limit
			}
		});
	}

	async requestCategories(search, categories = []) {
		return $.ajax({
			url: `/local/api/search/ajax/getcategories.php`,
			method: "get",
			dataType: "json",
			data: {
				search,
				categories
			}
		});
	}

	renderCategories(categories) {
		const it = this;
		const container = it.elems("categories").$el;
		container.html("");

		if(Object.keys(categories).length != 0) {
			for (const key in categories) {
				container.append(
					`
					<a class="${it.block}__category ${categories[key]['active'] ? 'active' : ''} ${categories[key]['count'] == 0 ? 'disabled' : ''}" href="${categories[key]['link']}">
						${categories[key]['name']} <span class="${it.block}__category-count"> ${categories[key]['count']}</span>
					</a>
					`
				);
			}
		}
	}
}

SearchPage.register();

export default SearchPage;
