import BEM from 'tao-bem';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

class SolutionsContent extends BEM.Block {
	static get blockName() {
		return 'b-solutions-content';
	}

	onInit() {
		const it = this;
		const hiddenContent = it.elems('hidden-content-wrapper').$el;
		const ps = new PerfectScrollbar(hiddenContent.find(`.${it.block}__hidden-content-scroll`)[0],{
			maxScrollbarLength: 100,
			suppressScrollX: true
		});

		function closeModal() {
			hiddenContent.removeClass('active');
			$('.b-layout').removeClass('modal-open');
		};

		function openModal() {
			hiddenContent.addClass('active');
			$('.b-layout').addClass('modal-open');
		};

		hiddenContent.find(`.${it.block}__hidden-content-scroll`).on('ps-scroll-down',()=>ps.update());

		hiddenContent.appendTo('body');

		hiddenContent.click(function(e) {
			if ($(e.target).closest(`.${it.block}__hidden-content`).length) return;   //при клике на эти блоки не скрывать
			closeModal();
			e.stopPropagation();
		})

		it.elems('content-btn').$el.click(function(){
			openModal();
		})

		hiddenContent.find(`.${it.block}__hidden-content-close`).click(function(){
			closeModal();
		})
	}
}

SolutionsContent.register();

export default SolutionsContent;