import BEM from 'tao-bem';
import { compose, pipe } from 'ramda';
const isNode = o => {
  return (
    typeof Node === 'object' ? o instanceof Node
      : o && typeof o === 'object' && typeof o.nodeType === 'number' && typeof o.nodeName === 'string'
  );
};
const rndMinMaxInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
const rndFromArray = arr => arr[rndMinMaxInt(0, arr.length - 1)];
const rndId = (length = 1) => {
  const [a, n, s] = ['qwertasdfgzxcvbyuiophjklnm', '6543217890', '_-'].map(l => l.split(''));
  let res = [rndFromArray(a)];
  while(res.length <= length) compose(s => res.push(s), rndFromArray)([...a, ...n, ...s]);
  return res.join('');
};

const condAttr = (el, attr) => el && isNode(el) && el[attr] !== undefined && el.nodeName !== '#document' ;
const getAttr = (el, keyAttribute) => condAttr(el, 'getAttribute')    ? el.getAttribute(keyAttribute)    : false;
const hasAttr = (el, keyAttribute) => condAttr(el, 'hasAttribute')    ? el.hasAttribute(keyAttribute)    : null;
const remAttr = (el, keyAttribute) => condAttr(el, 'removeAttribute') ? el.removeAttribute(keyAttribute) : null;

const setAttr = (el, keyAttribute, valAttribute) => condAttr(el, 'setAttribute') ? el.setAttribute(keyAttribute, valAttribute) : false;

const element = (type, params = {}) => {
  const {
    id,
    class: cls = null,
    style, text, title, event, href, target, src, alt, async, download,
    inputType  = 'text',
    scriptType = 'text/javascript'
  } = params;

  const newElement = document.createElement(type);
  if (id)    newElement.setAttribute('id',    id);
  if (cls)   newElement.setAttribute('class', cls);
  if (style) newElement.setAttribute('style', style);
  if (text)  newElement.innerText = text;
  if (title) newElement.title     = title;
  if (event) newElement.addEventListener(event.type, () => { event.handler({ element: newElement }); });
  if (type === 'a' && href)   newElement.setAttribute('href',   href);
  if (type === 'a' && target) newElement.setAttribute('target', target);
  if (type === 'a' && download === '') newElement.setAttribute('download', '');
  if (type === 'img' && src) newElement.setAttribute('src', src);
  if (type === 'img' && alt) newElement.setAttribute('src', alt);
  if (type === 'script' && src)        newElement.setAttribute('src',   src);
  if (type === 'script' && async)      newElement.setAttribute('async', async);
  if (type === 'script' && scriptType) newElement.setAttribute('type',  scriptType);
  if (type === 'input'  && inputType)  newElement.setAttribute('type',  inputType);
  return newElement;
};

const px  = (val, ...args) => `${val[0] || (args || []).join('')}px`;
const rem = (val, ...args) => `${val[0] || (args || []).join('')}rem`;

const getParent = (element, attribute, val = -1 ) => {
  let parent = element.parentNode;
  if(!parent) return null;
  while(parent !== null) {
    if(/document/.test(parent.nodeName)) {
      return parent;
    } else if(attribute === 'class') {
      if(val!== -1 && parent.classList.contains(val)) return parent;
      else if(val === -1 && hasAttr(parent, 'class')) return parent;
    } else if(attribute === 'id') {
      if(parent.id === val) return parent;
      else if(val === -1 && hasAttr(parent, 'id')) return parent;
    } else if(attribute === 'tag') {
      if(parent.tagName.toLocaleUpperCase() === val.toLocaleUpperCase()) return parent;
    } else {
      if(hasAttr(parent, attribute) && getAttr(parent, attribute) === val) return parent;
      else if(val === -1 && hasAttr(parent, attribute) && getAttr(parent, attribute)) return parent;
    }
    parent = parent.parentNode;
  }
  return null;
};

const insert = (child, _root = null) => {
  const root = _root || document.body;
  if (!child) return false;
  root.appendChild(child);
};

const eject = node => {
  if (!node) return false;
  const parent = node?.parentNode || node?.parentElement;
  if(!parent) return false;
  parent.removeChild(node);
};


const q = (s, e  = document, mode = 'all' /* all, one */) => mode === 'all' ? e.querySelectorAll(s) : e.querySelector(s);
const toShallow = val => [...val];

const clss = ({ element, has, add, remove }) => {
  if(!condAttr(element, 'classList')) return false;
  const cnts = (e, c) => e.classList.contains(c);
  const dd   = (e, c) => e.classList.add(c);
  const rem  = (e, c) => e.classList.remove(c);
  if (!element && !isNode(element)) return false;
  if (has) return cnts(element, has);
  if (add    && !cnts(element, add))    dd(element,  add);
  if (remove && cnts(element,  remove)) rem(element, remove);
};

const domReady  = (fn, after = null) => {
  let isRun = false;
  const clean = fn => document.removeEventListener('DOMContentLoaded', fn);
  const handler = _ => {
    if (!isRun) {
      isRun = true;
      fn && fn();
      clean(handler);
    }
  };

  document.addEventListener('DOMContentLoaded', handler);

  if (!isRun && (document.readyState === 'interactive' || document.readyState === 'complete')) {
    isRun = true;
    fn && fn();
    clean(handler);
  }
  after && after();
};

const clearSelector = val => String(val).replace(/[.#]/,'');

class AmazingJSClass {
  el        = null;
  elMenu    = null;
  elWrapper = null;

  structure   = {};

  slcItemSubMenuMod     = '.b-cabinet-menu__item--submenu';
  slcSubMenuEContainer  = '.b-cabinet-menu__external-container';
  slcSubMenuELink       = '.b-cabinet-menu__external-link';
  slcSubMenuELinkActive = '.b-cabinet-menu__external-link--active';
  slcSubMenuEOpen       = '.b-cabinet-menu__external-container--opened';
  slcItemSubMenuActive  = '.b-cabinet-menu__sub-menu-link--active';

  slcMenu          = '.b-cabinet-menu__menu';
  slcWrapper       = '.b-cabinet-menu__menu-wraper';
  slcItem          = '.b-cabinet-menu__item';
  slcItemModOpen   = '.b-cabinet-menu__item--opened';
  slcLink          = '.b-cabinet-menu__link';
  slcLinkActive    = '.b-cabinet-menu__link--active';
  slcSubMenu       = '.b-cabinet-menu__sub-menu';
  slcSubMenuActive = '.b-cabinet-menu__sub-menu--active';
  slcSubItem       = '.b-cabinet-menu__sub-menu-item';
  slcSubLink       = '.b-cabinet-menu__sub-menu-link';

  isReady = true;

  constructor ({ element }) {
    this.el = element;
    domReady( this.init.bind(this));
  }

  complite() { this.isReady = true; }
  busy()     { this.isReady = false; }

  init() {
    this.busy();
    this.initElements();
    this.initEvents();
    this.complite();
  }
  initEvents() {
    document.addEventListener('click', this.closeAll.bind(this));
    window.addEventListener('keyup', ev => {
      if(ev.keyCode === 27 || ev.key === 'Escape') this.closeAll()
    });
  }

  initElements() {
    const { el } = this;

    this.elMenu    = q(this.slcMenu,    el,      'one');
    this.elWrapper = q(this.slcWrapper, this.el, 'one');

    const make = arr => arr.map(item => {
      const link  = q(this.slcLink, item, 'one');
      const href  = getAttr(link, 'href');

      let items   = [];
      let submenu = q(this.slcSubMenu, item, 'one');

      const id = rndId(5);
      setAttr(item, 'id', id);

      if(submenu) {
        let isSelected = false;
        clss( { element: item, add: clearSelector(this.slcItemSubMenuMod) } );
        items = compose(
          a => a.map(subItem => {
            const subLink      = q(this.slcSubLink, subItem, 'one');
            const isActive     = clss({element: subLink, has: clearSelector(this.slcItemSubMenuActive) });
            if(isActive) isSelected = true;
            const [text, href, download] = [subLink.innerText, getAttr( subLink, 'href' ), getAttr(subLink, 'download')];
            return {text, href, download, isActive};
          }),
          toShallow,
          q,
        )(this.slcSubItem, submenu, 'all');

        if(isSelected) clss({element: link, add: clearSelector(this.slcLinkActive)});

        remAttr(link, 'href');
        link.addEventListener('click', this.handlerSubmenuLink.bind(this));
        item.addEventListener('click', this.handlerSubmenu.bind(this));
      }

      return { id, href, item, items, link, submenu, };
    });

    this.structure = compose(make, toShallow, q)(this.slcItem,  this.elMenu, 'all');
  }

  closeAll() {
    const closeStructure = element => (this.structure.forEach(({item}) => clss({element: item, remove: clearSelector(this.slcItemModOpen)})), element);
    const collapse = element => (clss({element, remove: clearSelector(this.slcSubMenuEOpen)}), element);
    const wipe     = (arr, fn) => {
      let t = setTimeout( _ => {
        arr.forEach(element => pipe(eject, _ => clearTimeout(t))(element));
        fn();
      }, 250);
    };
    const arrNodes = [];
    return new Promise(resolve => {
      this.structure.forEach(item => {
        const { extramenu = null} = item;
        if(extramenu) pipe(
          closeStructure,
          collapse,
          element => arrNodes.push(element),
        )(extramenu);
      });
      wipe(arrNodes, resolve);
    });
  }

  async createSubMenu(event) {
    const checkMenuStatus = element => {
      if(clss({element, has: clearSelector(this.slcItemModOpen)})) return true;
      else return false;
    };

    const { target }   = event;
    const subMenu = element('div', { class:  clearSelector(this.slcSubMenuEContainer) });

    let parentItem = null;

    if(clss({element: target, has: clearSelector(this.slcItem)})) parentItem = target;
    else parentItem = getParent( target, 'class', clearSelector(this.slcItem));

    if(checkMenuStatus(parentItem)) {
      await this.closeAll();
      return this.complite();
    }

    this.busy();
    await this.closeAll();

    clss({element: parentItem, add: clearSelector(this.slcItemModOpen)});

    const id = getAttr( parentItem, 'id' );
    let index = 0;
    const { items = null } = this.structure.find((item, i) => (index = i, item.id === id))||{};
    if(!items) return null;

    items.forEach(({text, href, isActive, download}) => compose(
      el => insert(el, subMenu),
      p  => element('a', {...p }),
    )({ text, href, class: (isActive ? `${clearSelector(this.slcSubMenuELink)} ${clearSelector(this.slcSubMenuELinkActive)}` : clearSelector(this.slcSubMenuELink)), download }));


    this.structure[index].extramenu = subMenu;
    insert(subMenu, this.elWrapper);

    pipe(
      _ => this.locateSubmenu( subMenu, parentItem ),
      _ => this.openSubMenu(subMenu),
    )();
    this.complite();
  }

  locateSubmenu(subMenu, parentItem ) {
    const { height }  = parentItem.getBoundingClientRect();
    subMenu.style.top = px`${height}`;
  }

  openSubMenu(subMenu) {
    clss( { element: subMenu, add: clearSelector(this.slcSubMenuEOpen) } );
  }

  handlerSubmenu(event) {
    if(!this.isReady) return null;
    this.createSubMenu(event);
  }

  handlerSubmenuLink(event) {
    event.stopPropagation();
    event.preventDefault();
    this.handlerSubmenu(event);
  }
}

class CabinetMenu extends BEM.Block {
  /* 🐒💩 */
  static get blockName() { return 'b-cabinet-menu'; }
	onInit() { new AmazingJSClass({ element: this.el }); }
}

CabinetMenu.register();

export default CabinetMenu;
