import BEM from 'tao-bem';

class Agreements extends BEM.Block {
    static get blockName() {
        return 'b-agreements';
    }

    onInit() {}
}

Agreements.register();

export default Agreements;
