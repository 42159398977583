import BEM from 'tao-bem';

class DownloadsListSections extends BEM.Block {
	static get blockName() {
		return 'b-downloads-list-sections';
	}
}

DownloadsListSections.register();

export default DownloadsListSections;