import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import anime from "animejs/lib/anime.es.js";
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox.css';

class SolutionsConnection extends BEM.Block {
	static get blockName() {
		return 'b-solutions-connection';
	}

	onInit() {
		const it = this;

		Fancybox.bind("[data-fancybox]", {
			// Your options go here
		});

		const swiper = new Swiper(it.elem('slider').el, {
			spaceBetween: 50,
			navigation: {
				nextEl: `.${it.block}__arrow-wrapper--next`,
				prevEl: `.${it.block}__arrow-wrapper--prev`,
				disabledClass: `${it.block}__arrow-wrapper--disabled`,
			},

			  pagination: {
				el: `.${it.block}__slider-bullets`,
				type: 'bullets',
				bulletClass: `${it.block}__slider-bullet`,
				bulletElement: 'span',
				bulletActiveClass: `${it.block}__slider-bullet--active`,
				clickable: true
			},

			on: {
				slideChange: function() {
					it.elems('slide-content').$el.removeClass('active');
					it.elems('right').$el.find(`[id='${this.activeIndex}']`).addClass('active');
					it.animateSlider();
				}
			}
		});
	}

	animateSlider() {
		const it = this;

		anime({
			targets: `.${it.block}__slide-title`,
			translateY: ["25px", 0],
			opacity: [0, 1],
			easing: "linear",
			delay: 100
		});

		anime({
			targets: `.${it.block}__slide-desc`,
			translateY: ["-25px", 0],
			opacity: [0, 1],
			easing: "linear",
			delay: 100
		});


		anime({
			targets: `.${it.block}__slide-img`,
			translateY: ["25px", 0],
			opacity: [0, 1],
			easing: "linear",
			delay: 300
		});
	}
}

SolutionsConnection.register();

export default SolutionsConnection;