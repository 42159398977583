import BEM from 'tao-bem';

class PartnersDetail extends BEM.Block {
	static get blockName() {
		return 'b-partners-detail';
	}
}

PartnersDetail.register();

export default PartnersDetail;