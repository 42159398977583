import BEM from 'tao-bem';
import 'jquery.maskedinput/src/jquery.maskedinput.js';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'component/perfect-scrollbar/style.scss';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min.js';
import 'component/select2/style.scss';
/*
	global BX
*/

class DownloadFullForm extends BEM.Block {
	static get blockName() {
		return 'b-download-full-form';
	}

	onInit() {
		const it = this;
		const $form = it.$el.find('form');
		const link = '/local/templates/site/components/bitrix/form.result.new/download-full-form/ajax.php';
		const $phone = it.$el.find(`.${it.block}__field--phone`);
		const $select_organization_industries = it.$el.find(`.select-organization-industries`);
		const $select_organization_industries_counter = it.$el.find(`.${it.block}__field-label-count.industries`);
		const $select_follow_up_about_us = it.$el.find(`.select-follow-up-about-us`);
		const $select_follow_up_about_us_counter = it.$el.find(`.${it.block}__field-label-count.follow_up_about_us`);

		setTimeout(() => {
			//select
			$select_organization_industries.select2(
				{
					placeholder: 'Выберите из списка',
					theme: 'white',
					closeOnSelect: false,
					selectionCssClass: 'select-organization-industries',
					language: {
						noResults: function() {
							return "Ничего не найдено";
						},
					},
					dropdownCssClass: 'select-organization-industries',
					sorter: data => data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1)
				}
			);
			$select_organization_industries.on('select2:select',function() {
				$(this).off('select2:select');

				$('.select-organization-industries').find('.select2-results__option--disabled').remove();

				it.$el.find(".select-organization-industries").find('.no-choice-option').remove();

				let data = $select_organization_industries.find(':selected');
				data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1);
				setTimeout(()=>{
					it.$el.find(".select-organization-industries").find('.select2-search__field').addClass('black');
					it.$el.find(".select-organization-industries").find('.select2-search__field').attr('placeholder', data[data.length - 1].label)
					$select_organization_industries_counter.addClass('active');
					$select_organization_industries_counter.text(data.length);
				},50)

				$select_organization_industries.on('select2:select',function() {
					data = $select_organization_industries.find(':selected')
					data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1);
					it.$el.find(".select-organization-industries").find('.select2-search__field').attr('placeholder', data[data.length - 1].label)
					$select_organization_industries_counter.addClass('active');
					$select_organization_industries_counter.text(data.length);
				});
			});

			$select_organization_industries.on('select2:unselect',function() {
				let data = $select_organization_industries.find(':selected');
				data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1);

				if(data.length != 0) {
					it.$el.find(".select-organization-industries").find('.select2-search__field').attr('placeholder', data[data.length - 1].label)
					$select_organization_industries_counter.text(data.length);
				} else {
					$select_organization_industries_counter.removeClass('active');
				}
			});
			let select_organization_industries_scroll = false;
			$select_organization_industries.on("select2:open", function() {
				if(!select_organization_industries_scroll){
					select_organization_industries_scroll = new PerfectScrollbar($('.select-organization-industries .select2-results__options')[0],{
						maxScrollbarLength: 100,
						suppressScrollX: true,
						wheelPropagation: false
					});
				}else {
					select_organization_industries_scroll.update()
				}
			});
		}, 150);

		setTimeout(() => {
			// select-follow-up-about-us
			$select_follow_up_about_us.select2(
				{
					placeholder: 'Выберите из списка',
					theme: 'white',
					closeOnSelect: false,
					selectionCssClass: 'select-follow-up-about-us',
					language: {
						noResults: function() {
							return "Ничего не найдено";
						},
					},
					dropdownCssClass: 'select-follow-up-about-us',
					sorter: data => data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1)
				}
			);
			$select_follow_up_about_us.on('select2:select',function() {
				$(this).off('select2:select');

				$('.select-follow-up-about-us').find('.select2-results__option--disabled').remove();

				it.$el.find(".select-follow-up-about-us").find('.no-choice-option').remove();

				let data = $select_follow_up_about_us.find(':selected');
				data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1);
				setTimeout(()=>{
					it.$el.find(".select-follow-up-about-us").find('.select2-search__field').addClass('black');
					it.$el.find(".select-follow-up-about-us").find('.select2-search__field').attr('placeholder', data[data.length - 1].label)
					$select_follow_up_about_us_counter.addClass('active');
					$select_follow_up_about_us_counter.text(data.length);
				},50)

				$select_follow_up_about_us.on('select2:select',function() {
					data = $select_follow_up_about_us.find(':selected')
					data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1);
					it.$el.find(".select-follow-up-about-us").find('.select2-search__field').attr('placeholder', data[data.length - 1].label)
					$select_follow_up_about_us_counter.addClass('active');
					$select_follow_up_about_us_counter.text(data.length);
				});
			});

			$select_follow_up_about_us.on('select2:unselect',function() {
				let data = $select_follow_up_about_us.find(':selected');
				data.sort((a,b) => a.text.toLowerCase() > b.text.toLowerCase() ? 0 : -1);

				if(data.length != 0) {
					it.$el.find(".select-follow-up-about-us").find('.select2-search__field').attr('placeholder', data[data.length - 1].label)
					$select_follow_up_about_us_counter.text(data.length);
				} else {
					$select_follow_up_about_us_counter.removeClass('active');
				}
			});
			let select_follow_up_about_us_scroll = false;
			$select_follow_up_about_us.on("select2:open", function() {
				if(!select_follow_up_about_us_scroll){
					select_follow_up_about_us_scroll = new PerfectScrollbar($('.select-follow-up-about-us .select2-results__options')[0],{
						maxScrollbarLength: 100,
						suppressScrollX: true,
						wheelPropagation: false
					});
				}else {
					select_follow_up_about_us_scroll.update()
				}
			});
		}, 150);

		let formScroll = new PerfectScrollbar(it.$el.find(`.${it.block}__form-wrapper-scroll`)[0],{
			maxScrollbarLength: 100,
			suppressScrollX: true,
			wheelPropagation: false
		});

		registerValidator(it, $phone, 'phone');
                registerRequiredFieldsValidation(it);

		it.elems('close-btn').$el.click(function(){
			it.closeModal();
		});

		it.$el.find(`.${it.block}__h3.toggle`).click(function(){
			$(this).toggleClass('folded');
		});


		let requiredCheckboxChangeHandler = function() {
			let politicCheckbox = it.$el.find(`.${it.block}__politic-checkbox`);
			let eulaCheckbox = it.$el.find(`.${it.block}__eula-checkbox`);
			let checked = $(politicCheckbox).is(':checked') && $(eulaCheckbox).is(':checked');
			it.$el.find(`.${it.block}__btn`).attr('disabled', !checked);
		};
		it.$el.find(`.${it.block}__politic-checkbox`).change(requiredCheckboxChangeHandler);
		it.$el.find(`.${it.block}__eula-checkbox`).change(requiredCheckboxChangeHandler);


		it.elems('wrapper').$el.click(function (e){
			if ($(e.target).closest(`.${it.block}__form-wrapper-scroll`).length) return;   //при клике на эти блоки не скрывать
			if ($(e.target).closest(`.${it.block}__close-btn-wrapper`).length) return;
			it.closeModal();
			e.stopPropagation();
		});

		BX.bind($form[0], 'submit', BX.proxy(function(e) {
			BX.PreventDefault(e);

			var arMaskValidateErrors = validateInputFields(it);
			if (arMaskValidateErrors.length) {
				it.$el.find(`.${it.block}__errors`).addClass('active').html(arMaskValidateErrors.join('<br>'));
				return false;
			}

			const productId = $form.find('.hidden-product').val();

			let xhr = new XMLHttpRequest();
			xhr.open('POST', link);

			xhr.onload = function() {
				if (xhr.status != 200) {
					alert(`Ошибка ${xhr.status}: ${xhr.statusText}`);
				} else {
					var json = JSON.parse(xhr.responseText)

					if (!json.success) {
						let errorStr = '';
						let requiredError = 'Не заполнены следующие обязательные поля: ';
						let hasRequiresError = false;

						for (let fieldKey in json.errors) {
							if(json.errors[fieldKey].includes('Не заполнены следующие обязательные поля:')) {
								requiredError+= json.errors[fieldKey].split('Не заполнены следующие обязательные поля:')[1] + ', ';
								hasRequiresError = true;
							}else {
								errorStr += json.errors[fieldKey] + '<br>';
							}
						}

						if(hasRequiresError) {
							errorStr += requiredError.substr(0, requiredError.length - 2) + '<br>';
						}

						it.$el.find(`.${it.block}__errors`).html(errorStr);
					} else {
						//Всё отправленно

						// достижение цели Яндекс.Метрики order-product
						if (typeof ym == 'function') {
							ym(1857700, 'reachGoal', 'order-product', { product_id: productId});
						}

						$form.find(`.${it.block}__errors`).html('');
						$form.find(`input:not([type=hidden])`).val('');
						$form.find(`input[type=checkbox]:not([id=form-checkbox])`).prop('checked', false);
						$form.find(`textarea`).val('');
						$form.find(`select`).val('').val([]);
						it.$el.find(".select2-search__field").attr("placeholder", 'Выберите из списка');
						it.$el.find(`.${it.block}__field-label-count`).removeClass('active').text('1');

						it.elems('form-content').$el.addClass('hide');
						it.elems('success-message').$el.addClass('active');
						it.elems('form-wrapper-scroll').$el.scrollTop(0);
						formScroll.update();
					}
				}
			};

			xhr.onerror = function() {
				console.log('Запрос не удался')
			};

			// Передаем все данные из формы
			xhr.send(new FormData($form[0]));
		}, $form[0], link));

	}

	closeModal() {
		const it = this;
		it.elems('wrapper').$el.removeClass('active');
		$('.b-layout').removeClass('modal-open');
		$('.b-layout').removeClass('header-hide');
	}
}

DownloadFullForm.register();

export default DownloadFullForm;