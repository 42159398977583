import BEM from 'tao-bem';
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox.css';

class BannerWithVideo extends BEM.Block {
	static get blockName() {
		return 'b-banner-with-video';
	}

	onInit() {
		let it = this;
		let image_block = it.el.querySelector(`.${it.block}__wrapper`);
		let media_querty = window.matchMedia('(min-width: 768px)');

		image_block.style.backgroundImage = "url("+image_block.getAttribute(media_querty.matches?"data-image-desktop":"data-image-mobile")+")";

		Fancybox.bind("[data-fancybox]", {
			// Your options go here
		});
	}
}

BannerWithVideo.register();

export default BannerWithVideo;