import BEM from 'tao-bem';

class SolutionsPage extends BEM.Block {
	static get blockName() {
		return 'b-solutions-page';
	}
}

SolutionsPage.register();

export default SolutionsPage;