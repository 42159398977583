import BEM from 'tao-bem';

class SolutionListItem extends BEM.Block {
	static get blockName() {
		return 'b-solution-list-item';
	}
}

SolutionListItem.register();

export default SolutionListItem;