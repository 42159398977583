import BEM from 'tao-bem';

class Layout extends BEM.Block {
	static get blockName() {
		return 'b-layout';
	}

	onInit() {
		var that = this;

		const $page = $('html, body');

        /*
		$('a[href*="#"]').click(function(e) {
			$page.animate({
				scrollTop: $($.attr(this, 'href')).offset().top
			}, 400);
			return false;
		});
         */

		that.checkScroll();
		$(window).scroll(function () {
			that.checkScroll();
		});

		//Сокрытие десктопного меню при клике в любое другое место
		that.$el.click(function(e) {
			let $menu = that.$el.find('.b-header__menu');

			if(!$menu.is(e.target) && $menu.has(e.target).length === 0 && $menu.hasClass('active')) {
				$menu.find('.b-header__menu-exit').trigger('click');
			}
		})
	}

	// Например так:
	//var bemLayout = BEM.Registry.getCollection('b-layout');
	//var curLayout = bemLayout.first().getLayoutPage();
	getLayoutPage() {
		return this.mod('page');
	}

	checkScroll() {
		var curScroll = $(window).scrollTop();
		var header = this.elem('header');

		if(header) {
			if (curScroll > 1) {
				this.addMod('scroll');
				header.delMod('absolute');
				header.addMod('fixed');
			} else {
				this.delMod('scroll');
				header.delMod('fixed');
				header.addMod('absolute');
			}
		}
	}
}

Layout.register();

export default Layout;
