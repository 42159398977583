import BEM from 'tao-bem';

class BecomeTechnologyPartnerPage extends BEM.Block {
	static get blockName() {
		return 'b-become-technology-partner-page';
	}

	onInit() {
		const it = this;

		it.elems('btn').$el.click(function(){
			it.elems('form').$el.removeClass('hide');
			$(this).remove();
		});
	}
}

BecomeTechnologyPartnerPage.register();

export default BecomeTechnologyPartnerPage;