import BEM from 'tao-bem';
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox.css';

class BannerTabs extends BEM.Block {
	static get blockName() {
		return 'b-banner-tabs';
	}

	onInit() {
		const it = this;

		Fancybox.bind("[data-fancybox]", {
			// Your options go here
		});


		it.elems('category').$el.click(function() {

			let $elem = $(this);
			let categoryId = $elem.attr('id');


			it.elems('category').$el.removeClass(`${it.block}__category--active`);
			it.elems('category-mobile').$el.removeClass(`${it.block}__category-mobile--active`);
			it.elems('items').$el.removeClass(`${it.block}__items--active`)



			it.elems('categories').$el.find(`[id='${categoryId}']`).addClass(`${it.block}__category--active`);
			it.elems('category-mobile-wrapper').$el.find(`[id='${categoryId}']`).addClass(`${it.block}__category-mobile--active`);
			it.elems('content-wrapper').$el.find(`[id='${categoryId}']`).addClass(`${it.block}__items--active`);
		});

	}
}

BannerTabs.register();

export default BannerTabs;