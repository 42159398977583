import BEM   from 'tao-bem';
import { q } from '@lib/dom.js';
import { replaceTmpl } from '@lib/string.js';
import { namespace } from '@settings';

//const q = (s, e  = document, mode = 'all' /* all, one */) => mode === 'all' ? e.querySelectorAll(s) : e.querySelector(s);

const isDebug = true;
const ModuleName = 'Breadcrumb';
const _log = console.log;
const log = ( ...args ) => isDebug ? _log(ModuleName + ': ',...args) : void null;

class AwesomeBreadcrumbs {
  static slcLink  = '.b-breadcrumb__link';
  static slcItem  = '.b-breadcrumb__item';

  static delim    = '—';
  static dots     = '…';

  static bemBlock    = 'b-breadcrumb';
  static idnamespace = 'bx_breadcrumb';

  static tmplItem = `
    <div class="%block%__item" id="%idnamespace%_%key%" itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem">
      <a class="%block%__link" href="%link%" title="%text%" itemprop="item">%text%</a><span class="%block%__delim">%delim%</span>
      <meta itemprop="position" content="%key-plus%"/>
      <div class="%block%__dots"><span class="%block%__dots-symbol">%dots%</span><span class="%block%__delim">%delim%</span></div>
    </div>`;
  static tmplItemLast = `<div class="%block%__item %block%__item--current"><a class="%block%__link" href="%link%" title="%text%" itemprop="item">%text%</a></div>`;

  #element = null;
  #structure = [
    //{link, text, index, element, elementLink}
  ];
  constructor( params = {} ) {
    const { element = null } = params;
    log('constructor');
    if(!element) return null;
    this.#element = element;
    this.#init();
    log(this.#structure);
    this.#render();
  }

  #init() {
    log('init');
    const items = [...q(AwesomeBreadcrumbs.slcItem, 'all', this.#element)];
    this.#structure = items.map(( element, index ) => {
      const elementLink = q(AwesomeBreadcrumbs.slcLink, 'one', element);
      const link = (elementLink.getAttribute('href') || window.location.pathname);
      const text = elementLink.innerText.trim();
      return { link, text };
    });
  }

  #render() {
    const block       = AwesomeBreadcrumbs.bemBlock;
    const delim       = AwesomeBreadcrumbs.delim;
    const dots        = AwesomeBreadcrumbs.dots;
    const idnamespace = AwesomeBreadcrumbs.idnamespace;
    const res = this.#structure.reduce((s, c, index, { length }) => {
      const isLast = index === length -1;
      const key = index;
      const {
        link,
        text,
      } = c;
      const tmpl = isLast ? AwesomeBreadcrumbs.tmplItemLast : AwesomeBreadcrumbs.tmplItem;


      return s.concat(replaceTmpl(tmpl, {
        block,
        delim,
        dots,
        idnamespace,
        key,
        'key-plus': key + 1,
        link,
        text,
      }));
    },'');
    this.#element.innerHTML = '';
    this.#element.insertAdjacentHTML('afterbegin', res);
  }

  push(params = {}) {
    const { text, link, isUpdate = true } = params;
    log('append: ', link, text);
    if(!text || !link) return null;
    this.#structure.push({ link, text });
    isUpdate && this.#render();
  }
  pop(params = {}) {
    const { isUpdate = true } = params;
    this.#structure.pop();
    isUpdate && this.#render();
  }

  hasLink ({ link }) { return this.links.includes(link); }
  hasText ({ text }) { return this.texts.includes(text); }

  get links () { return this.#structure.map(({ link }) => link);}
  get texts () { return this.#structure.map(({ text }) => text);}

  static run({element}) {
    if(!element) void null;
    const Breadcrumb =  new this({element});
    if(!window[namespace]) window[namespace] = { Breadcrumb };
    window[namespace] = {...window[namespace], Breadcrumb};
  }
}

class Breadcrumb extends BEM.Block {
	static get blockName() {
		return 'b-breadcrumb';
	}
	onInit() { log('onInit'); AwesomeBreadcrumbs.run({ element: this.el }); }
}

Breadcrumb.register();

export default Breadcrumb;
