import BEM from 'tao-bem';

class Pagination extends BEM.Block {
	static get blockName() {
		return 'b-pagination';
	}
}

Pagination.register();

export default Pagination;