import BEM from 'tao-bem';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

class AboutAdvantages extends BEM.Block {
	static get blockName() {
		return 'b-about-advantages';
	}

	onInit() {
		const it = this;
		const slider = new Swiper(it.elem('slider').el, {
			speed: 100,
			spaceBetween: 20,
			slidesPerView: 1,
			autoHeight: true,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			  },

			  pagination: {
				el: `.${it.block}__slider-bullets`,
				type: 'bullets',
				bulletClass: `${it.block}__slider-bullet`,
				bulletElement: 'span',
				bulletActiveClass: `${it.block}__slider-bullet--active`,
				clickable: true
			},
		});

		if($(window).width() >= 450) {
			slider.destroy();
		}
 	}
}

AboutAdvantages.register();

export default AboutAdvantages;